import Blockly from 'blockly'
import i18n from '../../translations/i18n';
import {blockColor, addSeparator} from "../BlockProperties"

Blockly.Blocks['actor_setinput_block'] = {
    init: function() {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(addSeparator(i18n.t("ADVANCED_SET_INPUT")));
        this.appendValueInput("input_name")
            .setCheck("String")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(i18n.t("PLACEHOLDER_NAME") + ":");
        this.setOutput(true,"Any");
        this.setInputsInline(true);
        this.setColour(blockColor('advanced'));
        this.setTooltip(i18n.t("ADVANCED_SET_INPUT_TOOLTIP"));
        this.setHelpUrl("");
    }
  };

  Blockly.Blocks['actor_getinput_block'] = {
    init: function() {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(addSeparator(i18n.t("ADVANCED_GET_INPUT")));
        this.appendValueInput("input_name")
            .setCheck("String")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(i18n.t("PLACEHOLDER_NAME") + ":");
        this.setOutput(true,"Any");
        this.setInputsInline(true);
        this.setColour(blockColor('advanced'));
        this.setTooltip(i18n.t("ADVANCED_GET_INPUT_TOOLTIP"));
        this.setHelpUrl("");
    }
  };

  Blockly.Blocks['actor_setoutput_block'] = {
    init: function() {
        this.appendDummyInput()
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(addSeparator(i18n.t("ADVANCED_SET_OUTPUT")));
        this.appendValueInput("output_name")
            .setCheck("String")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(i18n.t("PLACEHOLDER_NAME") + ":");
        this.appendValueInput("called_function")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(i18n.t("PLACEHOLDER_FUNCTION") + ":");
        this.setOutput(true,"Any");
        this.setInputsInline(true);
        this.setColour(blockColor('advanced'));
        this.setTooltip(i18n.t("ADVANCED_SET_OUTPUT_TOOLTIP"));
        this.setHelpUrl("");
      }
  };


  Blockly.Blocks['actor_getinterface_block'] = {
    init: function() {
        this.appendDummyInput()
          //.setAlign(Blockly.ALIGN_RIGHT)
          .appendField(addSeparator(i18n.t("ADVANCED_GET_INTERFACE")));
        this.appendValueInput("interface_name")
          .setCheck("String")
          //.setAlign(Blockly.ALIGN_LEFT)
          .appendField(i18n.t("PLACEHOLDER_NAME") + ":")
        this.appendDummyInput()
          .appendField(i18n.t("PLACEHOLDER_TYPE") + ":")
          .appendField(new Blockly.FieldDropdown([[i18n.t("PLACEHOLDER_INPUT"),"'input'"], [i18n.t("PLACEHOLDER_OUTPUT"),"'output'"]]), "interface_type_");
        this.setOutput(true, "Any");
        this.setInputsInline(true);
        this.setColour(blockColor('advanced'));
        this.setTooltip(i18n.t("ADVANCED_GET_INTERFACE_TOOLTIP"));
        this.setHelpUrl("");
    }
  };

  Blockly.Blocks['actor_createglobalvar_block'] = {
    init: function() {
      this.appendDummyInput()
          .appendField(addSeparator(i18n.t("ADVANCED_CREATE_GLOBAL_VAR")));
      this.appendValueInput("name")
          .setCheck("String")
          .appendField(i18n.t("PLACEHOLDER_NAME") + ":");
      this.appendValueInput("value")
          .setCheck(null)
          .appendField(i18n.t("PLACEHOLDER_VALUE") + ":");
      this.setInputsInline(true);
      this.setColour(blockColor('advanced'));
   this.setTooltip(i18n.t("ADVANCED_CREATE_GLOBAL_VAR_TOOLTIP"));
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['actor_createglobaloutinterface_block'] = {
    init: function() {
      this.appendDummyInput()
          .appendField(addSeparator(i18n.t("ADVANCED_CREATE_GLOBAL_OUT_INTERFACE")));
      this.appendValueInput("name")
          .setCheck("String")
          .appendField(i18n.t("PLACEHOLDER_NAME") + ":");
      this.appendValueInput("function")
          .setCheck(null)
          .appendField(i18n.t("PLACEHOLDER_FUNCTION") + ":");
      this.setInputsInline(true);
      this.setColour(blockColor('advanced'));
   this.setTooltip(i18n.t("ADVANCED_CREATE_GLOBAL_OUT_INTERFACE_TOOLTIP"));
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['actor_getglobalvalue_block'] = {
    init: function() {
      this.appendDummyInput()
          .appendField(addSeparator(i18n.t("ADVANCED_GET_GLOBAL_VALUE")));
      this.appendValueInput("name")
          .setCheck("String")
          .appendField(i18n.t("PLACEHOLDER_NAME") + ":");
      this.setInputsInline(true);
      this.setOutput(true, null);
      this.setColour(blockColor('advanced'));
   this.setTooltip(i18n.t("ADVANCED_GET_GLOBAL_VALUE_TOOLTIP"));
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['actor_setglobalvalue_block'] = {
    init: function() {
      this.appendDummyInput()
          .appendField(addSeparator(i18n.t("ADVANCED_SET_GLOBAL_VALUE")));
      this.appendValueInput("name")
          .setCheck("String")
          .appendField(i18n.t("PLACEHOLDER_NAME") + ":");
      this.appendValueInput("value")
          .setCheck(null)
          .appendField(i18n.t("PLACEHOLDER_VALUE") + ":");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(blockColor('advanced'));
   this.setTooltip(i18n.t("ADVANCED_SET_GLOBAL_VALUE_TOOLTIP"));
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['actor_getglobalinterfacevalue_block'] = {
    init: function() {
      this.appendDummyInput()
          .appendField(addSeparator(i18n.t("ADVANCED_GET_GLOBAL_INTERFACE_VALUE")));
      this.appendValueInput("name")
          .setCheck("String")
          .appendField(i18n.t("PLACEHOLDER_NAME") + ":");
      this.setInputsInline(true);
      this.setOutput(true, null);
      this.setColour(blockColor('advanced'));
   this.setTooltip(i18n.t("ADVANCED_GET_GLOBAL_INTERFACE_VALUE_TOOLTIP"));
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['actor_setglobalinterface_block'] = {
    init: function() {
      this.appendDummyInput()
          .appendField(addSeparator(i18n.t("ADVANCED_SET_GLOBAL_INTERFACE")));
      this.appendValueInput("name")
          .setCheck("String")
          .appendField(i18n.t("PLACEHOLDER_NAME") + ":");
      this.appendValueInput("function")
          .setCheck(null)
          .appendField(i18n.t("PLACEHOLDER_FUNCTION") + ":");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(blockColor('advanced'));
   this.setTooltip(i18n.t("ADVANCED_SET_GLOBAL_INTERFACE_TOOLTIP"));
   this.setHelpUrl("");
    }
  };
