import React, { Suspense } from "react";
import ReactDOM from "react-dom/client";
// import App from "./App";
import "bootstrap/dist/css/bootstrap.min.css";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import { GlobalContextProvider } from "./contexts/globalContext";
import BlocklyScreen from "./components/blockly/BlocklyScreen";
import { NavigateProvider } from "./contexts/navigationContext";

const App = React.lazy(() => import("./App"))
//para usar no electron, substituir o BroserRouter por HashRouter (https://stackoverflow.com/questions/36505404/how-to-use-react-router-with-electron)
const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);

let windowMatches = window.matchMedia("(orientation: landscape)").matches;
let orientation = windowMatches ? "horizontal" : "vertical";
setTimeout(() => {
  if (document !== null) {
    //let doc = document as Document;
    document.querySelector("meta[name=viewport]")?.setAttribute("content", "height=" + window.screen.height * 0.9 + "px, width=device-width, initial-scale=1.0")
  }
}
  , 300);

root.render(
    <BrowserRouter>
      <Suspense fallback={<div>Loading...</div>}>
        <GlobalContextProvider>
          <NavigateProvider>
            <Routes>
              <Route path="/blockly" element={<BlocklyScreen/>}>
                <Route path=":project_id"/>
              </Route>
              <Route path="*" element={<App />} />
            </Routes>
          </NavigateProvider>   
        </GlobalContextProvider>
      </Suspense>
    </BrowserRouter>
);
