const xml_data = `
<xml xmlns="https://developers.google.com/blockly/xml">
  <variables>
    <variable>actor</variable>
  </variables>
  <block type="FIRST_CHANGE" x="220" y="270">
    <statement name="DO">
      <block type="define_actor_block">
        <field name="title">define actor</field>
        <value name="actor_name">
          <block type="SECOND_CHANGE">
            <value name="name">
              <block type="text">
                <field name="TEXT">THIRD_CHANGE</field>
              </block>
            </value>
            <value name="uid">
              <block type="math_number">
                <field name="NUM">FOURTH_CHANGE</field>
              </block>
            </value>
          </block>
        </value>
        
      <next>
        <block type="variables_set">
          <field name="VAR">actor</field>
          <value name="VALUE">
            <block type="get_actor_block"></block>
          </value>
        </block>
      </next>

      </block>
    </statement>
  </block>
  <block type="procedures_run" x="220" y="500"></block>
</xml>`;


const getInitialSetupXml = (model_name: string, model_id: string, model_type: string) =>{
  let setup = (model_type==="Supervisor") ? "procedures_setup_supervisor" : "procedures_setup";

  var xmlChange = xml_data;
  xmlChange = xmlChange.replace('FIRST_CHANGE',setup);
  xmlChange = xmlChange.replace('SECOND_CHANGE', modelTypeClass[model_type]?.toLowerCase()+"_instantiation_block");
  xmlChange = xmlChange.replace('THIRD_CHANGE',model_name);
  xmlChange = xmlChange.replace('FOURTH_CHANGE',model_id);

  return xmlChange;
}

const getInitialSetupPython = (model_name: string, model_id: string | undefined, model_type: string) =>{
  let code= `
class {{CLASS_NAME}}(codeTemplate):
  def __init__(self):  
    self.actor = None

  def setup(self{{FIRST_CHANGE}}):
    self.defineActor({{SECOND_CHANGE}})
    self.actor = self.currentActor()

  def run(self):
    pass
  `;
  if(model_type==="Supervisor"){
    code = code.replace("{{CLASS_NAME}}",'supervisorCode');
    code = code.replace("{{FIRST_CHANGE}}",', actors');
    code = code.replace("{{SECOND_CHANGE}}", `${modelTypeClass[model_type]}("${model_name}", actors)`);
  }
  else{
    code = code.replace("{{CLASS_NAME}}",'actorCode');
    code = code.replace("{{FIRST_CHANGE}}","");
    code = code.replace("{{SECOND_CHANGE}}", `${modelTypeClass[model_type]}("${model_name}", ${model_id})`);
  }
  return code;
}

const modelTypeClass: { [type: string] : string} = {
  'DobotV3': 'Dobot',
  'NTUbot': 'NTUbot',
  'PioneerV2': 'Pioneer',
  'LineFollowerBot': 'LineFollowerBot',
  'ConveyorM': 'Conveyor',
  'Spawner2': 'Spawner', 
  'Stopwatch': 'Stopwatch',
  'colorSensor': 'ColorSensor',
  'rangeSensor': 'RangeSensor',
  'photoelectricSensor': 'PhotoelectricSensor',
  'Supervisor': 'Supervisor',
}

const initialSetup = {
    getInitialSetupXml,
    getInitialSetupPython
  };
  
  export default initialSetup;
  