import Blockly from "blockly";
import i18n from "../../translations/i18n";
import {blockColor} from "../BlockProperties"

Blockly.Blocks['define_actor_block'] = {
  init: function() {
      this.appendDummyInput("title")
        .setAlign(Blockly.ALIGN_CENTRE)
        .appendField(i18n.t("BASIC_DEFINE_ACTOR"))
      this.appendValueInput("actor_name")
        .setCheck("Any")
        .setAlign(Blockly.ALIGN_RIGHT)
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setInputsInline(true);
      this.setColour(blockColor('basic'));
      this.setTooltip(i18n.t("BASIC_DEFINE_ACTOR_TOOLTIP"));
      this.setHelpUrl("");
  },
};

Blockly.Blocks['get_actor_block'] = {
  init: function() {
      this.appendDummyInput()
        .setAlign(Blockly.ALIGN_CENTRE)
        .appendField(i18n.t("BASIC_GET_ACTOR"))
      this.setOutput(true, "Any");
      this.setInputsInline(true);
      this.setColour(blockColor('basic'));
      this.setTooltip(i18n.t("BASIC_GET_ACTOR_TOOLTIP"));
      this.setHelpUrl("");
  }
};

