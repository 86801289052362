import { blockColor } from "../../BlockProperties";
import "./block";
import "./pythonCode";
import "./customField";
import i18n from "../../../translations/i18n";

function GetColorSensorToolbox(actorId: string){

  let colorSensorToolbox: any = {
    kind: "category",
    name: i18n.t("PLACEHOLDER_COLORSENSOR"),
    expanded: true,
    // colour: blockColor("color_sensor"),
    contents: [
      {
        kind: "category",
        name: i18n.t("PLACEHOLDER_SENSING"),
        colour: blockColor("sensing"),
        contents: [
          {
            kind: "block",
            type:"variables_call_out",
            fields:{
                "VAR":{
                    type: "field_variable",
                    id: actorId
                }
            },
            inputs:{
                "VALUE":{
                    "block": {
                        type:"colorsensor_getcolor_block"
                    }
                }
            }
          },
          {
            kind: "block",
            type:"variables_call_out",
            fields:{
                "VAR":{
                    type: "field_variable",
                    id: actorId
                }
            },
            inputs:{
                "VALUE":{
                    "block": {
                        type:"colorsensor_gethexcolor_block"
                    }
                }
            }
          },
          {
            kind: "block",
            type:"variables_call_out",
            fields:{
                "VAR":{
                    type: "field_variable",
                    id: actorId
                }
            },
            inputs:{
                "VALUE":{
                    "block": {
                        type:"colorsensor_getgrayscalecolor_block"
                    }
                }
            }
          }
        ],
      },
      {
        kind: "category",
        name: i18n.t("PLACEHOLDER_PARAMETERS"),
        colour: blockColor("parameters"),
        contents: [
          {
            kind: "block",
            type: "colorsensor_instantiation_block",
            inputs:{
              name:{
                  shadow: {
                      type: "text",
                      fields: {
                          TEXT: "ColorSensor"
                      }
                  }
              },
              uid:{
                  shadow: {
                      type: "math_number",
                      fields: {
                          NUM: 1
                      }
                  }
              }
          }
          },
        ],
      },
    ],
  };

  return colorSensorToolbox;
}

export default GetColorSensorToolbox;
