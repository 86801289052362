import Blockly from "blockly";
import i18n from "../../../translations/i18n";
import { blockColor, addSeparator } from "../../BlockProperties";

Blockly.Blocks["spawner_instantiation_block"] = {
  init: function () {
    this.appendDummyInput("class_type")
      .setAlign(Blockly.ALIGN_RIGHT)
      //.appendField(addSeparator("Disc Spawner"));
      .appendField(addSeparator(i18n.t("PLACEHOLDER_SPAWNER")))
    this.appendValueInput("name")
      .setCheck("String")
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(i18n.t("PLACEHOLDER_NAME"));
    this.appendValueInput("uid")
      .setCheck("Number")
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(i18n.t("PLACEHOLDER_UID"));
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(blockColor("parameters"));
    this.setTooltip(i18n.t("SPAWNER_INSTANTIATE_TOOLTIP"));
    this.setHelpUrl("");
  },
};

Blockly.Blocks["spawner_setstate_block"] = {
  init: function () {
    this.appendDummyInput()
      .appendField(addSeparator(i18n.t("SPAWNER_SET_STATE")))
      .appendField(i18n.t("PLACEHOLDER_STATE") + ":")
      .appendField(
        new Blockly.FieldDropdown([
          [i18n.t("PLACEHOLDER_ON"), "1"],
          [i18n.t("PLACEHOLDER_OFF"), "0"],
        ]),
        "state"
      );
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(blockColor("actions"));
    this.setTooltip(i18n.t("SPAWNER_SET_STATE_TOOLTIP"));
    this.setHelpUrl("");
  },
};
