import "./BlocklyScreen.scss";
import React, { BaseSyntheticEvent, useEffect, useState } from "react";
import { BlocklyWorkspace, WorkspaceSvg } from "react-blockly";
import { Workspace } from "blockly";
import * as Blockly from "blockly/core";
import BlocklyPy from "blockly/python";
import * as PtBr from "blockly/msg/pt-br";
import * as En from "blockly/msg/en";
import * as Es from "blockly/msg/es";
import { useParams, useSearchParams } from "react-router-dom";
import ToolboxCategories from "./ToolboxCategoriesV3";
import FAB from "../commons/Button/FloatingActionButton";
import { FaPython, FaCamera, FaRedo, FaDownload } from "react-icons/fa";
import projectService from "../../services/project.service";
import { toastPromise } from "../commons/Toast/ToastPromise";
import { useTranslation } from "react-i18next";
import Modal from "react-modal";
import Button from "../commons/Button";
import indentString from "indent-string";
import initialSetup from "./GetInitialSetup";
import downloadScreenshot from "./Screenshot";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { VuplexPolyfill } from "../../helpers/VuplexPolyfill";
import ErrorHandling from "../commons/ErrorHandling/ErrorHandling";
import { useNavigateContext } from "../../contexts/navigationContext";
import { isSessionExpired, verifyAuthTokens } from "../../services/service";
import { SceneModel } from "../../models/Scene";
import modelService from "../../services/modelService";
import { Model } from "../../models/Model";

const setLocale = function (locale: any) {
  if(!locale) return;
  Object.keys(locale).forEach(function (k) {
    Blockly.Msg[k] = locale[k];
  });
};

const BlocklyLangs: { [key: string]: Object } = {
  "pt-BR": PtBr,
  en: En,
  es: Es,
};

const BlocklyScreen = () => {
  const navigate = useNavigateContext();
  const [actorId, setActorId] = useState("");

  var myWorkspace: WorkspaceSvg;
  const [initialXml, setInitialXml] = useState<string | null>(null);
  const [loadingInitialXml, setLoadingInitialXml] = useState(false); //usado para recarregar a tela de blocos quando receber o xml inicial
  const [actors, setActors] = useState<SceneModel[]>();

  var xml: string;
  var code: string;
  const [codeToShow, setCodeToShow] = useState("");

  //Get url params
  const [searchParams] = useSearchParams();
  let { project_id } = useParams();
  const projectId = project_id !== undefined ? project_id : "";
  const modelName = searchParams.get("model_name") ?? "";
  const modelId = searchParams.get("model_id") ?? "";
  const modelType = searchParams.get("model_type") ?? "";
  const readOnly = searchParams.get("read_only") === "1";

  const { i18n, t } = useTranslation();

  
  setLocale(BlocklyLangs[i18n.language]);
  
  //FIXING PT-BR
  //setLocale(PtBr);

  const [modalIsOpenReset, setIsOpenReset] = React.useState(false);
  const [modalIsOpenViewCode, setIsOpenViewCode] = React.useState(false);

  function openModalReset() {
    setIsOpenReset(true);
  }

  function closeModalReset() {
    setIsOpenReset(false);
  }

  function openModalViewCode() {
    setIsOpenViewCode(true);
  }

  function closeModalViewCode() {
    setIsOpenViewCode(false);
  }

  const getActors = async () => {
    const response = await projectService.getAllProjectProperties(projectId);
    
    let actors = response.feedback.data.rosStructure?.scene?.models?.map((m: any) => new SceneModel({...m}));
    const responseModels = await modelService.listModels();
    const models = responseModels.feedback.data;
    let dict: {[key: string] : string} = {};
    models.map((m: Model) => dict[m.metaModel.tag] = m.metaModel.type)
    actors = actors?.filter((actor: SceneModel) => (["Robot", "Sensor", "Actuator"].includes(dict[actor.model_type]) && actor.model_type !== "Floor"))
    setActors(actors ?? []);
    localStorage.setItem("actors", JSON.stringify(actors));
  }



  useEffect(() => {
    const getInitialXml = async () => {
      if (loadingInitialXml) {
        return;
      }
      try {
        if (modelName === "") {
          setLoadingInitialXml(true);
          return;
        }
        const response = await toastPromise(
          projectService.getXmlByObjectName(modelName, projectId)
        );
        if (response !== undefined && response.success) {
          const data = response.feedback.data;
          setInitialXml(data);
        } else if (response.feedback.tag === "objectNotFound") {
          setInitialXml(
            initialSetup.getInitialSetupXml(modelName, modelId, modelType)
          );
        } else {
          setInitialXml(null);
        }
      } catch (err) {
        const data = err as ApiResponse;
        if (!window.vuplex) {
          window.vuplex = new VuplexPolyfill();
        }
        if(err.response && err.response.data?.feedback.tag === "invalidRefreshToken"){
          window.vuplex.postMessage({
            action: "invalidRefreshToken",
            success: false,
          });
        }
        else{
          window.vuplex.postMessage({
            action: "lostConnection",
            success: false,
            code: data.code,
            tag: data.feedback?.tag,
          });
        }
        if(!verifyAuthTokens() || isSessionExpired(err)) navigate("/login")
      }
      setLoadingInitialXml(true);
    };

    getInitialXml();
  });  
  
  useEffect(() => {
    getActors();
  }, [])

  function workspaceDidChange(workspace: WorkspaceSvg) {
    myWorkspace = workspace;
    let c: string = BlocklyPy.workspaceToCode(workspace);

    let usedVars = Blockly.Variables.allUsedVarModels(workspace as Workspace);

    if (usedVars.length > 0 && actorId === "") {
      setActorId(usedVars[0].getId());
    }

    for (const variable in usedVars) {
      c = c.replace(
        "self." + usedVars[variable]["name"] + " = None",
        "  self." + usedVars[variable]["name"] + " = None"
      );
    }

    let upperCode;
    if (modelType === "Supervisor") {
      upperCode =
        "class supervisorCode(codeTemplate):\n  def __init__(self):\n";
    } else {
      upperCode = "class actorCode(codeTemplate):\n  def __init__(self):\n";
    }

    c = upperCode + indentString(c, 2) + "\n";

    let codeLines = c.split("\n");

    let newC = "";
    let globalVars = "";
    for (var line in codeLines) {
      var stringLine = codeLines[line];
      if (
        stringLine.search(/(  self.currentActor\(\).createGlobalVariable)/) ===
          0 ||
        stringLine.search(
          /(  self.currentActor\(\).createGlobalOutputInterface)/
        ) === 0
      ) {
        globalVars += "  " + codeLines[line] + "\n";
      } else {
        if (stringLine !== "") {
          newC += stringLine + "\n";
        }
      }
    }

    if (globalVars === "") {
      globalVars = "    pass";
    }
    newC = newC + "  def globalVars(self):\n" + globalVars;

    code = newC;

    var xmlDom = Blockly.Xml.workspaceToDom(workspace as Workspace);

    if (
      (code === codeToShow && xml === Blockly.Xml.domToPrettyText(xmlDom)) ||
      readOnly
    )
      return;

    setCodeToShow(code);
    xml = Blockly.Xml.domToPrettyText(xmlDom);
    try{
      projectService.saveCode(modelName, code, xml, projectId);
    } catch(err) {
      if(err.response && err.response.data?.feedback.tag === "invalidRefreshToken"){
        window.vuplex.postMessage({
          action: "invalidRefreshToken",
          success: false,
        });
      }
      else{
        window.vuplex.postMessage({
          action: "lostConnection",
          success: false,
        });
      }
    }
  }

  function onInject(workspace: any) {
    function configureContextMenu(menuOptions: any, e: any) {
      menuOptions.push(
        Blockly.ContextMenu.workspaceCommentOption(workspace, e)
      );
    }

    workspace.configureContextMenu = configureContextMenu;
  }

  const downloadPython = () => {
    const element = document.createElement("a");
    const codeFile = new Blob([code], { type: "text/plain" });
    element.href = URL.createObjectURL(codeFile);
    element.download = modelName + ".py";
    document.body.appendChild(element); // Required for this to work in FireFox
    element.click();
    element.remove();
  };

  const downloadBlocksScreenshot = () => {
    downloadScreenshot(myWorkspace, modelName);
  };

  const resetCode = async (event: BaseSyntheticEvent) => {
    try{
      event.target.disabled = true;
      await projectService.saveCode(
        modelName,
        initialSetup.getInitialSetupPython(modelName, modelId, modelType),
        initialSetup.getInitialSetupXml(modelName, modelId, modelType),
        projectId
      );
      event.target.disabled = false;
      window.location.reload();
    } catch (err){
      event.target.disabled = false;
      if(err.response && err.response.data?.feedback.tag === "invalidRefreshToken"){
        window.vuplex.postMessage({
          action: "invalidRefreshToken",
          success: false,
        });
      }
      else{
        window.vuplex.postMessage({
          action: "lostConnection",
          success: false,
        });
      }
      ErrorHandling(err, navigate);
    }
   
  };

  const actions = [
    {
      label: i18n.t("components.blockly.blocklyScreen.resetCode"),
      //icon: readOnly ? <FaRedo className="button-disabled"/> : <FaRedo />,
      icon: <FaRedo />,
      onClick: openModalReset,
      disabled: readOnly,
    },
    {
      label: i18n.t("components.blockly.blocklyScreen.seeCode"),
      icon: <FaPython />,
      onClick: openModalViewCode,
    },
    {
      label: i18n.t("components.blockly.blocklyScreen.downloadScreenshot"),
      icon: <FaCamera />,
      onClick: downloadBlocksScreenshot,
    },
    {
      label: i18n.t("commons.actions.export") + " .py",
      icon: <FaDownload />,
      onClick: downloadPython,
    },
  ];

  return projectId === "" ? (
    <div></div>
  ) : !loadingInitialXml || !actors ? (
    <div>{t("components.blockly.blocklyScreen.loadingCode")}</div>
  ) : initialXml === null ? (
    <div>{t("components.blockly.blocklyScreen.failedToLoadCode")}</div>
  ) : (
    <div className="blockly">
      <BlocklyWorkspace
        toolboxConfiguration={
          readOnly ? undefined : ToolboxCategories(modelType, actorId)
        }
        initialXml={initialXml}
        className="fillHeight"
        workspaceConfiguration={{
          readOnly: readOnly,
          theme: "zelos",
          renderer: "zelos",
          collapse: false,
          comments: true,
          disable: true,
          maxBlocks: Infinity,
          maxInstances: {
            define_actor_block: 1,
            procedures_setup: 1,
            procedures_setup_supervisor: 1,
            procedures_run: 1,
          },
          trashcan: true,
          horizontalLayout: false,
          toolboxPosition: "start",
          css: true,
          media: "https://blockly-demo.appspot.com/static/media/",
          rtl: false,
          scrollbars: true,
          sounds: true,
          oneBasedIndex: true,
          grid: {
            spacing: 20,
            length: 1,
            colour: "#888",
            snap: true,
          },
          zoom: {
            controls: true,
            startScale: 0.7,
            maxScale: 3,
            minScale: 0.3,
            scaleSpeed: 1.2,
            pinch: true,
          },
          move: {
            wheel: true,
          },
        }}
        onWorkspaceChange={workspaceDidChange}
        onInject={onInject}
      />
      <FAB actions={actions} />
      {/* <FAB actions={actions} /> */}
      <Modal
        isOpen={modalIsOpenReset}
        onRequestClose={closeModalReset}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "30%",
          },
        }}
      >
        <h2>{t("components.blockly.blocklyScreen.resetCode")}</h2>
        <div>{t("components.blockly.blocklyScreen.resetModal.message")}</div>
        <br />
        <div className="d-flex justify-content-around">
          <Button
            onClick={resetCode}
            label={i18n.t("Confirmar")}
            type="reset"
          />
          <Button
            onClick={closeModalReset}
            label={i18n.t("commons.actions.cancel")}
            type="submit"
          />
        </div>
      </Modal>

      <Modal
        isOpen={modalIsOpenViewCode}
        onRequestClose={closeModalViewCode}
        style={{
          content: {
            top: "50%",
            left: "50%",
            right: "auto",
            bottom: "auto",
            marginRight: "-50%",
            transform: "translate(-50%, -50%)",
            maxWidth: "80%",
            maxHeight: "90vh",
            paddingBottom: "0",
            overflow: "hidden",
          },
        }}
      >
        <div className="d-flex flex-column">
          <div className="code">
            <SyntaxHighlighter
              showLineNumbers={true}
              wrapLines={true}
              language="python"
            >
              {codeToShow}
            </SyntaxHighlighter>
          </div>

          {/* <br /> */}
          <div className="d-flex justify-content-around footer">
            <Button
              onClick={closeModalViewCode}
              label={i18n.t("commons.actions.close")}
            />
          </div>
        </div>
      </Modal>
    </div>
  );
};

export default BlocklyScreen;
