import BlocklyPY from "blockly/python";
import Blockly from "blockly";

BlocklyPY["rangesensor_instantiation_block"] = function (block) {
  var name = BlocklyPY.valueToCode(block, "name", BlocklyPY.ORDER_ATOMIC);
  var uid = BlocklyPY.valueToCode(block, "uid", BlocklyPY.ORDER_ATOMIC);
  var code = `RangeSensor(${name},${uid})`;
  return [code, BlocklyPY.ORDER_NONE];
};

BlocklyPY["rangesensor_getrange_block"] = function (block) {
  var dropdown_unit = block.getFieldValue("unit");
  var code = `getRange('${dropdown_unit}')`;
  return [code, BlocklyPY.ORDER_NONE];
};
