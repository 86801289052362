import { blockColor } from "../BlockProperties"
import "./block";
import "./pythonCode";

function GetAdvancedToolbox(actorId: string){

    let advancedToolbox = [
        {
            kind: "LABEL",
            text: "Common:",
            "web-class": "ioLabel",
        },
        {
            kind: "block",
            type:"variables_call_out",
            fields:{
                "VAR":{
                    type: "field_variable",
                    id: actorId
                }
            },
            inputs:{
                VALUE:{
                    block: {
                        type: "actor_getinterface_block",
                        inputs:{
                            interface_name:{
                                shadow: {
                                    type: "text",
                                    fields: {
                                        TEXT: "InterfaceName"
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        {
            kind: "LABEL",
            text: "Input:",
            "web-class": "ioLabel",
        },
        {
            kind: "block",
            type:"variables_call",
            fields:{
                "VAR":{
                    type: "field_variable",
                    id: actorId
                }
            },
            inputs:{
                VALUE:{
                    block: {
                        type: "actor_setinput_block",
                        inputs:{
                            input_name:{
                                shadow: {
                                    type: "text",
                                    fields: {
                                        TEXT: "InputName"
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        {
            kind: "block",
            type:"variables_call_out",
            fields:{
                "VAR":{
                    type: "field_variable",
                    id: actorId
                }
            },
            inputs:{
                VALUE:{
                    block: {
                        type: "actor_getinput_block",
                        inputs:{
                            input_name:{
                                shadow: {
                                    type: "text",
                                    fields: {
                                        TEXT: "InputName"
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        {
            kind: "LABEL",
            text: "Output:",
            "web-class": "ioLabel",
        },
        {
            kind: "block",
            type:"variables_call",
            fields:{
                "VAR":{
                    type: "field_variable",
                    id: actorId
                }
            },
            inputs:{
                VALUE:{
                    block: {
                        type: "actor_setoutput_block",
                        inputs:{
                            output_name:{
                                shadow: {
                                    type: "text",
                                    fields: {
                                        TEXT: "OutputName"
                                    }
                                }
                            }
                        }
                    }
                }
            }
        },
        // {
        //     kind: "block",
        //     type: "actor_getinterface_block",
        // },
        {
            kind: "LABEL",
            text: "Global Variable:",
            "web-class": "ioLabel",
        },
        {
            kind: "block",
            type: "actor_createglobalvar_block",
            inputs:{
                name:{
                    shadow: {
                        type: "text",
                        fields: {
                            TEXT: "GlobalName"
                        }
                    }
                },
                value:{
                    shadow: {
                        type: "math_number"
                    }
                }
            }
        },
        {
            kind: "block",
            type: "actor_setglobalvalue_block",
            inputs:{
                name:{
                    shadow: {
                        type: "text",
                        fields: {
                            TEXT: "GlobalName"
                        }
                    }
                },
                value:{
                    shadow: {
                        type: "math_number"
                    }
                }
            }
        },
        {
            kind: "block",
            type: "actor_getglobalvalue_block",
            inputs:{
                name:{
                    shadow: {
                        type: "text",
                        fields: {
                            TEXT: "GlobalName"
                        }
                    }
                }
            }
        },
        {
            kind: "LABEL",
            text: "Global Interface:",
            "web-class": "ioLabel",
        },
        {
            kind: "block",
            type: "actor_createglobaloutinterface_block",
            inputs:{
                name:{
                    shadow: {
                        type: "text",
                        fields: {
                            TEXT: "InterfaceName"
                        }
                    }
                }
            }
        },
        {
            kind: "block",
            type: "actor_setglobalinterface_block",
            inputs:{
                name:{
                    shadow: {
                        type: "text",
                        fields: {
                            TEXT: "InterfaceName"
                        }
                    }
                }
            }
        },
        {
            kind: "block",
            type: "actor_getglobalinterfacevalue_block",
            inputs:{
                name:{
                    shadow: {
                        type: "text",
                        fields: {
                            TEXT: "InterfaceName"
                        }
                    }
                }
            }
        },
        
        
    ];
    
    return advancedToolbox;

}

export default GetAdvancedToolbox;
