import Blockly from "blockly";
import { blockColor, addSeparator } from "../../../BlockProperties";
import i18n from "../../../../translations/i18n";
import "./pythonCode";

//================================================================================================================
//================================================= NTUbot BLOCKS =================================================
//================================================================================================================

Blockly.Blocks["ntubot_instantiation_block"] = {
  init: function () {
    this.appendDummyInput("class_type")
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(addSeparator("NTUbot"));
    this.appendValueInput("name")
      .setCheck("String")
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(i18n.t("PLACEHOLDER_NAME") + ":");
    this.appendValueInput("uid")
      .setCheck("Number")
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(i18n.t("PLACEHOLDER_UID") + ":");
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(blockColor("parameters"));
    this.setTooltip(i18n.t("NTUBOT_INSTANTIATE_TOOLTIP"));
    this.setHelpUrl("");
  },
};