import Blockly from "blockly";
import { blockColor, addSeparator } from "../../BlockProperties";
import i18n from "../../../translations/i18n";
import "./pythonCode";

Blockly.Blocks["robot2wheels_setwheelsvelocities_block"] = {
    init: function () {
      this.appendDummyInput("class_type")
        .setAlign(Blockly.ALIGN_RIGHT)
        //.appendField(addSeparator("set velocities "));
        .appendField(addSeparator(i18n.t("ROBOT2WHEELS_SETWHEELSVELOCITIES")));
      this.appendValueInput("left_wheel_vel")
        .setCheck("Number")
        .setAlign(Blockly.ALIGN_RIGHT)
        .appendField(i18n.t("PLACEHOLDER_LEFTWHEEL") + ":");
      this.appendValueInput("right_wheel_vel")
        .setCheck("Number")
        .setAlign(Blockly.ALIGN_RIGHT)
        .appendField(i18n.t("PLACEHOLDER_RIGHTWHEEL") + ":");
      this.appendDummyInput()
        .appendField(
          new Blockly.FieldDropdown([
            ["km/h", "km/h"],
            ["m/s", "m/s"],
            ["cm/s", "cm/s"],
            ["mm/s", "mm/s"]
            ]),
          "unit")
      this.setOutput(true, "Any");
      this.setInputsInline(true);
      this.setColour(blockColor("actions"));
      this.setTooltip(i18n.t("ROBOT2WHEELS_SETWHEELSVELOCITIES_TOOLTIP"));
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["robot2wheels_setleftwheelvelocity_block"] = {
    init: function () {
      this.appendValueInput("left_wheel_vel")
        .setCheck("Number")
        .appendField(i18n.t("ROBOT2WHEELS_SETLEFTWHEELVELOCITY") + " "+ i18n.t("PLACEHOLDER_IN") );
      this.appendDummyInput()
        .appendField(
          new Blockly.FieldDropdown([
            ["km/h", "km/h"],
            ["m/s", "m/s"],
            ["cm/s", "cm/s"],
            ["mm/s", "mm/s"]
            ]),
          "unit")
      this.setOutput(true, "Any");
      this.setInputsInline(true);
      this.setColour(blockColor("actions"));
      this.setTooltip(i18n.t("ROBOT2WHEELS_SETLEFTWHEELVELOCITY_TOOLTIP"));
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["robot2wheels_setrightwheelvelocity_block"] = {
    init: function () {
        this.appendValueInput("right_wheel_vel")
            .setCheck("Number")
            .appendField(i18n.t("ROBOT2WHEELS_SETRIGHTWHEELVELOCITY") + " "+ i18n.t("PLACEHOLDER_IN") );
        this.appendDummyInput()
            .appendField(
                new Blockly.FieldDropdown([
                ["km/h", "km/h"],
                ["m/s", "m/s"],
                ["cm/s", "cm/s"],
                ["mm/s", "mm/s"]
                ]),
                "unit")
        this.setOutput(true, "Any");
        this.setInputsInline(true);
        this.setColour(blockColor("actions"));
        this.setTooltip(i18n.t("ROBOT2WHEELS_SETRIGHTWHEELVELOCITY_TOOLTIP"));
        this.setHelpUrl("");
    }
  }

  Blockly.Blocks["robot2wheels_getwheelsvelocities_block"] = {
    init: function () {
        this.appendDummyInput()
          .setAlign(Blockly.ALIGN_CENTRE)
          //.appendField("get wheels velocities in ");
          .appendField(i18n.t("ROBOT2WHEELS_GETWHEELSVELOCITIES"));
        this.appendDummyInput()
          .appendField(
              new Blockly.FieldDropdown([
              ["km/h", "km/h"],
              ["m/s", "m/s"],
              ["cm/s", "cm/s"],
              ["mm/s", "mm/s"]
              ]),
              "unit")
        this.setOutput(true, "Any");
        this.setInputsInline(true);
        this.setColour(blockColor("sensing"));
        this.setTooltip(i18n.t("ROBOT2WHEELS_GETWHEELSVELOCITIES_TOOLTIP"));
        this.setHelpUrl("");
      },
  }
  
  Blockly.Blocks["robot2wheels_getleftwheelvelocity_block"] = {
    init: function () {
      this.appendDummyInput()
        .setAlign(Blockly.ALIGN_CENTRE)
        //.appendField("get left wheel velocity in ");
        .appendField(i18n.t("ROBOT2WHEELS_GETLEFTWHEELVELOCITY"));
      this.appendDummyInput()
        .appendField(
            new Blockly.FieldDropdown([
            ["km/h", "km/h"],
            ["m/s", "m/s"],
            ["cm/s", "cm/s"],
            ["mm/s", "mm/s"]
            ]),
            "unit")
      this.setOutput(true, "Any");
      this.setInputsInline(true);
      this.setColour(blockColor("sensing"));
      // this.setTooltip("Returns the linear velocity of the left wheel of the robot.");
      this.setTooltip(i18n.t("ROBOT2WHEELS_GETLEFTWHEELVELOCITY_TOOLTIP"));
      this.setHelpUrl("");
    },
  };
  
  Blockly.Blocks["robot2wheels_getrightwheelvelocity_block"] = {
    init: function () {
      this.appendDummyInput()
        .setAlign(Blockly.ALIGN_CENTRE)
        //.appendField("get right wheel velocity in ");
        .appendField(i18n.t("ROBOT2WHEELS_GETRIGHTWHEELVELOCITY"));
      this.appendDummyInput()
        .appendField(i18n.t("PLACEHOLDER_IN"))
        .appendField(
            new Blockly.FieldDropdown([
            ["km/h", "km/h"],
            ["m/s", "m/s"],
            ["cm/s", "cm/s"],
            ["mm/s", "mm/s"]
            ]),
            "unit")
      this.setOutput(true, "Any");
      this.setInputsInline(true);
      this.setColour(blockColor("sensing"));
      // this.setTooltip("Returns the linear velocity of the right wheel of the robot.");
      this.setTooltip(i18n.t("ROBOT2WHEELS_GETRIGHTWHEELVELOCITY_TOOLTIP"));
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["robot2wheels_rotate_block"] = {
    init: function () {
      this.appendDummyInput()
        .setAlign(Blockly.ALIGN_CENTRE)
        //.appendField("rotate around ")
        .appendField(addSeparator(i18n.t("ROBOT2WHEELS_ROTATE")))
        .appendField(i18n.t("PLACEHOLDER_AXIS") + ":")
        .appendField(
            new Blockly.FieldDropdown([
            [i18n.t("PLACEHOLDER_CENTER"), "center"],
            [i18n.t("PLACEHOLDER_LEFTWHEEL"), "left"],
            [i18n.t("PLACEHOLDER_RIGHTWHEEL"), "right"],
            ]),
            "axis")
      this.appendValueInput("angle")
        .setCheck("Number")
        .setAlign(Blockly.ALIGN_RIGHT)
        .appendField(i18n.t("PLACEHOLDER_ANGLE") + ":")
      this.appendDummyInput()
        .appendField(
            new Blockly.FieldDropdown([
            [i18n.t("PLACEHOLDER_DEGREES"), "deg"],
            [i18n.t("PLACEHOLDER_RADIANS"), "rad"],
            ]),
            "angle_unit")
      this.appendDummyInput()
        //.appendField("at speed ")
      this.appendValueInput("velocity")
        .setCheck("Number")
        .setAlign(Blockly.ALIGN_RIGHT)
        .appendField(i18n.t("PLACEHOLDER_VELOCITY") + ":")
      this.appendDummyInput()
        .appendField(
            new Blockly.FieldDropdown([
            ["km/h", "km/h"],
            ["m/s", "m/s"],
            ["cm/s", "cm/s"],
            ["mm/s", "mm/s"]
            ]),
            "vel_unit")
      this.setOutput(true, "Any");
      this.setInputsInline(true);
      this.setColour(blockColor("actions"));
      // this.setTooltip("Returns the linear velocity of the right wheel of the robot.");
      this.setTooltip(i18n.t("ROBOT2WHEELS_ROTATE_TOOLTIP"));
      this.setHelpUrl("");
    },
  };

  
  Blockly.Blocks["robot2wheels_moveforward_block"] = {
    init: function () {
      this.appendDummyInput()
        .setAlign(Blockly.ALIGN_CENTRE)
        //.appendField("move forward by")
        .appendField(addSeparator(i18n.t("ROBOT2WHEELS_MOVE_FORWARD")))
      this.appendValueInput("distance")
        .setCheck("Number")
        .setAlign(Blockly.ALIGN_RIGHT)
        .appendField(i18n.t("PLACEHOLDER_DISTANCE") + ":")
      this.appendDummyInput()
        .appendField(
            new Blockly.FieldDropdown([
            ["km", "km"],
            ["m", "m"],
            ["cm", "cm"],
            ["mm", "mm"],
            ["um", "um"],
            ["nm", "nm"]
            ]),
          "distance_unit"
        );
      this.appendDummyInput()
        //.appendField(" at speed ")
        .appendField(i18n.t("PLACEHOLDER_VELOCITY") + ":")
      this.appendValueInput("velocity")
        .setCheck("Number")
        .setAlign(Blockly.ALIGN_RIGHT)
      this.appendDummyInput()
        .appendField(
            new Blockly.FieldDropdown([
            ["km/h", "km/h"],
            ["m/s", "m/s"],
            ["cm/s", "cm/s"],
            ["mm/s", "mm/s"]
            ]),
            "vel_unit")
      this.setOutput(true, "Any");
      this.setInputsInline(true);
      this.setColour(blockColor("actions"));
      this.setTooltip(i18n.t("ROBOT2WHEELS_MOVE_FORWARD_TOOLTIP"));
      this.setHelpUrl("");
    },
  };