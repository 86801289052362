import { blockColor } from "../../BlockProperties";
import "./block";
import "./pythonCode";
import "./customField";
import i18n from "../../../translations/i18n";

const isExpanded = true;

function GetDobotToolbox(actorId: string){

    let dobotToolbox: any = {
        kind: "category",
        name: "Dobot",
        // colour: blockColor("dobot"),
        expanded: true,
        contents: [
          {
            kind: "category",
            name: i18n.t("PLACEHOLDER_ACTIONS"),
            colour: blockColor("actions"),
            contents: [
              {
                  kind: "block",
                  type:"variables_call",
                  fields:{
                      "VAR":{
                        type: "field_variable",
                        id: actorId
                      }
                  },
                  inputs:{
                      VALUE:{
                          block: {
                              type: "dobot_setjointangle_block",
                              fields:{
                                "unit": "deg"
                              },
                              inputs:{
                                  joint:{
                                      shadow: {
                                          type: "math_number",
                                          fields: {
                                              NUM: 1
                                          }
                                      }
                                  },
                                  angle:{
                                      shadow: {
                                          type: "math_number",
                                          fields: {
                                              NUM: 0
                                          }
                                      }
                                  },           
                              }
                          }
                      }
                  }
              },
                {
                  kind: "block",
                  type:"variables_call",
                  fields:{
                      "VAR":{
                          type: "field_variable",
                          id: actorId
                      }
                  },
                  inputs:{
                      VALUE:{
                          block: {
                              type: "dobot_setjointangles_block",
                              fields:{
                                "unit": "deg"
                              },
                              inputs:{
                                  angles:{
                                      shadow: {
                                          type: "lists_create_with",
                                          inline: true,
                                          extraState: {
                                            itemCount: 4 
                                          },
                                          inputs:{
                                            ADD0: {
                                              shadow: {
                                                type: "math_number",
                                                fields: {
                                                    NUM: 0
                                                }
                                            }
                                            },
                                            ADD1: {
                                              shadow: {
                                                type: "math_number",
                                                fields: {
                                                    NUM: 0
                                                }
                                            }
                                            },
                                            ADD2: {
                                              shadow: {
                                                type: "math_number",
                                                fields: {
                                                    NUM: 0
                                                }
                                            }
                                            },
                                            ADD3: {
                                              shadow: {
                                                type: "math_number",
                                                fields: {
                                                    NUM: 0
                                                }
                                            }
                                            }
                                          }
                                      }
                                  },           
                              }
                          }
                      }
                  }
              },
              {
                kind: "block",
                type:"variables_call",
                fields:{
                    "VAR":{
                        type: "field_variable",
                        id: actorId
                    }
                },
                inputs:{
                    VALUE:{
                        block: {
                            type: "dobot_setsuctioncupstate_block",
                            fields:{
                              "state": "1"
                            },
                        }
                    }
                }
            },
            ],
          },
          {
            kind: "category",
            name: i18n.t("PLACEHOLDER_SENSING"),
            colour: blockColor("sensing"),
            contents: [
              {
                kind: "block",
                type:"variables_call_out",
                fields:{
                    "VAR":{
                        type: "field_variable",
                        id: actorId
                    }
                },
                inputs:{
                    "VALUE":{
                        "block": {
                            type:"dobot_getjointangle_block",
                            fields:{
                                "unit": "deg",
                            },
                            inputs:{
                              joint:{
                                  shadow: {
                                      type: "math_number",
                                      fields: {
                                          NUM: 0
                                      }
                                  }
                              }
                          }
                        }
                    }
                }
            },
              {
                kind: "block",
                type:"variables_call_out",
                fields:{
                    "VAR":{
                        type: "field_variable",
                        id: actorId
                    }
                },
                inputs:{
                    "VALUE":{
                        "block": {
                            type:"dobot_getjointsangles_block",
                            fields:{
                                "unit": "deg",
                            }
                        }
                    }
                }
            },
            ],
          },
          {
            kind: "category",
            name: i18n.t("PLACEHOLDER_PARAMETERS"),
            colour: blockColor("parameters"),
            contents: [
              {
                kind: "block",
                type: "dobot_instantiation_block",
                inputs:{
                  name:{
                      shadow: {
                          type: "text",
                          fields: {
                              TEXT: "Dobot"
                          }
                      }
                  },
                  uid:{
                      shadow: {
                          type: "math_number",
                          fields: {
                              NUM: 1
                          }
                      }
                  }
              }
              },
            ],
          },
        ],
      };

    return dobotToolbox;
}

export default GetDobotToolbox;