import BlocklyPY from "blockly/python";


BlocklyPY['controls_configscoresystem_block'] = function(block) {
  // TODO: Assemble Python into code variable.
  var code = 'self.getExecNode().configScoreSystem()\n';
  return code;
};

BlocklyPY['controls_getscore_block'] = function(block) {
  // TODO: Assemble Python into code variable.
  var code = 'self.getExecNode().getScore()';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, BlocklyPY.ORDER_NONE];
};


BlocklyPY['controls_setscore_block'] = function(block) {
  var value_score = BlocklyPY.valueToCode(block, 'score', BlocklyPY.ORDER_ATOMIC);
  // TODO: Assemble Python into code variable.
  var code = `self.getExecNode().setScore(${value_score})\n`;
  return code;
};