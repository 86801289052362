import BlocklyPY from "blockly/python";

BlocklyPY["dobot_instantiation_block"] = function (block) {
  var name = BlocklyPY.valueToCode(
    block,
    "name",
    BlocklyPY.ORDER_ATOMIC
  );
  var uid = BlocklyPY.valueToCode(
    block,
    "uid",
    BlocklyPY.ORDER_ATOMIC
  );
  var code = `Dobot(${name},${uid})`;
  return [code, BlocklyPY.ORDER_NONE]
};

BlocklyPY["dobot_setjointangle_block"] = function (block) {
  var value_joint = BlocklyPY.valueToCode(
    block,
    "joint",
    BlocklyPY.ORDER_ATOMIC
  );
  var dropdown_unit = block.getFieldValue("unit");
  var value_angle = BlocklyPY.valueToCode(
    block,
    "angle",
    BlocklyPY.ORDER_ATOMIC
  );
  var code = `setJointAngle(${value_joint},${value_angle},${dropdown_unit})`;
  return [code, BlocklyPY.ORDER_NONE]; // Passando type
};

BlocklyPY["dobot_setjointangles_block"] = function (block) {
  var dropdown_unit = block.getFieldValue("unit");
  var value_angles = BlocklyPY.valueToCode(
    block,
    "angles",
    BlocklyPY.ORDER_ATOMIC
  );
  var code = `setJointAngles(${value_angles},${dropdown_unit})`;
  return [code, BlocklyPY.ORDER_NONE];
};

BlocklyPY["dobot_getjointangle_block"] = function (block) {
  var value_joint = BlocklyPY.valueToCode(
    block,
    "joint",
    BlocklyPY.ORDER_ATOMIC
  );
  var dropdown_unit = block.getFieldValue("unit");
  var code = `getJointAngle(${value_joint},${dropdown_unit})`;
  return [code, BlocklyPY.ORDER_NONE];
};

BlocklyPY["dobot_getjointsangles_block"] = function (block) {
  var dropdown_unit = block.getFieldValue("unit");
  var code = `getJointAngles(${dropdown_unit})`;
  return [code, BlocklyPY.ORDER_NONE];
};

BlocklyPY["dobot_setsuctioncupstate_block"] = function (block) {
  var value_state = block.getFieldValue("state");
  var code = `setSuctionCupState(${value_state})`;
  return [code, BlocklyPY.ORDER_NONE];
};
