import Blockly from "blockly";
import { blockColor, addSeparator } from "../../../BlockProperties";
import i18n from "../../../../translations/i18n";
import "./pythonCode";

//================================================================================================================
//================================================= Pioneer BLOCKS =================================================
//================================================================================================================

Blockly.Blocks["pioneer_instantiation_block"] = {
  init: function () {
    this.appendDummyInput("class_type")
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(addSeparator("Pioneer"));
    this.appendValueInput("name")
      .setCheck("String")
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(i18n.t("PLACEHOLDER_NAME") + ":");
    this.appendValueInput("uid")
      .setCheck("Number")
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(i18n.t("PLACEHOLDER_UID") + ":");
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(blockColor("parameters"));
    // this.setTooltip("Instantiate a Pioneer robot");
    //this.setTooltip("Instancia um robô Pioneer");
    this.setTooltip(i18n.t("PIONEER_INSTANTIATE_TOOLTIP"));
    this.setHelpUrl("");
  },
};

Blockly.Blocks["pioneer_getrange_block"] = {
  init: function () {
    this.appendDummyInput()
      .appendField(i18n.t("RANGESENSOR_GET_RANGE")+ " "+i18n.t("PLACEHOLDER_IN"))
      .appendField(
          new Blockly.FieldDropdown([
          ["km", "km"],
          ["m", "m"],
          ["cm", "cm"],
          ["mm", "mm"],
          ["um", "um"],
          ["nm", "nm"]
          ]),
        "unit"
      )
      .appendField(" ||");
    this.appendValueInput("index")
      .setCheck("Number")
      .appendField(i18n.t("PLACEHOLDER_INDEX") + ":")
      
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(blockColor("sensing"));
    // this.setTooltip("Returns the reading of a single Pioneer's range sensor.");
    this.setTooltip("Retorna a leitura do sensor de distância do Pioneer");
    this.setHelpUrl("");
  },
};

Blockly.Blocks["pioneer_getranges_block"] = {
  init: function () {
    this.appendDummyInput()
      .appendField(i18n.t("PIONEER_GET_RANGES") + " " + i18n.t("PLACEHOLDER_IN"))
      .appendField(
          new Blockly.FieldDropdown([
          ["km", "km"],
          ["m", "m"],
          ["cm", "cm"],
          ["mm", "mm"],
          ["um", "um"],
          ["nm", "nm"]
          ]),
        "unit"
      );
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(blockColor("sensing"));
    // this.setTooltip("Returns the reading of all of Pioneer's range sensors.");
    this.setTooltip("Retorna a leitura de todos os sensores de distância Pioneer'");
    this.setHelpUrl("");
  },
};