import BlocklyPY from "blockly/python";

BlocklyPY["robot2wheels_setwheelsvelocities_block"] = function (block) {
    var left_wheel_vel = BlocklyPY.valueToCode(
      block,
      "left_wheel_vel",
      BlocklyPY.ORDER_ATOMIC
    );
    var right_wheel_vel = BlocklyPY.valueToCode(
      block,
      "right_wheel_vel",
      BlocklyPY.ORDER_ATOMIC
    );
    var dropdown_unit = block.getFieldValue("unit");
    var code = `setWheelsVelocities(${left_wheel_vel},${right_wheel_vel},'${dropdown_unit}')`;
    return [code, BlocklyPY.ORDER_NONE];
  }

  BlocklyPY["robot2wheels_setleftwheelvelocity_block"] = function (block) {
    var left_wheel_vel = BlocklyPY.valueToCode(
      block,
      "left_wheel_vel",
      BlocklyPY.ORDER_ATOMIC
    );
    var dropdown_unit = block.getFieldValue("unit");
    var code = `setLeftWheelVelocity(${left_wheel_vel},'${dropdown_unit}')`;
    return [code, BlocklyPY.ORDER_NONE];
  }

  BlocklyPY["robot2wheels_setrightwheelvelocity_block"] = function (block) {
    var right_wheel_vel = BlocklyPY.valueToCode(
      block,
      "right_wheel_vel",
      BlocklyPY.ORDER_ATOMIC
    );
    var dropdown_unit = block.getFieldValue("unit");
    var code = `setRightWheelVelocity(${right_wheel_vel},'${dropdown_unit}')`;
    return [code, BlocklyPY.ORDER_NONE];
  }

  BlocklyPY["robot2wheels_getwheelsvelocities_block"] = function (block) {
    var dropdown_unit = block.getFieldValue("unit");
    var code = `getWheelsVelocities('${dropdown_unit}')`;
    return [code, BlocklyPY.ORDER_NONE]; 
  };
  
  BlocklyPY["robot2wheels_getleftwheelvelocity_block"] = function (block) {
    var dropdown_unit = block.getFieldValue("unit");
    var code = `getLeftWheelVelocity('${dropdown_unit}')`;
    return [code, BlocklyPY.ORDER_NONE]; 
  };
  
  BlocklyPY["robot2wheels_getrightwheelvelocity_block"] = function (block) {
    var dropdown_unit = block.getFieldValue("unit");
    var code = `getRightWheelVelocity('${dropdown_unit}')`;
    return [code, BlocklyPY.ORDER_NONE]; 
  };
  
  BlocklyPY["robot2wheels_rotate_block"] = function (block) {
    var angle = BlocklyPY.valueToCode(
      block,
      "angle",
      BlocklyPY.ORDER_ATOMIC
    );
    var angle_unit = block.getFieldValue("angle_unit");
    var axis = block.getFieldValue("axis");
    var velocity = BlocklyPY.valueToCode(
      block,
      "velocity",
      BlocklyPY.ORDER_ATOMIC
    );
    var velocity_unit = block.getFieldValue("vel_unit");
    var code = `rotate(${angle},'${angle_unit}','${axis}',${velocity},'${velocity_unit}')`;
    return [code, BlocklyPY.ORDER_NONE];
  }

  BlocklyPY["robot2wheels_moveforward_block"] = function (block) {
    var distance = BlocklyPY.valueToCode(
      block,
      "distance",
      BlocklyPY.ORDER_ATOMIC
    );
    var distance_unit = block.getFieldValue("distance_unit");
    var velocity = BlocklyPY.valueToCode(
      block,
      "velocity",
      BlocklyPY.ORDER_ATOMIC
    );
    var velocity_unit = block.getFieldValue("vel_unit");
    var code = `moveForward(${distance},'${distance_unit}',${velocity},'${velocity_unit}')`;
    return [code, BlocklyPY.ORDER_NONE];
  }