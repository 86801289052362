import Blockly from "blockly";
import i18n from "../../../translations/i18n";
import { addSeparator, blockColor } from "../../BlockProperties";


Blockly.Blocks['controls_getsimulationstate_block'] = {
    init: function() {
        this.appendDummyInput()
            .appendField(i18n.t("SIMULATION_GET_STATE"));
        this.setInputsInline(true);
        this.setOutput(true, null);
        this.setColour(blockColor("controls"));
    this.setTooltip(i18n.t("SIMULATION_GET_STATE_TOOLTIP"));
    this.setHelpUrl("");
    }
};

Blockly.Blocks['controls_start_block'] = {
    init: function() {
      this.appendDummyInput()
          .appendField(i18n.t("SIMULATION_START"))
        //   .appendField(i18n.t("PLACEHOLDER_STATE") + ":")
        //   .appendField(new Blockly.FieldDropdown([["ON","True"], ["OFF","False"]]), "state");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(blockColor("controls"));
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['controls_pause_block'] = {
    init: function() {
      this.appendDummyInput()
          .appendField(i18n.t("SIMULATION_PAUSE"))
        //   .appendField(i18n.t("PLACEHOLDER_STATE") + ":")
        //   .appendField(new Blockly.FieldDropdown([["ON","True"], ["OFF","False"]]), "state");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(blockColor("controls"));
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['controls_stop_block'] = {
    init: function() {
      this.appendDummyInput()
          .appendField(i18n.t("SIMULATION_STOP"))
        //   .appendField(i18n.t("PLACEHOLDER_STATE") + ":")
        //   .appendField(new Blockly.FieldDropdown([["ON","True"], ["OFF","False"]]), "state");
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(blockColor("controls"));
   this.setTooltip("");
   this.setHelpUrl("");
    }
  };