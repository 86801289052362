import BlocklyPY from "blockly/python";

BlocklyPY["ntubot_instantiation_block"] = function (block) {
  var name = BlocklyPY.valueToCode(
    block,
    "name",
    BlocklyPY.ORDER_ATOMIC
  );
  var uid = BlocklyPY.valueToCode(
    block,
    "uid",
    BlocklyPY.ORDER_ATOMIC
  );
  var code = `NTUbot(${name},${uid})`;
  return [code, BlocklyPY.ORDER_NONE]
};
