import { NavigateFunction } from "react-router-dom";
import { toast } from "react-toastify";
import { isSessionExpired, verifyAuthTokens } from "../../../services/service";
import { toastError } from "../Toast/ToastError";

/**
 * Function used for handling errors that were not handled by the internal logic of a component.
 * @param error AxiosError of error 
 * @param navigate NavigateFunction used to navigate to other components if necessary
 */

function ErrorHandling(error: any, navigate: NavigateFunction){
    if(error?.response?.data?.feedback?.tag){
        toast.error(toastError(error), {toastId: error?.response?.data?.feedback?.tag});
    }
    if(isSessionExpired(error) || !verifyAuthTokens())  
        navigate("/login"); 
}

export default ErrorHandling;