import { useState } from "react";
import cn from "classnames";
import { BsList } from "react-icons/bs";

import "./FloatingActionButton.scss";
import React from "react";

/**
 * Component for Floating Action Buttons (icon buttons that trigger actions throught the application)
 */

const FAB = (props: {
  actions: {
    label: string;
    onClick: () => void;
    icon: JSX.Element;
    disabled?: boolean;
  }[];
}) => {
  const [open, setOpen] = useState(false);

  // Set open state to true if user hover over "ul" element
  const mouseEnter = () => setOpen(true);

  // Set open state to false if user hover out of "ul" element
  const mouseLeave = () => setOpen(false);

  return (
    <ul
      className="fab-container"
      onMouseEnter={mouseEnter}
      onMouseLeave={mouseLeave}
    >
      <li className="fab-button">
        <BsList />
      </li>
      {props.actions.map((action: any, index: any) => (
        <li
          style={{ transitionDelay: `${index * 25}ms` }}
          className={cn(
            "fab-action" + (action.disabled ? " button-disabled" : ""),
            { open }
          )}
          key={action.label}
          onClick={action.onClick}
        >
          {action.icon}
          <span className="tooltip">{action.label}</span>
        </li>
      ))}
    </ul>
  );
};

export default FAB;
