import Blockly from 'blockly'
import {addSeparator, blockColor} from '../BlockProperties' 
import "./pythonCode"
import i18n from '../../translations/i18n'

//================================================================================================================
//================================================= Dobot BLOCKS =================================================
//================================================================================================================

Blockly.Blocks["supervisor_instantiation_block"] = {
  init: function(){
    this.appendDummyInput("class_type")
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField("Supervisor");
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(blockColor("parameters"));
    this.setTooltip(i18n.t("SUPERVISOR_INSTANTIATE_TOOLTIP"));
    this.setHelpUrl("");
  }
}

Blockly.Blocks['supervisor_getactor_block'] = {
  init: function() {
      this.appendDummyInput()
        .setAlign(Blockly.ALIGN_CENTRE)
        .appendField(addSeparator(i18n.t("SUPERVISOR_GET_ACTOR")));
      this.appendValueInput("actor_name")
        .setCheck("String")
        .setAlign(Blockly.ALIGN_RIGHT)
        .appendField(i18n.t("PLACEHOLDER_NAME") + ":");
      this.setOutput(true, "Any");
      this.setInputsInline(true);
      this.setColour(blockColor('supervisor'));
      this.setTooltip(i18n.t("SUPERVISOR_GET_ACTOR_TOOLTIP"));
      this.setHelpUrl("");
  }
};

Blockly.Blocks['supervisor_connectinterfaces_block'] = {
  init: function() {
      this.appendDummyInput()
        .setAlign(Blockly.ALIGN_CENTRE)
        .appendField(addSeparator(i18n.t("SUPERVISOR_CONNECT_INTERFACES")))
      this.appendValueInput("interface1")
        .setCheck("String")
        .setAlign(Blockly.ALIGN_RIGHT)
        .appendField(i18n.t("PLACEHOLDER_INPUT") + ":")
      this.appendValueInput("interface2")
          .setCheck("String")
          .setAlign(Blockly.ALIGN_RIGHT)
          .appendField(i18n.t("PLACEHOLDER_OUTPUT") + ":")
      this.setOutput(true, "Any");
      this.setInputsInline(false);
      this.setColour(blockColor("supervisor"));
      this.setTooltip(i18n.t("SUPERVISOR_CONNECT_INTERFACES_TOOLTIP"));
      this.setHelpUrl("");
  }
};


  