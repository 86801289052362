import EventStatus from "../enums/EventStatus";
import EventType from "../enums/EventType";
import EventVisibility from "../enums/EventVisibility";
import User from "./User";

class MetaEvent {
  id: string;
  title: string;
  status: string;
  shortDescription: string;
  description: string;
  thumbnail: string;
  thumbnailBlob: File | null;
  visibility: EventVisibility;
  metaDropsVersion: string;
  type: EventType;
  owner?: string;

  constructor(values: {
    id?: string;
    title: string;
    shortDescription: string;
    description: string;
    thumbnail: string;
    thumbnailBlob: File | null;
    visibility: EventVisibility;
    metaDropsVersion?: string;
    status?: string;
    type?: EventType;
    owner?: string;
  }) {
    this.id = values.id ?? "";
    this.title = values.title;
    this.status = values.status ?? "UnderConstruction";
    this.description = values.description;
    this.shortDescription = values.shortDescription;
    this.thumbnail = values.thumbnail;
    this.thumbnailBlob = values.thumbnailBlob;
    this.visibility = values.visibility;
    this.metaDropsVersion = values.metaDropsVersion ?? "v0.0.1";
    this.type = values.type ?? EventType.Tournament;
    this.owner = values.owner;
  }
}
class EventRules {
  description: string;
  startRegistrationDate: Date;
  endRegistrationDate: Date;
  startSubmissionDate: Date;
  dueSubmissionDate: Date;
  maxGroups: number;
  maxUsersPerGroup: number;
  maxSubmissions: number;
  fee: {
    value: number;
    currency: string;
    type: string;
  };
  attachments: Array<Blob>

  constructor(values?: {
    startRegistrationDate?: Date;
    endRegistrationDate?: Date;
    startSubmissionDate?: Date;
    dueSubmissionDate?: Date;
    maxGroups?: number;
    maxUsersPerGroup?: number;
    maxSubmissions?: number;
    fee?: { value: number; currency: string; type: string };
    description?: string;
    regulation?: string;
    attachments: Array<Blob>
  }) {
    this.startRegistrationDate = values?.startRegistrationDate ?? new Date();
    this.endRegistrationDate = values?.endRegistrationDate ?? new Date();
    this.startSubmissionDate = values?.startSubmissionDate ?? new Date();
    this.dueSubmissionDate = values?.dueSubmissionDate ?? new Date();
    this.maxGroups = values?.maxGroups ?? 10;
    this.maxUsersPerGroup = values?.maxUsersPerGroup ?? 2;
    this.maxSubmissions = values?.maxSubmissions ?? 1;
    this.fee = values?.fee ?? { value: 0, currency: "R$", type: "team" };
    this.description = values?.description ?? "";
    this.attachments = []
  }
}

class EventProperties {
  roles: [];
  archived: boolean;
  group_id?: string;
  constructor(values: {
    roles: [],
    archived: boolean,
    group_id?: string
    }
  ){
    this.roles = values?.roles;
    this.archived = values?.archived;
    this.group_id = values?.group_id
  }
}

class EventInvites{
  users: User[];

  constructor(users: User[]){
    this.users = users;
  }
}

class DropsEvent {
  _id?: string;
  metaEvent: MetaEvent;
  eventRules: EventRules;
  invites?: EventInvites;
  eventProperties?: EventProperties;

  constructor(metaEvent: MetaEvent, eventRules: EventRules, invites?: User[], eventProperties?: EventProperties) {
    this.metaEvent = metaEvent;
    this.eventRules = eventRules;
    if(invites)
      this.invites = new EventInvites(invites);
    if(eventProperties)
      this.eventProperties = eventProperties;
  }
}

export { DropsEvent, MetaEvent, EventRules, EventProperties, EventInvites };
