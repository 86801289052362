import { blockColor } from "../BlockProperties";
import "./block";
import "./pythonCode";
import "./customField";
import i18n from "../../translations/i18n";

function GetSupervisorToolbox(actorId: string){

    let supervisorToolbox = [
        {
          kind: "category",
          name: i18n.t("PLACEHOLDER_FUNCTIONS"),
          expanded: true,
          colour: blockColor("supervisor"),
          contents: [
            // {
            //   kind: "block",
            //   type: "supervisor_getactor_block",
            // },
            {
                kind: "block",
                type:"variables_call_out",
                fields:{
                    VAR:{
                        type: "field_variable",
                        id: actorId
                    }
                },
                inputs:{
                    VALUE:{
                        block: {
                            type:"supervisor_getactor_block",
                            inputs:{
                              actor_name:{
                                    block: {
                                        type: "text_actor",
                                    }
                                }
                            }
                        }
                    }
                }
            },
            // {
            //   kind: "block",
            //   type: "supervisor_connectinterfaces_block",
            // },
            {
              kind: "block",
              type:"variables_call",
              fields:{
                  VAR:{
                      type: "field_variable",
                      id: actorId
                  }
              },
              inputs:{
                  VALUE:{
                      block: {
                          type:"supervisor_connectinterfaces_block",
                          inputs:{
                            interface1:{
                                // block: {
                                //     type: "text",
                                //     fields: {
                                //         TEXT: "EntityLocalName"
                                //     }
                                //   }
                                block:{
                                    kind: "block",
                                    type:"variables_call_out",
                                    fields:{
                                        "VAR":{
                                            type: "field_variable",
                                            id: actorId
                                        }
                                    },
                                    inputs:{
                                        VALUE:{
                                            block: {
                                                type: "actor_getinterface_block",
                                                inputs:{
                                                    interface_name:{
                                                        shadow: {
                                                            type: "text",
                                                            fields: {
                                                                TEXT: "Interface1"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }        
                                }
                            },
                            interface2:{
                            //   block: {
                            //       type: "text",
                            //       fields: {
                            //           TEXT: "EntityGlobalName"
                            //       }
                            //   }
                                block:{
                                    kind: "block",
                                    type:"variables_call_out",
                                    fields:{
                                        "VAR":{
                                            type: "field_variable",
                                            id: actorId
                                        }
                                    },
                                    inputs:{
                                        VALUE:{
                                            block: {
                                                type: "actor_getinterface_block",
                                                fields: {
                                                    interface_type_: "'output'"
                                                },
                                                inputs:{
                                                    interface_name:{
                                                        shadow: {
                                                            type: "text",
                                                            fields: {
                                                                TEXT: "Interface2"
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }        
                                }
                            },
                          }
                      }
                  }
              }
          },
          ],
        },
        {
          kind: "category",
          name: i18n.t("PLACEHOLDER_PARAMETERS"),
          colour: blockColor("parameters"),
          contents: [
            {
              kind: "block",
              type: "supervisor_instantiation_block",
            },
          ],
        },
        
      ];
    
    return supervisorToolbox
}
export default GetSupervisorToolbox;