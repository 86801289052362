import Blockly from "blockly";

const blockColors ={
    coppelia: '#fcba03',
    resources: '#6A77B4',
    
    //types
    actions: "#5CAD6E",
    parameters: '#9262BC', 
    sensing: "#65AFF0",
    instantiation: "#6c0000",
    controls: "#AE475D",

    //categories
    variables: "#c54889",
    supervisor: "#9e9b96",
    basic:"#655FDF",
    advanced: "#CE4242",
    math: "#398524"
}

export const blockColor = (type) => {return blockColors[type]};

function withSep(text){
    var sep = text + " ||" //new Blockly.FieldLabel(text + ' ||\n', 'sep');

    return sep
}

export const addSeparator = (text) => {return withSep(text)};
