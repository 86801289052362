import axios, { AxiosError } from "axios";
import userService from "./user.service";

const API_URL = process.env.REACT_APP_API_URL_DEV;
const ACCESS_TOKEN_STORAGE = "access_token";
const REFRESH_TOKEN_STORAGE = "refresh_token";
const KEEP_SESSION_STORAGE = "keep_session";
const REQUEST_TIMEOUT = 30000;

/**
 * Método para verificar se a autentição está expirada
 */
export const verifyAuthTokens = () =>
  localStorage.getItem(ACCESS_TOKEN_STORAGE) &&
  localStorage.getItem(REFRESH_TOKEN_STORAGE);

export const isSessionExpired = (error: any) =>
  error?.response?.data?.feedback?.tag === "invalidRefreshToken" ||
  error?.response?.data?.feedback?.tag === "wrongAuth";

/**
 * Get genérico
 */
const getRequest = async (
  action: string,
  config: any = null,
  forceRefresh: boolean | null = null
) => {
  if (!config) {
    config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_STORAGE)}`
      },
      timeout: REQUEST_TIMEOUT,
    };
  }

  try {
    if ("headers" in config && "Authorization" in config.headers && !verifyAuthTokens()) {
      throw "missingAuth";
    }
    const response = await axios.get(API_URL + action, config);
    const data = response.data as ApiResponse;
    window.vuplex?.postMessage({
      action: "updateAccessToken",
      success: true,
      access_token: localStorage.getItem(ACCESS_TOKEN_STORAGE),
      refresh_token: localStorage.getItem(REFRESH_TOKEN_STORAGE),
    });
    return data;
  } catch (err: any) {
    const error = err as AxiosError;
    if (error.response) {
      const apiResponse = err.response.data as ApiResponse;
      if (apiResponse && apiResponse.feedback) {
        if (
          apiResponse.feedback.tag === "wrongAuth" ||
          apiResponse.feedback.tag === "invalidRefreshToken"
        ) {
          userService.logout();
          throw err;
        }

        if (apiResponse.feedback.tag === "expiredAuth") {
          const refresh =
            forceRefresh !== null
              ? forceRefresh
              : localStorage.getItem(KEEP_SESSION_STORAGE) === "true";
          if (!refresh) {
            userService.logout();
            throw err;
          }
          try {
            await userService.refreshToken();
            window.vuplex?.postMessage({
              action: "updateAccessToken",
              success: true,
              access_token: localStorage.getItem(ACCESS_TOKEN_STORAGE),
            });
            config = {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  ACCESS_TOKEN_STORAGE
                )}`,
              },
              timeout: REQUEST_TIMEOUT,
            };
            const response = await axios.get(API_URL + action, config);
            const data = response.data as ApiResponse;
            return data;
          } catch (err) {
            userService.logout();
            throw err;
          }
        }
      }
    }
    throw err;
  }
};

/**
 * Post genérico
 */
const postRequest = async (
  action: string,
  body: any,
  config: any = null,
  forceRefresh: boolean | null = null
) => {
  if (!config) {
    config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_STORAGE)}`
      },
      timeout: REQUEST_TIMEOUT,
    };
  }

  try {
    if ("headers" in config && "Authorization" in config.headers && !verifyAuthTokens()) {
      throw "missingAuth";
    }
    const response = await axios.post(API_URL + action, body, config);
    const data = response.data as ApiResponse;
    window.vuplex?.postMessage({
      action: "updateAccessToken",
      success: true,
      access_token: localStorage.getItem(ACCESS_TOKEN_STORAGE),
      refresh_token: localStorage.getItem(REFRESH_TOKEN_STORAGE),
    });
    return data;
  } catch (err: any) {
    const error = err as AxiosError;
    if (error.response) {
      const apiResponse = err.response.data as ApiResponse;
      if (apiResponse && apiResponse.feedback) {
        if (
          apiResponse.feedback.tag === "wrongAuth" ||
          apiResponse.feedback.tag === "invalidRefreshToken"
        ) {
          userService.logout();
          throw err;
        }

        if (apiResponse.feedback.tag === "expiredAuth") {
          const refresh =
            forceRefresh !== null
              ? forceRefresh
              : localStorage.getItem(KEEP_SESSION_STORAGE) === "true";
          if (!refresh) {
            userService.logout();
            throw err;
          }
          try {
            await userService.refreshToken();
            window.vuplex?.postMessage({
              action: "updateAccessToken",
              success: true,
              access_token: localStorage.getItem(ACCESS_TOKEN_STORAGE),
            });
            config = {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  ACCESS_TOKEN_STORAGE
                )}`,
              },
              timeout: REQUEST_TIMEOUT,
            };
            const response = await axios.post(API_URL + action, body, config);
            const data = response.data as ApiResponse;
            return data;
          } catch (err) {
            userService.logout();
            throw err;
          }
        }
      }
    }
    throw err;
  }
};

/**
 * Delete genérico
 */
const deleteRequest = async (
  action: string,
  body: any,
  config: any = null,
  forceRefresh: boolean | null = null
) => {
  if (!config) {
    config = {
      headers: {
        Authorization: `Bearer ${localStorage.getItem(ACCESS_TOKEN_STORAGE)}`
      },
      timeout: REQUEST_TIMEOUT,
      data: body,
    };
  }

  try {
    if ("headers" in config && "Authorization" in config.headers && !verifyAuthTokens()) {
      throw "missingAuth";
    }
    const response = await axios.delete(API_URL + action, config);
    const data = response.data as ApiResponse;
    window.vuplex?.postMessage({
      action: "updateAccessToken",
      success: true,
      access_token: localStorage.getItem(ACCESS_TOKEN_STORAGE),
      refresh_token: localStorage.getItem(REFRESH_TOKEN_STORAGE),
    });
    return data;
  } catch (err: any) {
    const error = err as AxiosError;
    if (error.response) {
      const apiResponse = err.response.data as ApiResponse;
      if (apiResponse && apiResponse.feedback) {
        if (
          apiResponse.feedback.tag === "wrongAuth" ||
          apiResponse.feedback.tag === "invalidRefreshToken"
        ) {
          userService.logout();
          throw err;
        }

        if (apiResponse.feedback.tag === "expiredAuth") {
          const refresh =
            forceRefresh !== null
              ? forceRefresh
              : localStorage.getItem(KEEP_SESSION_STORAGE) === "true";
          if (!refresh) {
            userService.logout();
            throw err;
          }
          try {
            await userService.refreshToken();
            window.vuplex?.postMessage({
              action: "updateAccessToken",
              success: true,
              access_token: localStorage.getItem(ACCESS_TOKEN_STORAGE),
            });
            config = {
              headers: {
                Authorization: `Bearer ${localStorage.getItem(
                  ACCESS_TOKEN_STORAGE
                )}`,
              },
              timeout: REQUEST_TIMEOUT,
              data: body,
            };
            const response = await axios.delete(API_URL + action, config);
            const data = response.data as ApiResponse;
            return data;
          } catch (err) {
            userService.logout();
            throw err;
          }
        }
      }
    }
    throw err;
  }
};

export const apiService = {
  getRequest,
  postRequest,
  deleteRequest,
};
