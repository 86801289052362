import BlocklyPY from "blockly/python";

BlocklyPY["colorsensor_instantiation_block"] = function (block) {
  var name = BlocklyPY.valueToCode(block, "name", BlocklyPY.ORDER_ATOMIC);
  var uid = BlocklyPY.valueToCode(block, "uid", BlocklyPY.ORDER_ATOMIC);
  var code = `ColorSensor(${name},${uid})`;
  return [code, BlocklyPY.ORDER_NONE];
};

BlocklyPY["colorsensor_getcolor_block"] = function (block) {
  var code = `getColor()`;
  return [code, BlocklyPY.ORDER_NONE];
};

BlocklyPY["colorsensor_gethexcolor_block"] = function (block) {
  var code = `getHexColor()`;
  return [code, BlocklyPY.ORDER_NONE];
};

BlocklyPY["colorsensor_getgrayscalecolor_block"] = function (block) {
  var code = `getGrayscaleColor()`;
  return [code, BlocklyPY.ORDER_NONE];
};