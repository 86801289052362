import { TRANSLATIONS } from "../translations_template";

const BLOCKLY_TRANSLATIONS_ES = {
  PLACEHOLDER_LOGIC: "Lógica",
  PLACEHOLDER_LOOPS: "Lazos",
  PLACEHOLDER_MATH: "Matemática",
  PLACEHOLDER_TEXT: "Texto",
  PLACEHOLDER_LISTS: "Listas",
  PLACEHOLDER_COLOUR: "Color",
  PLACEHOLDER_RESOURCES: "Recursos",
  PLACEHOLDER_BASIC: "Básico",
  PLACEHOLDER_ADVANCED: "Avanzado",
  PLACEHOLDER_TIME: "Tiempo",
  PLACEHOLDER_SCORE: "Puntuación",
  PLACEHOLDER_CONTROLS: "Control",
  PLACEHOLDER_SIMULATION: "Simulación",
  PLACEHOLDER_ROBOTS: "Robots",
  PLACEHOLDER_NON_MOBILE: "Non-muebles",
  PLACEHOLDER_MOBILE: "Muebles",
  PLACEHOLDER_SENSORS: "Sensores",
  PLACEHOLDER_SUPERVISOR: "Supervisório",
  PLACEHOLDER_COLORSENSOR: "Sensor de Color",
  PLACEHOLDER_RANGESENSOR: "Sensor de Proximidad",
  PLACEHOLDER_PHOTOELECTRICSENSOR: "Sensor Fotoeléctrico",
  PLACEHOLDER_VARIABLES: "Variables",
  PLACEHOLDER_FUNCTIONS: "Funciones",
  PLACEHOLDER_ACTUATORS: "Actuadores",
  PLACEHOLDER_CONVEYOR: "Cinta Transportadora",
  PLACEHOLDER_SPAWNER: "Dispensador de Discos",
  PLACEHOLDER_STOPWATCH: "Cronógrafo",
  PLACEHOLDER_ACTIONS: "Acciones",
  PLACEHOLDER_SENSING: "Sensoriamento",
  PLACEHOLDER_PARAMETERS: "Parámetros",

  PLACEHOLDER_NAME: "nombre",
  PLACEHOLDER_TYPE: "tipo",
  PLACEHOLDER_UID: "UID",
  PLACEHOLDER_INDEX: "índice",
  PLACEHOLDER_IN: "en",
  PLACEHOLDER_TO: "para",
  PLACEHOLDER_JOINT: "junta",
  PLACEHOLDER_ANGLE: "ángulo",
  PLACEHOLDER_ANGLES: "ángulos",
  PLACEHOLDER_UNIT: "unidad",
  PLACEHOLDER_STATE: "estado",
  PLACEHOLDER_LEFTWHEEL: "rueda izquierda",
  PLACEHOLDER_RIGHTWHEEL: "rueda derecha",
  PLACEHOLDER_CENTER: "centro",
  PLACEHOLDER_AXIS: "eje",
  PLACEHOLDER_VELOCITY: "velocidad",
  PLACEHOLDER_DISTANCE: "distancia",
  PLACEHOLDER_ON: "ON",
  PLACEHOLDER_OFF: "OFF",
  PLACEHOLDER_REVERSE: "RETROCESO",
  PLACEHOLDER_FUNCTION: "función",
  PLACEHOLDER_INPUT: "entrada",
  PLACEHOLDER_OUTPUT: "salida",
  PLACEHOLDER_VALUE: "valor",
  PLACEHOLDER_START: "COMIENZO",
  PLACEHOLDER_PAUSE: "PAUSA",
  PLACEHOLDER_STOP: "PARADA",
  PLACEHOLDER_RESET: "REINICIAR",
  PLACEHOLDER_DEGREES: "grados",
  PLACEHOLDER_RADIANS: "radianes",
  PLACEHOLDER_POSITION: "posición",
  PLACEHOLDER_RIGHT: "derecha",
  PLACEHOLDER_LEFT: "izquierda",
  PLACEHOLDER_ACTOR: "actor",

  TEXT_INPUT_OUTPUT: "Entrada/Salida",

  MATH_FUNCS: "Funciones matemáticas",
  MATH_CONVERT: "{{original}} para {{target}}",
  MATH_DEG_TO_RAD_TOOLTIP: "Convierte un ángulo de grados para radianos.",
  MATH_RAD_TO_DEG_TOOLTIP: "Convierte un ángulo de radianos para grados.",
  MATH_QUATERNION_TO_EULER: "cuaternio para Euler",
  MATH_QUATERNION_TO_EULER_TOOLTIP: "Convierta un cuaternio para un vector de ángulos de Euler.",
  MATH_EULER_TO_QUATERNION: "Euler para cuaternio",
  MATH_EULER_TO_QUATERNION_TOOLTIP: "Convierte un vector de ángulos de Euler para quatérnio.",
  MATH_CONVERT_LENGTH_METERS_TOOLTIP: "Convierte una longitud en metros para otra unidad.",
  MATH_CONVERT_LENGTH_UNIT_TOOLTIP: "Convierte um longitud en una unidad dada para otra.",
  MATH_CONVERT_TIME_SECONDS_TOOLTIP: "Convierte tempo em segundos para outra unidade.",
  MATH_CONVERT_TIME_UNIT_TOOLTIP: "Convierte tiempo en una unidad dada para otra.",
  MATH_CONVERT_VELOCITY_METERSPERSEC_TOOLTIP: "Convierte velocidad en m/s para otra unidad.",
  MATH_CONVERT_VELOCITY_UNIT_TOOLTIP: "Convierte velocidad en una unidad dada para otra.",

  RESOURCES_LINEBREAK: "salto de línea",
  RESOURCES_LINEBREAK_TOOLTIP: "Hacer un salto de línea",

  BASIC_DEFINE_ACTOR: "definir actor",
  BASIC_DEFINE_ACTOR_TOOLTIP: "Recibe como parámetro una instancia de actor e con ella define una variable de actor.",
  BASIC_GET_ACTOR: "obtener esto actor",
  BASIC_GET_ACTOR_TOOLTIP: "Retorna este ator.",

  ADVANCED_SET_INPUT: "definir entrada",
  ADVANCED_SET_INPUT_TOOLTIP: "Define un señal de entrada",
  ADVANCED_GET_INPUT: "obtener entrada",
  ADVANCED_GET_INPUT_TOOLTIP: "Retorna un señal de entrada",
  ADVANCED_SET_OUTPUT: "definir salida",
  ADVANCED_SET_OUTPUT_TOOLTIP: "Define un señal de salida",
  ADVANCED_GET_INTERFACE: "obtener interface",
  ADVANCED_GET_INTERFACE_TOOLTIP: "Retorna una interface a partir do su nombre.",
  ADVANCED_CREATE_GLOBAL_VAR: "crear variables globales",
  ADVANCED_CREATE_GLOBAL_VAR_TOOLTIP: "Crea una variable global en el contexto de simulación.",
  ADVANCED_CREATE_GLOBAL_OUT_INTERFACE: "crear interfaz global",
  ADVANCED_CREATE_GLOBAL_OUT_INTERFACE_TOOLTIP: "Crea una interfaz de salida global en el contexto de la simulación.",
  ADVANCED_GET_GLOBAL_VALUE: "obtener valor global",
  ADVANCED_GET_GLOBAL_VALUE_TOOLTIP: "Devuelve el valor de la variable global seleccionada.",
  ADVANCED_SET_GLOBAL_VALUE: "definir global value",
  ADVANCED_SET_GLOBAL_VALUE_TOOLTIP: "Crea el valor de la variable global seleccionada.",
  ADVANCED_GET_GLOBAL_INTERFACE_VALUE: "obtener global interfaz",
  ADVANCED_GET_GLOBAL_INTERFACE_VALUE_TOOLTIP: "Devuelve el valor de la interfaz global seleccionada.",
  ADVANCED_SET_GLOBAL_INTERFACE: "definir global interfaz",
  ADVANCED_SET_GLOBAL_INTERFACE_TOOLTIP: "Crea el valor de la interfaz global seleccionada.",

  TIME_WAIT: "esperar",
  TIME_WAIT_TOOLTIP: "Para la simulación por el tiempo especificado.",
  TIME_SLEEP: "esperar",
  TIME_SLEEP_TOOLTIP: "Para la execución de código por el tiempo especificado.",

  SIMULATION_START: "iniciar simulación",
  SIMULATION_PAUSE: "simulación de pausa",
  SIMULATION_STOP: "detener la simulación",
  SIMULATION_GET_STATE: "obtener estado de simulación",
  SIMULATION_GET_STATE_TOOLTIP: "Devuelve el estado de la simulación (0: detenido, 1: en ejecución, 2: pausa).",
  SIMULATION_GET_TIME: "obtener tiempo de simulación",
  SIMULATION_GET_TIME_TOOLTIP: "Devuelve el tiempo de simulación en segundos, medido desde un sistema interno.",
  SIMULATION_GET_REAL_TIME: "obtener tiempo real",
  SIMULATION_GET_REAL_TIME_TOOLTIP: "Devuelve el tiempo real empleado por la simulación, medido desde el temporizador del sistema operativo. Solo funciona con la bandera en tiempo real activada.",

  SIMULATION_TIME_LOOP: "Bucle de tiempo de",
  SIMULATION_TIME_LOOP_TOOLTIP: "Ejecuta un bucle durante un cierto tiempo de simulación.",

  SIMULATION_CONFIG_SCORE_SYSTEM: "Inicializar sistema de notas",
  SIMULATION_CONFIG_SCORE_SYSTEM_TOOLTIP: "Inicializa y configura el sistema de notación de código.",
  SIMULATION_SET_SCORE: "establecer grado para",
  SIMULATION_SET_SCORE_TOOLTIP: "Asigne valor al grado de simulación.",
  SIMULATION_GET_SCORE: "obtener grado",
  SIMULATION_GET_SCORE_TOOLTIP: "Devuelve el grado de simulación actual.",

  ROBOT2WHEELS_SETWHEELSVELOCITIES: "definir velocidades de las ruedas",
  ROBOT2WHEELS_SETWHEELSVELOCITIES_TOOLTIP: "Define las velocidades de las dos rodas a partir de un vector [izquierda, derecha].",
  ROBOT2WHEELS_SETLEFTWHEELVELOCITY: "definir velocidad de la rueda izquierda",
  ROBOT2WHEELS_SETLEFTWHEELVELOCITY_TOOLTIP: "Define a velocidad de la rueda izquierda.",
  ROBOT2WHEELS_SETRIGHTWHEELVELOCITY: "definir velocidad de la rueda derecha",
  ROBOT2WHEELS_SETRIGHTWHEELVELOCITY_TOOLTIP: "Define la velocidad de la rueda derecha.",
  ROBOT2WHEELS_GETWHEELSVELOCITIES: "obtener velocidades de las rodas",
  ROBOT2WHEELS_GETWHEELSVELOCITIES_TOOLTIP: "Retorna las velocidades de las ruedas a partir de um vector [izquierda, derecha].",
  ROBOT2WHEELS_GETLEFTWHEELVELOCITY: "obtener velocidad da roda esquerda",
  ROBOT2WHEELS_GETLEFTWHEELVELOCITY_TOOLTIP: "Retorna la velocidad de la rueda izquierda.",
  ROBOT2WHEELS_GETRIGHTWHEELVELOCITY: "obtener velocidad de la rueda derecha",
  ROBOT2WHEELS_GETRIGHTWHEELVELOCITY_TOOLTIP: "Retorna la velocidad de la rueda derecha.",
  ROBOT2WHEELS_ROTATE: "rotar",
  ROBOT2WHEELS_ROTATE_TOOLTIP: "Rota el robot hasta un ángulo dado  alrededor de um eje dado (rueda izquierda, rueda derecha ó centro del robot) a una velocidad dada.",
  ROBOT2WHEELS_MOVE_FORWARD: "se mueve hacia delante",
  ROBOT2WHEELS_MOVE_FORWARD_TOOLTIP: "Mueve el robot hacia delante por una distáncia dada a una dada velocidad.",

  LINEFOLLOWERBOT_INSTANTIATE_TOOLTIP: "Instancia um robot LineFollowerBot.",
  LINEFOLLOWERBOT_GET_COLOR_TOOLTIP: "Devuelve la lectura de color (de un vector [R,G,B]) de un sensor de color seleccionado.",
  LINEFOLLOWERBOT_GET_COLORS: "obtener colores",
  LINEFOLLOWERBOT_GET_COLORS_TOOLTIP: "Devuelve la lectura de color (de un vector [R,G,B]) de los sensores de color del robot en orden [izquierda, centro, derecha]",
  LINEFOLLOWERBOT_GET_RANGE_TOOLTIP: 'Devuelve la lectura del sensor de proximidad seleccionado.',
  LINEFOLLOWERBOT_GET_RANGES: 'obtener distancias',
  LINEFOLLOWERBOT_GET_RANGES_TOOLTIP: "Devuelve la lectura de los sensores de proximidad en orden [izquierda, centro, derecha].",

  NTUBOT_INSTANTIATE_TOOLTIP: "Instancia um robot NTUbot.",

  PIONEER_INSTANTIATE_TOOLTIP: "Instancia um robot Pioneer.",
  PIONEER_GET_RANGES: "obtener las distancias detectadas",
  PIONEER_GET_RANGES_TOOLTIP: "Retorna la lectura  de los sensores de proximidad del robot Pioneer.",

  DOBOT_INSTANTIATE_TOOLTIP: "Instancia un robot Dobot Magician",
  DOBOT_SET_JOINT_ANGLE: "definir ángulo de junta",
  DOBOT_SET_JOINT_ANGLE_TOOLTIP: "Define el ángulo de una junta dada del robot.",
  DOBOT_SET_JOINT_ANGLES: "definir ángulos de junta",
  DOBOT_SET_JOINT_ANGLES_TOOLTIP: "Define los ángulos de todas las juntas del robot.",
  DOBOT_GET_JOINT_ANGLE: "obtener ángulo de junta",
  DOBOT_GET_JOINT_ANGLE_TOOLTIP: "Retorna el ángulo de una junta dada do robô.",
  DOBOT_GET_JOINT_ANGLES: "obtener ángulos de junta",
  DOBOT_GET_JOINT_ANGLES_TOOLTIP: "Retorna los ángulos de todas las juntas del robot en la forma de um vector.",
  DOBOT_SET_SUCTION_CUP_STATE: "definir estado de la copa de succión",
  DOBOT_SET_SUCTION_CUP_STATE_TOOLTIP: "Define si la copa de succión del robot está activada ó no.",

  RANGESENSOR_INSTANTIATE_TOOLTIP: "Instancia um sensor de proximidad.",
  RANGESENSOR_GET_RANGE: "obtener la distancia detectada",
  RANGESENSOR_GET_RANGE_TOOLTIP: "Retorna la lectura del sensor de proximidad.",

  PHOTOELECTRICSENSOR_INSTANTIATE_TOOLTIP: "Instancia um sensor fotoeléctrico.",
  PHOTOELECTRICSENSOR_GET_RANGE: "obtener la distancia detectada",
  PHOTOELECTRICSENSOR_GET_RANGE_TOOLTIP: "Retorna la lectura del sensor fotoeléctrico.",
  PHOTOELECTRICSENSOR_HAS_DETECTED: "ha detectado?",
  PHOTOELECTRICSENSOR_HAS_DETECTED_TOOLTIP: "Compruebe si detectó algún objeto dentro de su volumen de detección.",

  COLORSENSOR_INSTANTIATE_TOOLTIP: "Instancia un sensor de color.",
  COLORSENSOR_GET_COLOR: "obtener color (RGB)",
  COLORSENSOR_GET_HEX_COLOR: "obtener color (HEX)",
  COLORSENSOR_GET_COLOR_TOOLTIP: "Retorna la lectura de color (un vector [R,G,B]) de un sensor de color.",
  COLORSENSOR_GET_HEX_COLOR_TOOLTIP: "Retorna la lectura de color (un número hexadecimal) de un sensor de color.",
  COLORSENSOR_GET_GRAYSCALE_COLOR: "obtener el valor de color promedio",
  COLORSENSOR_GET_GRAYSCALE_COLOR_TOOLTIP: "Devuelve la lectura de color en escala de grises de un valor de sensor de color que oscila entre 0 y 255.",

  CONVEYOR_INSTANTIATE_TOOLTIP: "Instancia una cinta transportadora.",
  CONVEYOR_SET_STATE: "definir estado de la cinta transportadora",
  CONVEYOR_SET_STATE_TOOLTIP: "Define si la cinta está encendida ó apagada.",

  SPAWNER_INSTANTIATE_TOOLTIP: "Instancia un dispensador de discos.",
  SPAWNER_SET_STATE: "definir estado del dispensador",
  SPAWNER_SET_STATE_TOOLTIP: "Define si el dispensador de discos está encendido ó apagado.",

  STOPWATCH_INSTANTIATE_TOOLTIP: "Instancia un cronómetro",
  STOPWATCH_SET_STATE: "definir estado del cronómetro",
  STOPWATCH_SET_STATE_TOOLTIP: "Establece los estados del cronómetro.",
  STOPWATCH_GET_TIME: "consigue tiempo",
  STOPWATCH_GET_TIME_TOOLTIP: "Devuelve el tiempo de simulación en segundos.",
  STOPWATCH_GET_TIME_FORMAT: "obtener cadena de tiempo",
  STOPWATCH_GET_TIME_FORMAT_TOOLTIP: "Devuelve el tiempo de simulación en formato HH:MM:SS.sss.",

  SUPERVISOR_INSTANTIATE_TOOLTIP: "Instancia um supervisório",
  SUPERVISOR_GET_ACTOR: "obtener ator",
  SUPERVISOR_GET_ACTOR_TOOLTIP: "Retorna un actor por su nombre",
  SUPERVISOR_CONNECT_INTERFACES: "conectar interfazes",
  SUPERVISOR_CONNECT_INTERFACES_TOOLTIP: "Conecta una interfaz de entrada a una interfaz de salida.",

  TEXT_ACTOR_TOOLTIP: "Devuelve el nombre de un actor en la escena",
}

export const TRANSLATIONS_ES: TRANSLATIONS = {

  commons: {
    Language: "Lenguaje",
    submission: "sumisión",
    copy: "copia",
    event: "evento",
    member: "miembro",
    members: "miembro(s)",
    group: "equipo",
    Leader: "Líder",
    Optional: "Opcional",
    defaultName_male: "Mi {{projectType}}",
    defaultName_female: "Mi {{projectType}}",
    Loading: "Cargando ...",
    Unnamed: "Sin nombre",
    Untitled: "Sin título",
    Status: "Status",
    Information: "Información",
    image: "imagen",
    userData: {
      username: "Usuário",
      email: "correo eletrónico",
      Email: "Correo eletrónico",
      fullname: "Nombre completo",
      phone: "Teléfono",
      zipcode: "ZIP",
    },
    userAddress: {
      zipcode: "ZIP",
      address1: "Dirección",
      address2: "Complemento",
      city: "Ciudad",
      state: "Estado",
      country: "País"
    },
    institution: {
      institution: "Institución",
      unit: "Unidad",
      taxIdNumber: "CNPJ",
      segment: "Segment"
    },
    msgs: {
      modal: {
        deleteProjectWarning: "Al completar esta acción, el proyecto será eliminado y no podrá ser recuperado. ¿Estás seguro de que quieres continuar?",
        deleteEventWarning: "Al completar esta acción, el evento será eliminado y nadie más podrá acesarlo. ¿Estás seguro de que quieres continuar?",
        deleteSubmissionWarning: "Al completar esta acción, la sumisión será eliminada y no será considerada para evaluación. ¿Estás seguro de que quieres continuar?",
        deleteTemplateWarning: "Al completar esta acción, el template será eliminado y no aparecerá más para los participantes deste evento. ¿Estás seguro de que quieres continuar?",
        archiveEventWarning: "Mientras el evento esté archivado, él no aparecerá en tu lista de eventos. ¿Estás seguro de que quieres continuar?",
        unarchiveEventWarning: "Al desarchivar el evento, el volverá à tu lista de eventos. ¿Estás seguro de que quieres continuar?",
        sendTemplateWarning: "Al completar esta acción, la plantilla estará disponible para los participantes de este evento para que ellos puedam crear experimentos a partir de él. ¿Estás seguro de que quieres continuar?",
        submitExperimentWarning: "Al completar esta acción, el experimento será sumetido para evaluación. ¿Estás seguro de que quieres continuar?"
      },
      openedUnity: "¡Simulación cargada!",
      openingUnity: "Cargando simulación ...",
      emptyList: "Ningún ítem para exibir",
      create: "Crear {{target}}",
      created_male: "¡{{target}} creado con éxito!",
      created_female: "¡{{target}} criada con éxito!",
      creating: "Creando {{target}} ...",
      edit: "Editar {{target}}",
      editing: "Editando {{target}} ...",
      edited_male: "¡{{target}} editado!",
      edited_female: "¡{{target}} editada!",
      delete: "Eliminar {{target}}",
      deleted_male: "¡{{target}} eliminado con éxito!",
      deleted_female: "¡{{target}} eliminada con éxito!",
      deleting: "Eliminando {{target}} ...",
      update: "Actualizar {{project}}",
      updating: "Actualizando {{project}} ...",
      updated_male: "¡{{project}} actualizado con éxito!",
      updated_female: "¡{{project}} actualizada con éxito!",
      copy: "Copiar {{project}}",
      copying: "Copiando {{project}} ...",
      copied: "¡{{project}} copiado con éxito!",
      archive: "Archivar {{}}",
      archiving: "Archivando {{}} ...",
      archived: "¡{{}} archivado!",
      unarchive: "Desarchivar {{}}",
      unarchiving: "Desarchivando {{}} ...",
      unarchived: "¡{{}} desarchivado!",
      share: "Compartir {{item}}",
      sharing: "Compartindo {{item}} ...",
      shared: "{{item}} compartido!",
      searching: "Buscando {{}} ...",
      found: "¡{{}} encontrado!",
      noTemplatesToShow: "No hay plantillas para mostrar"
    },
    actions: {
      backToHome: "Volver al início",
      confirm: "Confirmar",
      cancel: "Cancelar",
      open: "Abrir",
      close: "Cerrar",
      export: "Exportar",
      load: "Cargar",
      loading: "Cargando",
      loaded: "Cargado",
      create: "Crear",
      creating: "Creando",
      created: "Creado",
      editing: "Editando",
      edit: "Editar",
      edited: "Editado",
      updating: "Atualizando",
      update: "Atualizar",
      updated: "Atualizado",
      delete: "Eliminar",
      subscribe: "Inscribirse",
      publish: "Publicar",
      finish: "Cerrar",
      next: "Próximo",
      back: "Volver",
      send: "Enviar",
      resend: "Reenviar",
      sendingEmail: "Enviando correo electrónico",
      emailSent: "Correo electrónico enviado",
      copied: "Copiado",
      search: "Buscar"
    },
    password: {
      placeholder: "contraseña",
      placeholder_uppercase: "Contraseña",
      confirm: "Confirmar contraseña",
      new: "Nueva contraseña",
    },
    request: {
      placeholder: "requisición",
      successful: "¡{{request}} se realizó con éxito!",
      failed: "¡{{request}} no puede ser realizado!",
      pending: "Realizando {{request}} ...",
    },
    projectInfo: {
      projectName: "Nome del proyecto",
      description: "Descripción",
      creationDate: "Fecha de creación",
      lastModified: "Última modificación",
      models: "Modelos",
    },
    projectTypes: {
      project: "proyecto",
      classroom: "sala de clase",
      tournament: "torneo",
      challenge: "desafío",
      tutorial: "tutorial",
      template: "plantilla",
    },
    projectRoles: {
      project: "proyecto",
      experiment: "experimento",
      draft: "borrador"
    },
    LicenseType: {
      Root: "Root",
      Admin: "Administrador",
      Manager: "Gerente",
      Professor: "Profesor",
      Organizer: "Organizador",
      Instructor: "Instructor",
      Student: "Estudiante",
      Member: "Miembro",
      Individual: "Individual",
      Guest: "Visitante"
    },
    eventStatus: {
      UnderConstruction: "En construcción",
      WaitingApproval: "En espera de aprovación",
      Approved: "Aprovado",
      Published: "Publicado",
      InProgress: "En curso",
      Closed: "Cerrado",
      WaitingScores: "En espera de evaluación",
      Canceled: "Cancelado"
    },
    eventTypes: {
      classroom: "sala de clase",
      tournament: "torneo",
      challenge: "desafío",
    },
    resourceTypes: {
      DobotV3: "Dobot",
      NTUbot: "NTUbot",
      PioneerV2: "Pioneer",
      ConveyorM: "Cinta Transportadora",
      Spawner2: "Dispensador",
      Stopwatch: "Cronógrafo",
      colorSensor: "Sensor de Color",
      rangeSensor: "Sensor de Proximidad",
      GreenBox: "Caja Verde",
      RedBox: "Caja Roja",
      Plant: "Planta",
      Chair: "Silla",
      Table: "Mesa",
      Path: "Trayecto",
      Path2: "Trayecto 2",
      Sphere: "Esfera",
      Cuboid: "Cuboide",
      Cylinder: "Cilindro",
      Supervisor: "Supervisório",
      photoelectricSensor: "Sensor Fotoeléctrico",
      LineFollowerBot: "Seguidor de Línea",
      Border: "Borda",
      Maze: "Labirinto",
      cameraSensor: "Câmera",
      Plane: "Plano",
      Disc: "Disco",
      Cone: "Cone",
      Capsule: "Capsula",
      MediumConveyorBelt: "Cinta Transportadora",
    },
    modelTypes: {
      Actuator: "Actuadores",
      Sensor: "Sensores",
      Object: "Objectos",
      Primitive: "Primitivas",
      Robot: "Robots"
    },
    modelMotionProps: {
      static: "Estáticos",
      dynamic: "Dinámicos"
    },
    time: {
      secondsAgo: "hace {{seconds}} segundos",
      minutesAgo: "hace {{minutes}} minutos",
      hoursAgo: "hace {{hours}} horas",
      daysAgo: "hace {{days}} días",
      monthsAgo: "hace {{months}} meses",
      yearsAgo: "hace {{years}} años",
    },
    timeUnit: {
      days: "día(s)",
      months: "mes(es)",
      years: "año(s)"
    },
    paymentPeriod: {
      daily: "día",
      monthly: "mes",
      yearly: "año",
    },
    form: {
      name: "nombre",
      description: "descripción",
      initials: "iniciales",
      code: "código",
      type: "tipo"
    },
    unit: {
      professors: "professors",
      organizers: "organizers",
      instructors: "instructors",
      type: {
        head: "principal",
        branch: "subsidiaria",
        franchise: "franquicia",
        department: "departamento",
        campus: "campus"
      }
    },
    units: "unidades"
  },

  components: {
    acceptProjectCopy: {
      header: "Aceptar una cópia de proyecto?",
      instructions: "{{user}} quer te enviar una cópia del proyecto {{projectName}}. Deseas aceptar?"
    },
    blockly: {
      blocklyScreen: {
        loadingCode: "Cargando código del modelo",
        failedToLoadCode: "No se pudo cargar el código de la plantilla. ¡Inténtelo de nuevo más tarde!",
        saveCode: "Salvar código",
        resetCode: "Restabelecer código",
        seeCode: "Ver código",
        downloadScreenshot: "Descargar captura de pantalla",
        resetModal: {
          message: "Al realizar esta acción, todo el código se perderá y no podrá recuperarse. ¿Estás seguro de que quieres continuar?"
        }
      },
    },
    // changePassword: {
    //   header: "Cambiar contraseña",
    //   pending: "Cambiando contraseña ...",
    //   successful: "¡Contraseña cambiada con éxito!",
    //   failed: "¡Cambio de contraseña falló!"
    // },
    commons: {
      uploadFile: {
        removeFile: "Eliminar archivo",
        sendFile: "Enviar archivo del proyecto"
      },
      uploadImage: {
        image: "imagen",
        tooltip: "Enviar imagen",
        successful: "¡Imagen cargada con éxito!",
        failed: "¡Imagen no puede ser cargada!",
        pending: "La imagen está siendo cargada ...",
        sendNew: "Enviar nueva foto de perfil",
        remove: "Eliminar foto de perfil"
      },
      userSelection: {
        searchUsersTitle: "Seleccionar usuarios",
        selectedUsersTitle: "Usuarios seleccionados",
        usernamePlaceholder: "Nombre de usuario o correo electrónico"
      }
    },
    credits: {
      header: "Créditos"
    },
    events: {
      actions: {
        updateEvent: "Actualizar evento",
        updatingEvent: "Actualizando evento ...",
        updatedEvent: "¡Evento actualizado con éxito!",
        createEvent: "Crear evento",
        copyEvent: "Copiar evento",
        creatingEvent: "Creando evento ...",
        createdEvent: "¡Evento creado con éxito!",
        classroom: {
          updateEvent: "Actualizar sala de clase",
          updatingEvent: "Actualizando sala de clase ...",
          updatedEvent: "Sala de clase actualizado con éxito!",
          createEvent: "Crear sala de clase",
          copyEvent: "Copiar sala de clase",
          creatingEvent: "Creando sala de clase ...",
          createdEvent: "sala de clase creada con éxito!",
        },
        tournament: {
          updateEvent: "Actualizar torneo",
          updatingEvent: "Actualizando torneo ...",
          updatedEvent: "¡Torneo actualizado con éxito!",
          createEvent: "Crear torneo",
          copyEvent: "Copiar torneo",
          creatingEvent: "Creando torneo ...",
          createdEvent: "¡Torneo creado con éxito!",
        }
      },
      Event: {
        commons: {
          registrationDates: {
            header: "Fechas de registro",
            start: "Fecha inicial de registro",
            end: "Fecha final de registro",
          },
          submissionDates: {
            header: "Fechas de envío",
            start: "Fecha inicial de envío",
            end: "Fecha final de envío",
          },
          eventDates: {
            start: "Inicio del evento",
            end: "Fecha límite del evento"
          },
          classroomDates: {
            header: "Fechas",
            release: "Fecha de liberación",
            end: "Fecha de cierre"
          }
        },
        eventForm: {
          tournamentName: "Nombre del torneo",
          classroomName: "Nombre de la sala de clase",
          tournamentType: "Tipo de torneo",
          eventVisibility: {
            open: "Abierto",
            closed: "Cerrado"
          },
          shortDescription: "Descripción corta",
          description: "Descripción",

          teamInfo: {
            header: "Información de equipo",
            zeroIfUnlimitedValue: "Ponga cero si el valor es ilimitado.",
            maxTeamsAllowed: "Maximo de equipos permitidos",
            maxUsersPerTeam: "Maximo de usuarios por equipo",
            maxSubmissions: "Maximo de envíos permitidos"
          },
          classroomSettings: {
            header: "Configuraciones de sala de clase",
            maxStudentsAllowed: "Máximo de alumnos permitidos"
          },
          payment: {
            header: "Pago",
            value: "Valor",
            currency: "Moneda",
            paymentType: "Tipo de pago",
            paymentTypes: {
              Team: "Equipo",
              TeamMember: "Miembro de Equipo",
              Robot: "Robot"
            },
            currencyNames: {
              real: "Real",
              dolar: "Dólar"
            },
          },
          rules: {
            placeholder: "Reglas",
            viewMarkdownTip: "Visualizar reglas en Markdown",
            viewFormatedTip: "Visualizar reglas formateadas"
          },
          invites: {
            header: "Huéspedes"
          }
        },
        eventFormSchema: {
          beforeRegistrationStart: "Debe ser anterior a la fecha de inicio de registro",
          beforeRegistrationEnd: "Debe ser anterior a la fecha de cierre de registro",
          beforeSubmissionsStart: "Debe ser anterior a la fecha de inicio de envío",
          beforeSubmissionsEnd: "Debe ser anterior a la fecha de cierre de envío",
          beforeRelease: "Debe ser anterior a la fecha de liberación",
          beforeEnd: "Debe ser anterior a la fecha de cierre",
          afterRegistrationStart: "Debe ser posterior a la fecha de inicio de registro",
          afterRegistrationEnd: "Debe ser posterior a la fecha de cierre de registro",
          afterSubmissionsStart: "Debe ser posterior a la fecha de inicio de envío",
          afterRelease: "Debe ser posterior a la fecha de liberación",
          afterEnd: "Debe ser posterior a la fecha de cierre",
          afterToday: "Debe ser posterior ao início do dia de hoje",
          atLeastOneMember: "Cada equipo debe tener al menos un miembro",
          charsMissing: "¡Faltan {{number}} caracteres!",
          charsInExcess: "¡Excedió en {{number}} caracteres!"
        },
        sharedInstitutions: {
          title: "Compartir evento con instituciones",
          description: "Seleccione las instituciones que desea compartir su evento.",
          searchTitle: "Seleccione instituciones",
          selectedTitle: "Instituciones seleccionadas",
          searchPlaceholder: "Nombre de la institución",
          updating: "Compartiendo evento con las instituciones seleccionadas...",
          complete: "Evento compartido con las instituciones seleccionadas!"
        }
      },
      group: {
        groupTab: {
          editGroup: "Editar equipo",
          membersHeader: "Miembros",
          noUsersInGroup: "Nigún usuario en el grupo",
          unsubscribeGroup: "Desuscribir equipo",
          unsubscribingGroup: "Desuscribindo equipo ...",
          unsubscribedGroup: "¡Equipo desuscrito con éxito!",
          quitGroup: "Dejar equipo",
          quitingGroup: "Dejando equipo ...",
          quited: "¡Has dejado el equipo!",
          addMember: "Agregar miembro",
          addingMember: "Agregando miembro ...",
          addedMember: "¡Miembro agregado con éxito!",
          removeMember: "Eliminar miembro",
          removingMember: "Eliminando miembro ...",
          removedMember: "¡Miembro removido con éxito!",
          unsubscribeGroupWarning: "Al finalizar esta acción, tu equipo será desuscrito, todas las submissiones y proyectos associados a él serán eliminados y todos sus miembros perderán el acceso al evento.",
          unsubscribeMemberWarning: "Al finalizar esta acción, el usuario {{member}} será eliminado del equipo y perderá el acceso al evento.",
          unsubscribeSelfWarning: "Al finalizar esta acción, tu sairás del equipo y perderá el acceso al evento.",
          areYouSure: "¿Estás seguro de que quieres continuar?"
        },
        updateGroupForm: {
          groupName: "Nombre del equipo",
        },
      },
      groupInfoTab: {
        noName: "Sin nombre",
        showSubmissions: "Exibir envíos del equipo",
        seeSubmissions: "Ver envíos",
        noGroupsToShow: "No hay equipos para mostrar",
        noMembersToShow: "No hay miembros para mostrar",
        creationDate: "Fecha de creación:",
        Location: "Locación",
        membersHeader: "Miembros",
        submissionsHeader: "Envíos"
      },
      submission: {
        rankingTab: {
          tableHeaders: {
            Position: "Posición",
            GroupName: "Nombre del equipo",
            Evaluation: "Evaluación",
            SubmissionDate: "Fecha de submisión"
          },
          noRankingToShow: "No hay ranking para mostrar"
        },
        submissionTab: {
          submissionStatus: {
            Review: "En revisión",
            NotGraded: "Esperando Evaluación",
            Graded: "Avaliado",
            Submitted: "Enviado"
          },
          startingAutoEvaluation: "Iniciar evaluación automática",
          startedAutoEvaluation: "¡Evaluación automática iniciada!",
          tableHeaders: {
            ID: "ID de sumisión",
            projectName: "Nombre del proyecto",
            submittedBy: "Enviado por",
            submissionDate: "Data de envío",
            status: "Status",
            evaluation: "Evaluación",
            actions: "Acciones"
          },
          seeSubmission: "Visualizar sumisión",
          revaluateSubmission: "Reavaliar sumisión",
          noSubmissionToShow: "No hay envíos para mostrar"
        },
      },
      templates: {
        templatesTab: {
          tableHeaders: {
            ID: "ID de la plantilla",
            templateName: "Nombre de la plantilla",
            submissionDate: "Fecha de envío",
            actions: "Acciones"
          },
          noTemplatesToShow: "No hay plantillas para mostrar"
        }
      },
      eventInfo: {
        subscribingEvent: "Inscribindose en el evento ...",
        subscribedEvent: "¡Inscrito en el evento con éxito!",
        publishingEvent: "Publicando evento ...",
        publishedEvent: "Evento publicado!",
        finishingEvent: "Cerrando evento ...",
        finishedEvent: "Evento cerrado!",
        acceptRules_male: "Yo leí y concuerdo con las reglas del {{event}}.",
        acceptLeaderRole_male: "Yo accepto crear y ser el líder de un nuevo equipo del {{event}}.",
        acceptRules_female: "Yo leí y concuerdo con las reglas del {{event}}.",
        acceptLeaderRole_female: "Yo accepto crear y ser el líder de un nuevo equipo del {{event}}.",
        subscriptionStatus: {
          subscribed: "Inscrito",
          eventFull: "Lleno",
          outOfDeadline: "Fora del límite de inscriciones"
        },
        navBarTabs: {
          General: "General",
          Drafts: "Borradores",
          Experiments: "Experimentos",
          Templates: "Plantillas",
          MyGroup: "Mi equipo",
          Groups: "Equipos",
          Submissions: "Envíos",
          Ranking: "Classificación",
          Invites: "Invitaciones"
        }
      },
      eventList: {
        tabs: {
          MySubscriptions: "Mis inscriciones",
          Open: "Abiertos",
          Closed: "Cerrados",
          MyTournaments: "Mis torneos",
          MyClassrooms: "Salas de clase"
        },
        hideArchived: "Ocultar archivados",
        showArchived: "Ver archivados",
        archivedHeader: "Archivados"
      },
      invites: {
        editInvitedUsers: "Editar invitados",
      },
      copyEvent: {
        description: "Seleccione uno de los eventos de su unidad para hacer una copia."
      }
    },
    home: {
      homeHeader: {
        reloadPage: "Recargar página"
      },
      sidebar: {
        logout: "Salir",
        config: "Configuraciones",
        comingSoon: "En breve",
      }
    },
    myAccount: {
      header: "Mi cuenta",
      userInfo: {
        header: "Informacciones de usuario",
        description: "Administrar sus datos de usuario, como nombre, e-mail, teléfono ó código ZIP",
        updateUser: {
          pending: "Actualizando usuário...",
          successful: "Usuário actualizado!",
          failed: "Error al actualizar usuario",
        }
      },
      addresses: {
        header: "Mis direcciones",
        description: "Añadir ó eliminar direcciones",
        addAddress: {
          added: "¡Dirección añadida!",
          pending: "Añadindo dirección ..."
        },
        newAddress: "Nueva dirección"
      },
      security: {
        header: "Securidad",
        description: "Administrar sus configuraciones de securidad, como contraseñas.",
        changePassword: {
          header: "Cambiar contraseña",
          pending: "Cambiando contraseña ...",
          successful: "¡Contraseña cambiada con éxito!",
          failed: "¡Cambio de contraseña falló!"
        }
      },
      myPlans: {
        header: "Planos y licencias",
        description: "Visualizar informaciones de tu linececia y tu plan.",
        license: {
          header: "Licencia"
        },
        plan: {
          header: "Plan",
          customPlan: "Plan personalizado",
          active: "Activo",
          inactive: "Inactivo",
          features: {
            header: "Recursos",
            seats: "asiento(s)",
            licensesOf: "licencia(s) de",
            youHaveALicenseOf: "Tu posees una licencia de {{license}}"
          },
          payments: {
            header: "Pagos",
            cost: "Costo",
            expiresOn: "Expira en",
            renewedOn: "Renovado en",
            paymentMethod: "Modo de pago",
            paymentHistory: "Historial de pago",
            change: "Cambiar",
            trialPeriodWarning: "Este plan está en un periodo de testes de {{duration}} {{unit}}"
          },
          paymentHistory: {
            empty: "No hay historial para mostrar",
            table: {
              headers: {
                paymentDate: "Fecha de pago",
                paidBy: "Pagado por:",
                paymentMethod: "Modo de pago",
                value: "Precio",
                discount: "Descuento",
                total: "Total"
              }
            }
          }
        }
      }
    },
    login: {
      header: "Iniciar sesión",
      instructions: "Inicie una sesión para accender al simulador.",
      pending: "Iniciando sesión ...",
      successful: "¡Sesión iniciada con éxito!",
      failed: "¡Inicio de sesión ha fallado!",
      noAccount: "¿No tienes una cuenta?",
      remindMe: "Recuerdame en este computador",
      forgotPassword: "Olvidé mi contraseña",
      createAccount: "Crear una cuenta",
      signIn: "Iniciar sesión",
    },
    passwordRecovery: {
      OtpPanel: {
        OtpButtons: {},
        codeExpires: "Codigo expira en {{sec}} segundos",
        resendOtp: "Reenviar código",
      },
      CodeInputForm: {
        otpCheck: {
          successful: "¡Validación de código exitosa!",
          failed: "¡Validación de código ha fallado!",
          pending: "Verificando código ...",
        },
      },
      CodeInputSchema: {
        codeRequired: "El código és obligatório"
      },
      CodeInputScreen: {
        submitInstructions: "Por favor, ingressa el código enviado para tu email para el reestabelecimento de tu contraseña",
        recoveryHeader: "Recuperación de contraseña",
      },
      SendCodeForm: {
        sendCode: "Enviar código",
      },
      SendCodeSchema: {},
      SendCodeScreen: {
        sendInstructions: "Por favor, ingressa con el email registrado para que podamos enviarle las instrucciones para el reestabelecimento de tu contraseña.",
        forgotPwd: "¿Olvidó tu contraseña?",
      },
      commons: {
        otpSend: {
          successful: "¡Código enviado con éxito para tu email!",
          failed: "¡Código no puede ser enviado para tu email!",
          pending: "Enviando código ...",
        },
      }
    },
    projectManager: {},
    projectsSelection: {
      project: {
        actions: {
          create: "Crear {{project}}",
          creating: "Creando {{project}} ...",
          created: "¡{{project}} creado com sucesso!",
          edit: "Atualizar {{project}}",
          editing: "Atualizando {{project}} ...",
          edited: "¡{{project}} atualizado com sucesso!",
        },
        projectForm: {
          templates_header: "Plantillas",
          templatesInstructions: "Seleccione una plantilla para la criación del proyecto",
          templatesInstructions2: "Una plantilla permite a usuarios trabajar en una cena preconfigurada con un conjunto predefinido de recursos disponibles.",
          selectResources: "Seleccione los recursos deseados ...",
          resourcesInstructions: "Crear una cena vacía con un conjunto personalizado de recursos disponibles.",
          resourcesInstructions2: "Haga clic en el botón {{buttonLabel}} y, en el modal abierto, seleccione los modelos que deseas incluir.",
          copyInstructions: "Pegue aquí el ID del proyecto que deseas copiar:",
          howToCopyProjectById: "Como copiar proyecto por ID ?",
          creationModes: {
            Template: "Plantilla",
            Resource: "Recurso",
            Copy: "Copia"
          },
          description: "Descripción",
          projectName: "nombre del proyecto",
          projectId: "ID del proyecto",
          fileDefaultName: "1 archivo",
          addNewResources: "Añadir nuevos recursos",
          copySteps: {
            goToProjectsList: "Vaya hasta la lista de proyectos/experimentos donde está el proyecto que deseas copiar.",
            clickInfoButton: "Haga clic en el botón de información del proyecto a ser copiado.",
            clickCopy: "En el modal de información aberto, haga clic en el botón de copiar al lado del ID de proyecto.",
            pasteId: "Con el ID de projeto copiado, accessa la pantalla de criación de proyectos, en la pestaña de Copia, y pegalo en el campo indicado.",
            createProject: "Si el ID ingressado es válido, las informaciones del proyecto como nombre, descripción y thumbnail seran cargadas."
          }
        },
        resourceListItem: {
          available: "Disponibles",
          used: "Usados"
        },
        resourceSelectionModal: {
          instructions: "Dentre los modelos abajo, elija aquellos que deseas añadir al proyecto para aparezcan en la lista {{listName}}. En esta lista, seleccione la cantidad de cada modelo que debe estar disponible  y haga clic en {{button}}",
          addResources: "Añadir recursos",
          selectedResources: "Recursos seleccionados",
          exempleSearchBar: "Ej: Sensor de color",
          allCategories: "Todas las categorías",
          seeLegends: "Ver leyendas",
          modelTypesSlide: {
            header: "Iconos de tipo de modelo",
            subtitles: "Estos iconos indicam a qué categoria el modelo pertenece: roboits, actuadores (dispositivos que producem movimento), sensores (dispositivos que captam señales/estímulos), obyectos ó primitivas (sólidos geométricos no estáticos)"          },
          motionPropsSlide: {
            header: "Iconos de propriedades de movilidad",
            subtitles: "Estos iconos indicam si el obyecto en cuestión es dinámico (esto es, interactúa con la física de la simulación) ó estático"
          }
        },
        aditionalFilters: {
          motionPropsHeader: "Propriedades"
        }
      },
      projectInfoModal: {
        projectInfoModal: {
          emailNotFound: "¡Correo eletrónico no encontrado!",
          created: "creou",
          createdIn: "creou en {{}}",
          projectId: "ID del proyecto"
        }
      },
      projectListItem: {
        createdBy: "Creado por",
      },
      projectList: {
        submit: "Enviar experimento",
        submitting: "Enviando experimento ...",
        submitted: "¡Experimento enviado!",
        submitExperimentTip: "Someter experimento para evaluación",
        outOfSubmissionDeadline: "Fuera del plazo de envíos",
        sendTemplate: "Enviar plantilla",
        sendingTemplate: "Enviando plantilla ...",
        sentTemplate: "¡Plantilla enviado con éxito!",
        myProjectsHeader: "Mis proyectos",
        myGroupProjectsHeader: "Proyectos de mi equipo"
      }
    },
    registration: {
      header: "Registro",
      instructions: "Completa el registro tener accesso a la plataforma.",
      register: "Registrar",
      passwordInstructions: "Debe contener 8 caracteres, sendo al menos una letra maiúscula, una minúscula, um número e um caracter especial",
      pending: "Creando usuario ...",
      successful: "¡Usuario registrado con éxito!",
      failed: "¡Usuario no puede ser registrado!",
      selectInstitution: {
        instruction: "Seleccione su institución y complete el campo con su correo electrónico. Se le enviará un correo electrónico para completar su registro institucional.",
        instructionEmailSent: "Se ha enviado un correo electrónico de confirmación. Por favor, confirma para continuar con el registro.",
        invalidEmail: "El correo electrónico ingresado no coincide con el formato de correo electrónico de la institución seleccionada."
      }
    },
    systemDefinitions: {
      header: "Ajustes de sistema"
    },

    project: {
      header: "Proyectos"
    },
    classroom: {
      header: "Salas de clase"
    },
    tournament: {
      header: "Torneos"
    },
    challenge: {
      header: "Desafíos"
    },
    tutorial: {
      header: "Tutoriales"
    },
    template: {
      header: "Plantillas"
    },
    myInstitution: {
      header: "Mí institución",
      unitForm: {
        createHeader: "Nueva unidad",
        updateHeader: "Actualizar unidad",
      },
      organizeEventsModal: {
        tip: "Organizar materiales de la unidad",
        header: "Materiales de la unidad",
        description: "Seleccione los materiales de su institución que pueden ser utilizados por la unidad {{unit}}",
        updating: "Actualizando lista de materiales de la unidad...",
        success: "Lista de materiales de la unidad atualizada com sucesso!"
      },
      organizeUsersRolesModal: {
        tip: "Organizar miembros",
        header: "Organizar miembros de la unidad",
        description: "Lista de miembros de la unidad {{unit}}.",
        updating: "Actualizando de miembros de la unidad...",
        success: "Miembros de la unidad actualizados con éxito!",
      }
    }
  },
  getUser: {
    pending: "Buscando dados de usuário...",
    successful: "Usuário encontrado!",
  },
  error: {
    invalid_format: "El formato de {{field}} es inválido",
    required: "Campo obligatório",
    minCharsRequired: "Debe contener al menos {{min}} caracteres",
    uppercaseRequired: "Debe contener al menos una letra mayúscula",
    lowercaseRequired: "Debe contener al menos una letra minúscula",
    numberRequired: "Debe contener al menos un número",
    specialRequired: "Debe contener al menos un caractere especial",
    passwordsIdentical: "Las contraseñas debem ser idénticas",
    api: {
      recaptchaNotAvailable: "Recaptcha no está disponible",
      userNotFound: "¡Usuario no encontrado!",
      userNameAlreadyExists: "¡Nombre de ususrio ya existe!",
      userAlreadyRegistered: "¡Usuario ya registrado!",
      emailNotAllowed: "¡Email no permitido para cadastro!",
      emailAlreadyExists: "¡Email ya existe!",
      incorrectPassword: "¡Usuario y contraseña no coinciden!",
      usedOTP: "Usuario no ha requerido recuperar contraseña ó ya ha usado su código de recuperación.",
      wrongOTP: "Código incorrecto.",
      expiredOTP: "Código ha expirado.",
      authError: "Erro de autenticación.",
      expiredSession: "¡La sesión ha expirado¡ Por favor, inicia sesión nuevamente.",
      serverError: "Erro de servidor.",
      databaseError: "Erro en la base de datos.",
      serverConnectionError: "La conexión con el servidor ha fallado. Vuelve a intentarlo más tarde.",
      unknownError: "Erro desconocido.",
      unknownTag: "Erro desconocido: \"{{tag}}\".",
      formError: "Un ó más campos fueram llenados incorrectamente.",
      simulationLoading: "¡Erro al cargar  simulación! Intente novamente más tarde.",
      groupLoading: "¡Erro al cargar equipo! Intente novamente más tarde.",
      groupEditing: "¡Erro al editar equipo!",
      groupsNotFound: "¡Nigún equipo encontrado!",
      projectIdNotValid: "¡ID de proyecto inválido!",
      projectNotFound: "¡Proyeto no encontrado!",
      publicEventSameName: "¡Un evento con el mismo nombre ya existe!",
      maxSubmissionsLimit: "¡Número maximo de sumisiones alcanzado!",
      eventNotFound: "¡Evento no encontrado!",
      outOfDeadline: "¡Fuera del límite!",
      licenseExpired: "Tu licencia ha expirado. Por favor, renueva tu suscripción para seguir simulando.",
      userNotAllowed: "No tiene permiso para realizar esta acción.",
      invalidRecaptcha: "Recaptcha inválido."
    },
    front:{
      noProjectSelected: "No proyecto seleccionado!",
      imageUploading: "Erro al cargar imagen!",
      fileUploading: "Erro al cargar archivo!",
      contentLoading: "Error al cargar contenido"
    },
    unity: {
      timeoutError: "El servidor no responde. Vuelve a intentarlo más tarde.",
      lostRosConnection: "Perdida de conexión con ROS. Vuelve a intentarlo más tarde.",
      loadSimulationError: "Erro al cargar la simulação. Vuelve a intentarlo más tarde."
    }
  },

  ...BLOCKLY_TRANSLATIONS_ES

};
