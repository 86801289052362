import BlocklyPY from "blockly/python";

BlocklyPY["stopwatch_instantiation_block"] = function (block) {
  var name = BlocklyPY.valueToCode(block, "name", BlocklyPY.ORDER_ATOMIC);
  var uid = BlocklyPY.valueToCode(block, "uid", BlocklyPY.ORDER_ATOMIC);
  var code = `Stopwatch(${name},${uid})`;
  return [code, BlocklyPY.ORDER_NONE];
};

BlocklyPY["stopwatch_setstate_block"] = function (block) {
  var value_state = block.getFieldValue("state");

  var code = `setStopwatchState(${value_state})`;
  return [code, BlocklyPY.ORDER_NONE]; // Passando type
};

BlocklyPY['stopwatch_getseconds_block'] = function(block) {
    // TODO: Assemble Python into code variable.
    var code = 'getSeconds()';
    // TODO: Change ORDER_NONE to the correct strength.
    return [code, BlocklyPY.ORDER_NONE];
  };


BlocklyPY['stopwatch_gettimeformat_block'] = function(block) {
    // TODO: Assemble Python into code variable.
    var code = 'getTimeFormat()';
    // TODO: Change ORDER_NONE to the correct strength.
    return [code, BlocklyPY.ORDER_NONE];
};