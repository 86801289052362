import BlocklyPY from "blockly/python";

BlocklyPY['controls_getsimulationstate_block'] = function(block) {
    // TODO: Assemble Python into code variable.
    var code = 'self.getExecNode().getSimulationState()';
    // TODO: Change ORDER_NONE to the correct strength.
    return [code, BlocklyPY.ORDER_NONE];
};

BlocklyPY['controls_start_block'] = function(block) {
    //var dropdown_state = block.getFieldValue('state');
    // TODO: Assemble Python into code variable.
    var code = `self.getExecNode().startSimulation()\n`;
    return code;
  };

  BlocklyPY['controls_pause_block'] = function(block) {
    //var dropdown_state = block.getFieldValue('state');
    // TODO: Assemble Python into code variable.
    var code = `self.getExecNode().pauseSimulation()\n`;
    return code;
  };

  BlocklyPY['controls_stop_block'] = function(block) {
    //var dropdown_state = block.getFieldValue('state');
    // TODO: Assemble Python into code variable.
    var code = `self.getExecNode().stopSimulation()\n`;
    return code;
  };