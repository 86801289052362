import BlocklyPY from "blockly/python";
import Blockly from "blockly";

BlocklyPY["photoelectricsensor_instantiation_block"] = function (block) {
  var name = BlocklyPY.valueToCode(block, "name", BlocklyPY.ORDER_ATOMIC);
  var uid = BlocklyPY.valueToCode(block, "uid", BlocklyPY.ORDER_ATOMIC);
  var code = `PhotoelectricSensor(${name},${uid})`;
  return [code, BlocklyPY.ORDER_NONE];
};

BlocklyPY["photoelectricsensor_getrange_block"] = function (block) {
  var dropdown_unit = block.getFieldValue("unit");
  var code = `getRange('${dropdown_unit}')`;
  return [code, BlocklyPY.ORDER_NONE];
};

BlocklyPY['photoelectricsensor_hasdetected_block'] = function(block) {
  // TODO: Assemble JavaScript into code variable.
  var code = 'hasDetected()';
  // TODO: Change ORDER_NONE to the correct strength.
  return [code, BlocklyPY.ORDER_NONE];
};
