import Blockly from "blockly";
import i18n from "../../translations/i18n";
import { blockColor, addSeparator } from "../BlockProperties";

Blockly.Blocks["mathfuncs_degtorad_block"] = {
  init: function () {
    this.appendValueInput("angle")
      .setCheck("Number")
      .setAlign(Blockly.ALIGN_RIGHT)
    this.appendDummyInput("title")
      .setAlign(Blockly.ALIGN_CENTRE)
      .appendField(i18n.t("MATH_CONVERT",{original: "°", target: "rad"}))
    this.setOutput(true, "Number");
    this.setInputsInline(true);
    this.setColour(blockColor('math'));
    //this.setTooltip("Converts an angle from degrees to radians");
    this.setTooltip(i18n.t("MATH_DEG_TO_RAD_TOOLTIP"));
    this.setHelpUrl("");
  },
};

Blockly.Blocks["mathfuncs_radtodeg_block"] = {
    init: function () {
      this.appendValueInput("angle")
        .setCheck("Number")
        .setAlign(Blockly.ALIGN_RIGHT)
      this.appendDummyInput("title")
        .setAlign(Blockly.ALIGN_CENTRE)
        .appendField(i18n.t("MATH_CONVERT",{original: "rad", target: "°"}))
      this.setOutput(true, "Number");
      this.setInputsInline(true);
      this.setColour(blockColor('math'));
      //this.setTooltip("Converts an angle from radians to degrees");
      this.setTooltip(i18n.t("MATH_RAD_TO_DEG_TOOLTIP"));
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks["mathfuncs_quaterniontoeuler_block"] = {
    init: function () {
        this.appendDummyInput("title")
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(addSeparator(i18n.t("MATH_QUATERNION_TO_EULER")));
        this.appendValueInput("quat_x")
            .setCheck("Number")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("x:")
        this.appendValueInput("quat_y")
            .setCheck("Number")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("y:")
        this.appendValueInput("quat_z")
            .setCheck("Number")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("z:")
        this.appendValueInput("quat_w")
            .setCheck("Number")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("w:")
        this.setOutput(true, "Array");
        this.setInputsInline(true);
        this.setColour(blockColor('math'));
        //this.setTooltip("Converts a quaternion angle to an Euler angle");
        this.setTooltip(i18n.t("MATH_QUATERNION_TO_EULER_TOOLTIP"));
        this.setHelpUrl("");
    },
  };

  Blockly.Blocks["mathfuncs_eulertoquaternion_block"] = {
    init: function () {
        this.appendDummyInput("title")
            .setAlign(Blockly.ALIGN_CENTRE)
            .appendField(addSeparator(i18n.t("MATH_EULER_TO_QUATERNION")));
        this.appendValueInput("euler_x")
            .setCheck("Number")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("x:")
        this.appendValueInput("euler_y")
            .setCheck("Number")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("y:")
        this.appendValueInput("euler_z")
            .setCheck("Number")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("z:")
        this.setOutput(true, "Array");
        this.setInputsInline(true);
        this.setColour(blockColor('math'));
        //this.setTooltip("Converts an Euler angle to a quaternion angle");
        this.setTooltip(i18n.t("MATH_EULER_TO_QUATERNION_TOOLTIP"));
        this.setHelpUrl("");
    },
  };

  Blockly.Blocks["mathfuncs_convertlengthunit_block"] = {
    init: function () {
        this.appendValueInput("length")
            .setCheck("Number")
            .setAlign(Blockly.ALIGN_RIGHT)
        this.appendDummyInput("curr_unit_dropdown")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(
                new Blockly.FieldDropdown([
                ["km", "km"],
                ["m", "m"],
                ["cm", "cm"],
                ["mm", "mm"],
                ["um", "um"],
                ["nm", "nm"]
                ]),
                "current_unit")
        this.appendDummyInput("des_unit_dropdown")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField(i18n.t("PLACEHOLDER_TO"))
            .appendField(
                new Blockly.FieldDropdown([
                ["km", "km"],
                ["m", "m"],
                ["cm", "cm"],
                ["mm", "mm"],
                ["um", "um"],
                ["nm", "nm"]
                ]),
                "desired_unit")
        this.setOutput(true, "Array");
        this.setInputsInline(true);
        this.setColour(blockColor('math'));
        //this.setTooltip("Converts a distance from one unit to another");
        this.setTooltip(i18n.t("MATH_CONVERT_LENGTH_UNIT_TOOLTIP"));
        this.setHelpUrl("");
    },
  };

  Blockly.Blocks["mathfuncs_convertlengthinmeters_block"] = {
    init: function () {
        this.appendValueInput("length")
            .setCheck("Number")
            .setAlign(Blockly.ALIGN_RIGHT)
        this.appendDummyInput("des_unit_dropdown")
            .setAlign(Blockly.ALIGN_RIGHT)
            .appendField("m")
            .appendField(i18n.t("PLACEHOLDER_TO"))
            .appendField(
                new Blockly.FieldDropdown([
                ["km", "km"],
                ["m", "m"],
                ["cm", "cm"],
                ["mm", "mm"],
                ["um", "um"],
                ["nm", "nm"]
                ]),
                "desired_unit")
        this.setOutput(true, "Array");
        this.setInputsInline(true);
        this.setColour(blockColor('math'));
        this.setTooltip(i18n.t("MATH_CONVERT_LENGTH_METERS_TOOLTIP"));
        this.setHelpUrl("");
    },
  };

  Blockly.Blocks["mathfuncs_converttimeunit_block"] = {
    init: function () {
      this.appendValueInput("time")
          .setCheck("Number")
          .setAlign(Blockly.ALIGN_RIGHT)
      this.appendDummyInput()
          .setAlign(Blockly.ALIGN_RIGHT)
          .appendField(
              new Blockly.FieldDropdown([
              ["hr", "hr"],
              ["min", "min"],
              ["s", "s"],
              ["ms", "ms"],
              ["us", "us"],
              ["ns", "ns"]
              ]),
              "current_unit")
      this.appendDummyInput()
          .setAlign(Blockly.ALIGN_RIGHT)
          .appendField(i18n.t("PLACEHOLDER_TO"))
          .appendField(
            new Blockly.FieldDropdown([
              ["hr", "hr"],
              ["min", "min"],
              ["s", "s"],
              ["ms", "ms"],
              ["us", "us"],
              ["ns", "ns"]
              ]),
              "desired_unit")
      this.setOutput(true, "Array");
      this.setInputsInline(true);
      this.setColour(blockColor('math'));
      this.setTooltip(i18n.t("MATH_CONVERT_TIME_UNIT_TOOLTIP"));
      this.setHelpUrl("");
  },
  };

  Blockly.Blocks["mathfuncs_converttimeinseconds_block"] = {
    init: function () {
      this.appendValueInput("time")
          .setCheck("Number")
          .setAlign(Blockly.ALIGN_RIGHT)
      this.appendDummyInput()
          .setAlign(Blockly.ALIGN_RIGHT)
          .appendField("s")
          .appendField(i18n.t("PLACEHOLDER_TO"))
          .appendField(
            new Blockly.FieldDropdown([
              ["hr", "hr"],
              ["min", "min"],
              ["s", "s"],
              ["ms", "ms"],
              ["us", "us"],
              ["ns", "ns"]
              ]),
              "desired_unit")
      this.setOutput(true, "Array");
      this.setInputsInline(true);
      this.setColour(blockColor('math'));
      this.setTooltip(i18n.t("MATH_CONVERT_TIME_SECONDS_TOOLTIP"));
      this.setHelpUrl("");
  },
  };

  Blockly.Blocks["mathfuncs_convertvelocityunit_block"] = {
    init: function () {
      this.appendValueInput("velocity")
          .setCheck("Number")
          .setAlign(Blockly.ALIGN_RIGHT)
      this.appendDummyInput()
          .setAlign(Blockly.ALIGN_RIGHT)
          .appendField(
              new Blockly.FieldDropdown([
              ["km/h", "km/h"],
              ["m/s", "m/s"],
              ["cm/s", "cm/s"],
              ["mm/s", "mm/s"]
              ]),
              "current_unit")
      this.appendDummyInput()
          .setAlign(Blockly.ALIGN_RIGHT)
          .appendField(i18n.t("PLACEHOLDER_TO"))
          .appendField(
            new Blockly.FieldDropdown([
              ["km/h", "km/h"],
              ["m/s", "m/s"],
              ["cm/s", "cm/s"],
              ["mm/s", "mm/s"]
              ]),
              "desired_unit")
      this.setOutput(true, "Array");
      this.setInputsInline(true);
      this.setColour(blockColor('math'));
      this.setTooltip(i18n.t("MATH_CONVERT_VELOCITY_UNIT_TOOLTIP"));
      this.setHelpUrl("");
    }
  };

  Blockly.Blocks["mathfuncs_convertvelocityinmeterspersec_block"] = {
    init: function () {
      this.appendValueInput("velocity")
          .setCheck("Number")
          .setAlign(Blockly.ALIGN_RIGHT)
      this.appendDummyInput()
          .setAlign(Blockly.ALIGN_RIGHT)
          .appendField("m/s")
          .appendField(i18n.t("PLACEHOLDER_TO"))
          .appendField(
            new Blockly.FieldDropdown([
              ["km/h", "km/h"],
              ["m/s", "m/s"],
              ["cm/s", "cm/s"],
              ["mm/s", "mm/s"]
              ]),
              "desired_unit")
      this.setOutput(true, "Array");
      this.setInputsInline(true);
      this.setColour(blockColor('math'));
      this.setTooltip(i18n.t("MATH_CONVERT_VELOCITY_METERSPERSEC_TOOLTIP"));
      this.setHelpUrl("");
    }
  };
