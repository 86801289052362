import Blockly from "blockly";
import { blockColor, addSeparator } from "../../../BlockProperties";
import i18n from "../../../../translations/i18n";
import "./pythonCode";

//================================================================================================================
//================================================= LineFollower BLOCKS =================================================
//================================================================================================================

Blockly.Blocks["linefollowerbot_instantiation_block"] = {
  init: function () {
    this.appendDummyInput("class_type")
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(addSeparator("LineFollowerBot"));
    this.appendValueInput("name")
      .setCheck("String")
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(i18n.t("PLACEHOLDER_NAME") + ":");
    this.appendValueInput("uid")
      .setCheck("Number")
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(i18n.t("PLACEHOLDER_UID") + ":");
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(blockColor("parameters"));
    this.setTooltip(i18n.t("LINEFOLLOWERBOT_INSTANTIATE_TOOLTIP"));
    this.setHelpUrl("");
  },
};

Blockly.Blocks["linefollowerbot_getcolor_block"] = {
  init: function () {
    this.appendDummyInput()
      .appendField(addSeparator(i18n.t("COLORSENSOR_GET_COLOR")) + " " + i18n.t("PLACEHOLDER_POSITION")+ ":")
      .appendField(
        new Blockly.FieldDropdown([
        [i18n.t("PLACEHOLDER_CENTER"), "center"],
        [i18n.t("PLACEHOLDER_LEFT"), "left"],
        [i18n.t("PLACEHOLDER_RIGHT"), "right"]
        ]),
      "position"
    );
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(blockColor("sensing"));
    this.setTooltip(i18n.t("LINEFOLLOWERBOT_GET_COLOR_TOOLTIP"));
    this.setHelpUrl("");
  },
};

Blockly.Blocks["linefollowerbot_gethexcolor_block"] = {
  init: function () {
    this.appendDummyInput()
      .appendField(addSeparator(i18n.t("COLORSENSOR_GET_HEX_COLOR")) + " " + i18n.t("PLACEHOLDER_POSITION")+ ":")
      .appendField(
        new Blockly.FieldDropdown([
        [i18n.t("PLACEHOLDER_CENTER"), "center"],
        [i18n.t("PLACEHOLDER_LEFT"), "left"],
        [i18n.t("PLACEHOLDER_RIGHT"), "right"]
        ]),
      "position"
    );
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(blockColor("sensing"));
    this.setTooltip(i18n.t("LINEFOLLOWERBOT_GET_HEX_COLOR_TOOLTIP"));
    this.setHelpUrl("");
  },
};

Blockly.Blocks["linefollowerbot_getgrayscalecolor_block"] = {
  init: function () {
    this.appendDummyInput()
      .appendField(addSeparator(i18n.t("COLORSENSOR_GET_GRAYSCALE_COLOR")) + " " + i18n.t("PLACEHOLDER_POSITION")+ ":")
      .appendField(
        new Blockly.FieldDropdown([
        [i18n.t("PLACEHOLDER_CENTER"), "center"],
        [i18n.t("PLACEHOLDER_LEFT"), "left"],
        [i18n.t("PLACEHOLDER_RIGHT"), "right"]
        ]),
      "position"
    );
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(blockColor("sensing"));
    this.setTooltip(i18n.t("LINEFOLLOWERBOT_GET_GRAYSCALE_COLOR_TOOLTIP"));
    this.setHelpUrl("");
  },
};

Blockly.Blocks["linefollowerbot_getcolors_block"] = {
  init: function () {
    this.appendDummyInput()
      .appendField(i18n.t("LINEFOLLOWERBOT_GET_COLORS"))
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(blockColor("sensing"));
    this.setTooltip(i18n.t("LINEFOLLOWERBOT_GET_COLORS_TOOLTIP"));
    this.setHelpUrl("");
  },
};

Blockly.Blocks["linefollowerbot_getrange_block"] = {
  init: function () {
    this.appendDummyInput()
      .appendField(i18n.t("RANGESENSOR_GET_RANGE") + " " + i18n.t("PLACEHOLDER_IN"))
      .appendField(
          new Blockly.FieldDropdown([
          ["km", "km"],
          ["m", "m"],
          ["cm", "cm"],
          ["mm", "mm"],
          ["um", "um"],
          ["nm", "nm"]
          ]),
        "unit"
      )
      .appendField(addSeparator("") + " "+ i18n.t("PLACEHOLDER_POSITION") +":")
      .appendField(
        new Blockly.FieldDropdown([
        [i18n.t("PLACEHOLDER_CENTER"), "center"],
        [i18n.t("PLACEHOLDER_LEFT"), "left"],
        [i18n.t("PLACEHOLDER_RIGHT"), "right"],
        ]),
      "position"
    )
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(blockColor("sensing"));
    this.setTooltip(i18n.t("LINEFOLLOWERBOT_GET_RANGE_TOOLTIP"));
    this.setHelpUrl("");
  },
};

Blockly.Blocks["linefollowerbot_getranges_block"] = {
  init: function () {
    this.appendDummyInput()
      .appendField(i18n.t("PIONEER_GET_RANGES") + " " + i18n.t("PLACEHOLDER_IN"))
      .appendField(
          new Blockly.FieldDropdown([
          ["km", "km"],
          ["m", "m"],
          ["cm", "cm"],
          ["mm", "mm"],
          ["um", "um"],
          ["nm", "nm"]
          ]),
        "unit"
      );
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(blockColor("sensing"));
    this.setTooltip(i18n.t("LINEFOLLOWERBOT_GET_RANGES_TOOLTIP"));
    this.setHelpUrl("");
  },
};