import { t } from "i18next";
import i18n from "../../../translations/i18n";

const ToastErrorMsgsDict: { [key: string]: string } = {
  GeneralMessage: "",

  serverConnectionError: "error.api.serverConnectionError",

  jsonNotFound: "error.api.serverError",
  parsingError: "error.api.serverError",
  
  postgresError: "error.api.databaseError",
  redisError: "error.api.databaseError",

  missingPasswordOrCode: "error.api.formError",
  notAcceptedField: "error.api.formError",
  missingField: "error.api.formError",
  notSameLength: "error.api.formError",

  missingAuth: "error.api.authError",
  expiredAuth: "error.api.expiredSession",
  wrongAuth: "error.api.authError",
  notPermited: "error.api.authError",
  invalidRefreshToken: "error.api.expiredSession",
  invalidRecaptcha: "error.api.invalidRecaptcha",

  userNotFound: "error.api.userNotFound",
  userAlreadyRegistered: "error.api.userAlreadyRegistered",
  emailNotAllowed: "error.api.emailNotAllowed",
  incorrectPassword: "error.api.incorrectPassword",

  usedOTP: "error.api.usedOTP",
  wrongOTP: "error.api.wrongOTP",
  expiredOTP: "error.api.expiredOTP",

  usersPromoted: "",
  userFound: "",
  userRegistered: "",
  userAuthenticated: "",
  userNotAllowed: "error.api.userNotAllowed",

  unknownError: "error.api.unknownError",
  //undefined: "error.api.unknownError",
  nothingFound: "error.api.nothingFound",

  internalServerError: "error.api.serverError",

  projectIdNotValid: "error.api.projectIdNotValid",
  projectNotFound: "error.api.projectNotFound",
  publicEventSameName: "error.api.publicEventSameName",
  maxSubmissionsLimit: "error.api.maxSubmissionsLimit",
  groupLoading: "error.api.groupLoading",
  editGroup: "error.api.groupEditing",
  groupsNotFound: "error.api.groupsNotFound",
  eventNotFound: "error.api.eventNotFound",
  outOfDeadline: "error.api.outOfDeadline",

  licenseExpired: "error.api.licenseExpired",

  timeoutError: "error.unity.timeoutError",
  lostRosConnection: "error.unity.lostRosConnection",
  loadSimulationError: "error.unity.loadSimulationError",
  
  recaptchaNotAvailable: "components.passwordRecovery.recaptchaNotAvailable"
};

const ToastPendingMsgsDict: { [key: string]: string } = {
  login: "components.login.pending",
  registration: "components.registration.pending",
  otpSend: "components.passwordRecovery.commons.otpSend.pending",
  otpCheck: "components.passwordRecovery.CodeInputForm.otpCheck.pending",
  request: "commons.request.pending",
  changePassword: "components.myAccount.security.changePassword.pending",
  create: "commons.msgs.creating",
  update: "commons.msgs.updating",
  copy: "commons.msgs.copying",
  edit: "commons.msgs.editing",
  delete: "commons.msgs.deleting",
  createEvent: "components.events.Event.eventForm.creatingEvent",
  addMember: "components.events.group.groupTab.addingMember",
  removeMember: "components.events.group.groupTab.removingMember",
  quitGroup: "components.events.group.groupTab.quiting",
  unsubscribeGroup:  "components.events.group.groupTab.unsubscribingGroup",
  getUser: "getUser.pending",
  updateUser: "components.myAccount.userInfo.updateUser.pending",
  uploadProfilePicture: "components.commons.uploadImage.pending",
  addAddress: "components.myAccount.addresses.addAddress.pending",
  openUnity: "commons.msgs.openingUnity",
};

export const ErrorTagExists = (key: string) => {
  return ToastErrorMsgsDict[key] !== undefined;
}

export const ToastErrorMsgs = (key: string) => {
  return ToastErrorMsgsDict[key] !== undefined
    ? i18n.t(`${ToastErrorMsgsDict[key]}`)
    : i18n.t("error.api.unknownTag",{tag: key});
};

// verificar forma de não precisar utilizar isso:
export const ToastErrorExtraMsgs = (msg: string) => {
  if(msg.includes("e_user_user_email_key")){
    return t("error.api.emailAlreadyExists") 
  }
  else if(msg.includes("e_user_user_username_key")){
    return t("error.api.userNameAlreadyExists")
  }
};

export const ToastPendingMsgs = (key: string, replacement?: string) => {
  return key !== undefined
    ? i18n.t(`${ToastPendingMsgsDict[key]}`).replace(/{.*}/, replacement ?? "")
    : i18n.t(`${ToastPendingMsgsDict["request"]}`);
};

// export const ToastStatus = (success: boolean | undefined, request: string) => {
//     return success ? i18n.t(`commons.request.successful`,{ request: request }) : i18n.t(`commons.request.failed`,{ request: request })
// }
export const ToastStatus = (
  target: string | undefined,
  statusMsg: string | undefined
) => {
  if (statusMsg !== undefined) {
    if (target !== undefined) {
      return i18n.t(statusMsg).replace(/{.*}/, target);
    } else {
      return i18n.t(statusMsg);
    }
  } else return "";
};
