import BlocklyPY from "blockly/python";

BlocklyPY["waittime_block"] = function (block) {
    var time_value = BlocklyPY.valueToCode(block, "time", BlocklyPY.ORDER_ATOMIC);
    var dropdown_unit = block.getFieldValue("unit");
    var code = `self.waitTime(${time_value},'${dropdown_unit}')`;
    return code + '\n';
};

BlocklyPY['controls_getsimulationtime_block'] = function(block) {
    // TODO: Assemble Python into code variable.
    var code = 'self.getExecNode().getSimulationTime()';
    // TODO: Change ORDER_NONE to the correct strength.
    return [code, BlocklyPY.ORDER_NONE];
  };


BlocklyPY['controls_getrealtime_block'] = function(block) {
    // TODO: Assemble Python into code variable.
    var code = 'self.getExecNode().getRealTime()';
    // TODO: Change ORDER_NONE to the correct strength.
    return [code, BlocklyPY.ORDER_NONE];
};

BlocklyPY['time_loop'] = function(block) {
    var number_time = block.getFieldValue('time');
    var dropdown_unit = block.getFieldValue('unit');
    var statements_loop = BlocklyPY.statementToCode(block, 'loop');
    // TODO: Assemble Python into code variable.
    var unit = "";
  
    if (dropdown_unit=== 'seconds')
      unit = 'round(time.time() * 1)';
    else if (dropdown_unit=== 'milliseconds')
      unit = 'round(time.time() * 1000)';
    else if (dropdown_unit=== 'microseconds')
      unit = 'round(time.time() * 1000000)';
  
  
    var code = 'var_initialTime = '+unit+'\r\nvar_finalTime = var_initialTime\r\nwhile((var_finalTime - var_initialTime) <= '+number_time+'):\r\n'+statements_loop+'\r\n\tvar_finalTime = '+unit+'\n';
    return code;
  };