import Blockly from "blockly";
import i18n from "../../../translations/i18n";
import { blockColor } from "../../BlockProperties";

Blockly.Blocks["waittime_block"] = {
    init: function () {
      this.appendDummyInput("class_type")
        .setAlign(Blockly.ALIGN_RIGHT)
        //.appendField("wait");
        .appendField(i18n.t("TIME_WAIT"));
      this.appendValueInput("time")
        .setCheck("Number")
        .setAlign(Blockly.ALIGN_RIGHT)
      this.appendDummyInput("dropdown")
        .appendField(new Blockly.FieldDropdown([
            ["hr", "hr"],
            ["min", "min"],
            ["s", "s"],
            ["ms", "ms"],
            ["us", "us"],
            ["ns", "ns"],
          ]),
          "unit")
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(blockColor("controls"));
      this.setTooltip(i18n.t("TIME_WAIT_TOOLTIP"));
      this.setHelpUrl("");
    },
  };

  Blockly.Blocks['controls_getsimulationtime_block'] = {
    init: function() {
      this.appendDummyInput()
          .appendField(i18n.t("SIMULATION_GET_TIME"));
      this.setInputsInline(true);
      this.setOutput(true, null);
      this.setColour(blockColor("controls"));
   this.setTooltip(i18n.t("SIMULATION_GET_TIME_TOOLTIP"));
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['controls_getrealtime_block'] = {
    init: function() {
      this.appendDummyInput()
          .appendField(i18n.t("SIMULATION_GET_REAL_TIME"));
      this.setInputsInline(true);
      this.setOutput(true, null);
      this.setColour(blockColor("controls"));
   this.setTooltip(i18n.t("SIMULATION_GET_REAL_TIME_TOOLTIP"));
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['time_loop'] = {
    init: function() {
      this.appendDummyInput()
          .appendField(i18n.t("SIMULATION_TIME_LOOP")) 
          .appendField(new Blockly.FieldNumber(0, 0), "time")
          .appendField(new Blockly.FieldDropdown([
            ["hr", "hr"],
            ["min", "min"],
            ["s", "s"],
            ["ms", "ms"],
            ["us", "us"],
            ["ns", "ns"],
          ]), "unit");
      this.appendStatementInput("loop")
          .setCheck(null);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(blockColor("controls"));
   this.setTooltip(i18n.t("SIMULATION_TIME_LOOP_TOOLTIP"));
   this.setHelpUrl("");
    }
  };