import i18n, { t } from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

import { TRANSLATIONS_PT } from "./pt/translations";
import { TRANSLATIONS_EN } from "./en/translations";
import { TRANSLATIONS_ES } from "./es/translations";
import detectionOptions from "./detectionOptions"
import { ToastPendingMsgs } from "../components/commons/Toast/ToastMsg";

const MsgsDict: { [key: string]: string } = {
  logging: "components.login.pending",
  registering: "components.registration.pending",
  otpSending: "components.passwordRecovery.commons.otpSend.pending",
  otpChecking: "components.passwordRecovery.CodeInputForm.otpCheck.pending",
  requesting: "commons.request.pending",
  changingPassword: "components.myAccount.security.changePassword.pending",
  creating: "commons.msgs.creating",
  updating: "commons.msgs.updating",
  copying: "commons.msgs.copying",
  editing: "commons.msgs.editing",
  deleting: "commons.msgs.deleting",
  creatingEvent: "components.events.Event.eventForm.creatingEvent",
  addingMember: "components.events.group.groupTab.addingMember",
  removingMember: "components.events.group.groupTab.removingMember",
  quitingGroup: "components.events.group.groupTab.quiting",
  unsubscribingGroup:  "components.events.group.groupTab.unsubscribingGroup",
  archiving: "commons.msgs.archiving",
  unarchiving: "commons.msgs.unarchiving",
  sharing: "commons.msgs.sharing",
  gettingUser: "getUser.pending",
  updatingUser: "components.myAccount.userInfo.updateUser.pending",
  uploadingProfilePicture: "components.commons.uploadImage.pending",
  addingAddress: "components.myAccount.addresses.addAddress.pending",
  openingUnity: "commons.msgs.openingUnity",

  login: "components.login.successful",
  registration: "components.registration.successfull",
  otpSend: "components.passwordRecovery.commons.otpSend.successfull",
  otpCheck: "components.passwordRecovery.CodeInputForm.otpCheck.sucessfull",
  request: "commons.request.successfull",
  changePassword: "components.myAccount.security.changePassword.successfull",
  create: "commons.msgs.create",
  update: "commons.msgs.update",
  copy: "commons.msgs.copy",
  edit: "commons.msgs.edit",
  delete: "commons.msgs.delete",
  createEvent: "components.events.Event.eventForm.createEvent",
  addMember: "components.events.group.groupTab.addMember",
  removeMember: "components.events.group.groupTab.removeMember",
  unsubscribeMemberWarning: "components.events.group.groupTab.unsubscribeMemberWarning",
  quitGroup: "components.events.group.groupTab.quit",
  unsubscribeGroup:  "components.events.group.groupTab.unsubscribeGroup",
  archive: "commons.msgs.archive",
  unarchive: "commons.msgs.unarchive",
  share: "commons.msgs.share",
  getUser: "getUser.successfull",
  updateUser: "components.myAccount.userInfo.updateUser.successfull",
  uploadProfilePicture: "components.commons.uploadImage.successfull",
  addAddress: "components.myAccount.addresses.addAddress.successfull",
  openUnity: "commons.msgs.openUnity",

  edited_male: "commons.msgs.edited_male",
  edited_female: "commons.msgs.edited_female",
  deleted_male: "commons.msgs.deleted_male",
  deleted_female: "commons.msgs.deleted_female",
  created_male: "commons.msgs.created_male",
  created_female: "commons.msgs.created_female",
  updated_male: "commons.msgs.updated_male",
  updated_female: "commons.msgs.updated_female",
  archived: "commons.msgs.archived",
  unarchived: "commons.msgs.unarchived",
  shared: "commons.msgs.shared",

  defaultName_male: "commons.defaultName_male",
  defaultName_female: "commons.defaultName_female"
};

i18n
  .use(LanguageDetector)
  .use(initReactI18next)
  .init({
    keySeparator: '.',
    interpolation: {
      escapeValue: false,
      format: function (str, format) {
        if (format === "firstUpper") return str.charAt(0).toUpperCase() + str.slice(1);
      }
    },
    detection: detectionOptions,
    resources: {
      en: {
        translation: TRANSLATIONS_EN
      },
      pt: {
        translation: TRANSLATIONS_PT
      },
      es: {
        translation: TRANSLATIONS_ES
      }
    }
  });

// const setLanguage = (lang: string) => i18n.changeLanguage(lang);

export function toCamelCase(str: string) {
  let path = str;
  let newStr = "";
  if (str) {
    if(path.startsWith("/")) path = path.substring(1);
    let wordArr = path.split("/");
    path = wordArr.join(".");
    wordArr = path.split("-");
    for (let i = 0; i < wordArr.length; i++) {
      if (i > 0) {
        newStr += wordArr[i].charAt(0).toUpperCase() + wordArr[i].slice(1);
      } else {
        newStr += wordArr[i];
      }
    }
  } else {
    return newStr;
  }
  return newStr;
}

export function makeFirstCharUpper(str: string){
  let originalStr = str;
  let first = str[0];
  return first.toUpperCase() + originalStr.substring(1);
}

export function i18nReplace(key: string, substitutive: string, firstCharUpper?: boolean){
  return MsgsDict[key] ? t(MsgsDict[key]).replace(/{.*}/, (firstCharUpper ? makeFirstCharUpper(substitutive) : substitutive))
        : t(key).replace(/{.*}/, (firstCharUpper ? makeFirstCharUpper(substitutive) : substitutive))
}

export default i18n;
