import React from "react";
import "./BaseButton.scss";
import styles from "./styles.module.css";

type props = {
  disabled?: boolean;
  style?: any;
  type?: any;
  onClick?: any;
  label?: string;
  link?: string;
  className?: string;
  id?: string;
};

interface IState {
  maxTime: number;
}

class BaseButton extends React.Component<props, IState> {
  constructor(props: props) {
    super(props);
    this.getStyle = this.getStyle.bind(this);
    // this.renderButton = this.renderButton.bind(this);
    this.state = { maxTime: 5 };
  }

  getStyle() {
    let style =
      this.props.style !== undefined
        ? this.props.style
        : this.props.type === "submit" || this.props.type === "reset"
          ? this.props.type
          : "";
    return styles[style];
  }

  getHtmlType() {
    let type =
      this.props.type === "submit" || this.props.type === "reset"
        ? this.props.type
        : "button";
    return type;
  }

  button = (props: props) => {
    return (
      <button
        disabled={props.disabled}
        type={this.getHtmlType()}
        className={`base-btn ${this.getStyle()} ` + (props.className !== undefined ? props.className : "")}
        onClick={props.onClick}
        id={props.id}
      >
        {props.label}
      </button>
    );
  };



  render() {

    let btn =
      // this.props.link !== undefined ? (
      //   <this.button
      //     {...this.props}
      //     onClick={() => this.props.("/")}
      //   />
      // ) : (
      //   <this.button {...this.props} />
      // );
      <this.button {...this.props} />
    return btn;
  }
}

export default BaseButton;
