import BlocklyPY from "blockly/python";

BlocklyPY["mathfuncs_degtorad_block"] = function(block){
    var angle_value = BlocklyPY.valueToCode(block, "angle", BlocklyPY.ORDER_ATOMIC);
    // var code = "MathFuncs.degToRad(" + angle_value + ")\n";
    var code = `MathFuncs.degToRad(${angle_value})`;
    return [code,BlocklyPY.ORDER_NONE];
};

BlocklyPY["mathfuncs_radtodeg_block"] = function(block){
    var angle_value = BlocklyPY.valueToCode(block, "angle", BlocklyPY.ORDER_ATOMIC);
    var code = `MathFuncs.radToDeg(${angle_value})`;
    return [code,BlocklyPY.ORDER_NONE];
};

BlocklyPY["mathfuncs_quaterniontoeuler_block"] = function(block){
    var quat_x = BlocklyPY.valueToCode(block, "quat_x", BlocklyPY.ORDER_ATOMIC);
    var quat_y = BlocklyPY.valueToCode(block, "quat_y", BlocklyPY.ORDER_ATOMIC);
    var quat_z = BlocklyPY.valueToCode(block, "quat_z", BlocklyPY.ORDER_ATOMIC);
    var quat_w = BlocklyPY.valueToCode(block, "quat_z", BlocklyPY.ORDER_ATOMIC);
    var code = `MathFuncs.quaternionToEuler(${quat_x},${quat_y},${quat_z},${quat_w})`;
    return [code,BlocklyPY.ORDER_NONE];
};

BlocklyPY["mathfuncs_eulertoquaternion_block"] = function(block){
    var euler_x = BlocklyPY.valueToCode(block, "euler_x", BlocklyPY.ORDER_ATOMIC);
    var euler_y = BlocklyPY.valueToCode(block, "euler_y", BlocklyPY.ORDER_ATOMIC);
    var euler_z = BlocklyPY.valueToCode(block, "euler_z", BlocklyPY.ORDER_ATOMIC);
    var code = `MathFuncs.eulerToQuaternion(${euler_x},${euler_y},${euler_z})`;
    return [code,BlocklyPY.ORDER_NONE];
};

BlocklyPY["mathfuncs_convertlengthunit_block"] = function(block){
    var length_value = BlocklyPY.valueToCode(block, "length", BlocklyPY.ORDER_ATOMIC);
    var current_unit = block.getFieldValue("current_unit");
    var desired_unit = block.getFieldValue("desired_unit");
    var code = `MathFuncs.convertLengthUnit(${length_value},'${current_unit}','${desired_unit}')`;
    return [code,BlocklyPY.ORDER_NONE];
};

BlocklyPY["mathfuncs_convertlengthinmeters_block"] = function(block){
    var length_value = BlocklyPY.valueToCode(block, "length", BlocklyPY.ORDER_ATOMIC);
    var desired_unit = block.getFieldValue("desired_unit");
    var code = `MathFuncs.convertLength(${length_value},'${desired_unit}')`;
    return [code,BlocklyPY.ORDER_NONE];
};

BlocklyPY["mathfuncs_converttimeunit_block"] = function(block){
    var time_value = BlocklyPY.valueToCode(block, "time", BlocklyPY.ORDER_ATOMIC);
    var current_unit = block.getFieldValue("current_unit");
    var desired_unit = block.getFieldValue("desired_unit");
    var code = `MathFuncs.convertTimeUnit(${time_value},'${current_unit}','${desired_unit}')`;
    return [code,BlocklyPY.ORDER_NONE];
};

BlocklyPY["mathfuncs_converttimeinseconds_block"] = function(block){
    var time_value = BlocklyPY.valueToCode(block, "time", BlocklyPY.ORDER_ATOMIC);
    var desired_unit = block.getFieldValue("desired_unit");
    var code = `MathFuncs.convertTime(${time_value},'${desired_unit}')`;
    return [code,BlocklyPY.ORDER_NONE];
};

BlocklyPY["mathfuncs_convertvelocityunit_block"] = function(block){
    var velocity_value = BlocklyPY.valueToCode(block, "velocity", BlocklyPY.ORDER_ATOMIC);
    var current_unit = block.getFieldValue("current_unit");
    var desired_unit = block.getFieldValue("desired_unit");
    var code = `MathFuncs.convertVelocityUnit(${velocity_value},'${current_unit}','${desired_unit}')`;
    return [code,BlocklyPY.ORDER_NONE];
};

BlocklyPY["mathfuncs_convertvelocityinmeterspersec_block"] = function(block){
    var velocity_value = BlocklyPY.valueToCode(block, "velocity", BlocklyPY.ORDER_ATOMIC);
    var desired_unit = block.getFieldValue("desired_unit");
    var code = `MathFuncs.convertVelocity(${velocity_value},'${desired_unit}')`;
    return [code,BlocklyPY.ORDER_NONE];
};