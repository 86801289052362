import Blockly from "blockly";
import i18n from "../../../translations/i18n";
import { blockColor } from "../../BlockProperties";

Blockly.Blocks['controls_configscoresystem_block'] = {
  init: function() {
    this.appendDummyInput()
        .appendField(i18n.t("SIMULATION_CONFIG_SCORE_SYSTEM"));
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(blockColor("controls"));
 this.setTooltip(i18n.t("SIMULATION_CONFIG_SCORE_SYSTEM_TOOLTIP"));
 this.setHelpUrl("");
  }
};


Blockly.Blocks['controls_getscore_block'] = {
  init: function() {
    this.appendDummyInput()
        .appendField(i18n.t("SIMULATION_GET_SCORE"));
    this.setInputsInline(true);
    this.setOutput(true, null);
    this.setColour(blockColor("controls"));
 this.setTooltip(i18n.t("SIMULATION_GET_SCORE_TOOLTIP"));
 this.setHelpUrl("");
  }
};

  Blockly.Blocks['controls_setscore_block'] = {
    init: function() {
      this.appendValueInput("score")
          .setCheck("Number")
          .appendField(i18n.t("SIMULATION_SET_SCORE"));
      this.setInputsInline(true);
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(blockColor("controls"));
   this.setTooltip(i18n.t("SIMULATION_SET_SCORE_TOOLTIP"));
   this.setHelpUrl("");
    }
  };