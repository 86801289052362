import {  FormikProps } from "formik";
import React, { createContext } from "react";
import { useState } from "react";
import i18n from "../translations/i18n";
import useMediaQuery from "@mui/material/useMediaQuery";
import ScreenResolution from "../enums/ScreenResolution";

type GlobalContextProps = {
    children?: any;
}

type GlobalProps = {
    clearFormErrors: boolean;
    setClearFormErrors: React.Dispatch<React.SetStateAction<boolean>>;
    lang: string;
    setLang: React.Dispatch<React.SetStateAction<string>>;
    formikRef: React.RefObject<FormikProps<any>>;
    setFormikRef: React.Dispatch<React.SetStateAction<React.RefObject<FormikProps<any>>>>;
    screenResolution: ScreenResolution
};

const DEFAULT_VALUE = {
    clearFormErrors: false,
    setClearFormErrors: () => { },
    lang: i18n.language.substring(0, 2),
    //lang: "pt",
    setLang: () => { },
    formikRef: null as any,
    setFormikRef: () => { },
    screenResolution: ScreenResolution.DesktopWide
};

const GlobalContext = createContext<GlobalProps>(DEFAULT_VALUE);

const GlobalContextProvider: React.FC<GlobalContextProps> = ({ children }) => {
    const [clearFormErrors, setClearFormErrors] = useState(DEFAULT_VALUE.clearFormErrors);
    const [lang, setLang] = useState(DEFAULT_VALUE.lang);
    const [formikRef, setFormikRef] = useState(DEFAULT_VALUE.formikRef);
    const screenMatchesMobile = useMediaQuery("(max-width: 720px)");
    const screenMatchesDesktopSmall = useMediaQuery("(max-width: 920px)");
    const screenMatchesDesktopMedium = useMediaQuery("(max-width: 1350px)");
    const screenResolution = 
        screenMatchesMobile ? ScreenResolution.Mobile 
        : screenMatchesDesktopSmall ? ScreenResolution.DesktopSmall 
        : screenMatchesDesktopMedium ? ScreenResolution.DesktopMedium
        : ScreenResolution.DesktopWide
    return (
        <GlobalContext.Provider value={{ clearFormErrors, setClearFormErrors, lang, setLang, formikRef, setFormikRef, screenResolution}}>
            {children}
        </GlobalContext.Provider>
    )
}

export { GlobalContextProvider }
export default GlobalContext;