import BlocklyPY from "blockly/python";

BlocklyPY["spawner_instantiation_block"] = function (block) {
  var name = BlocklyPY.valueToCode(block, "name", BlocklyPY.ORDER_ATOMIC);
  var uid = BlocklyPY.valueToCode(block, "uid", BlocklyPY.ORDER_ATOMIC);
  var code = `Spawner(${name},${uid})`;
  return [code, BlocklyPY.ORDER_NONE];
};

BlocklyPY["spawner_setstate_block"] = function (block) {
  var value_state = block.getFieldValue("state");
  //var value_state = BlocklyPY.valueToCode(block,"state",BlocklyPY.ORDER_ATOMIC);
  var code = `setSpawnerState(${value_state})`;
  return [code, BlocklyPY.ORDER_NONE]; // Passando type
};
