import { blockColor } from "../../BlockProperties";
import "./block";
import "./pythonCode";

import { getSpaceUntilMaxLength } from "@testing-library/user-event/dist/utils";
import i18n from "../../../translations/i18n";

function GetStopwatchToolbox(actorId: string){

  let stopwatchToolbox = {
    kind: "category",
    name: i18n.t("PLACEHOLDER_STOPWATCH"),
    expanded: true,
    contents: [
      {
        kind: "category",
        name: i18n.t("PLACEHOLDER_ACTIONS"),
        colour: blockColor("actions"),
        contents: [
            {
                kind: "block",
                type:"variables_call",
                fields:{
                    "VAR":{
                        type: "field_variable",
                        id: actorId
                    }
                },
                inputs:{
                    VALUE:{
                        block: {
                            type: "stopwatch_setstate_block",
                            fields:{
                              state: 1
                            },
                        }
                    }
                }
            },
        ],
      },
      {
        kind: "category",
        name: i18n.t("PLACEHOLDER_SENSING"),
        colour: blockColor("sensing"),
        contents: [
            {
                kind: "block",
                type:"variables_call_out",
                fields:{
                    "VAR":{
                        type: "field_variable",
                        id: actorId
                    }
                },
                inputs:{
                    VALUE:{
                        block: {
                            type: "stopwatch_getseconds_block"
                        }
                    }
                }
            },
            {
                kind: "block",
                type:"variables_call_out",
                fields:{
                    "VAR":{
                        type: "field_variable",
                        id: actorId
                    }
                },
                inputs:{
                    VALUE:{
                        block: {
                            type: "stopwatch_gettimeformat_block"
                        }
                    }
                }
            }
        ]
      },
      {
        kind: "category",
        name: i18n.t("PLACEHOLDER_PARAMETERS"),
        colour: blockColor("parameters"),
        contents: [
            {
                kind: "block",
                type: "stopwatch_instantiation_block",
                inputs:{
                  name:{
                      block: {
                          type: "text",
                          fields: {
                              TEXT: "Stopwatch"
                          }
                      }
                  },
                  uid:{
                      block: {
                          type: "math_number",
                          fields: {
                              NUM: 1
                          }
                      }
                  }
                }
            },
        ],
      },
    ],
  };

  return stopwatchToolbox;

}

export default GetStopwatchToolbox;
