import { Model } from "./Model";
import { ModelPose } from "./ModelPose";
import ModelProperties from "./ModelProperties";
import { ModelResource } from "./Project";

class SceneModel {
    model_id: string;
    model_properties: ModelProperties;
    model_pose: ModelPose;
    model_type: string;
    model_name: string;

    constructor(values: {
        model_id: string,
        model_properties: ModelProperties,
        model_pose: ModelPose,
        model_type: string,
        model_name: string
    }){
        this.model_id = values.model_id;
        this.model_properties = values.model_properties;
        this.model_pose = values.model_pose;
        this.model_type = values.model_type;
        this.model_name = values.model_name;
    }
}

class Scene {
    models: SceneModel[];
    constructor( values: {
      models: SceneModel[]
    }){
      this.models = values.models;
    }
  }

  export {Scene, SceneModel};