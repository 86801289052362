import Blockly from "blockly";
import { blockColor, addSeparator } from "../../BlockProperties";
import i18n from "../../../translations/i18n";

//================================================================================================================
//================================================= ColorSensor BLOCKS =================================================
//================================================================================================================

Blockly.Blocks["colorsensor_instantiation_block"] = {
  init: function () {
    this.appendDummyInput("class_type")
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(addSeparator(i18n.t("PLACEHOLDER_COLORSENSOR")));
    this.appendValueInput("name")
      .setCheck("String")
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(i18n.t("PLACEHOLDER_NAME") + ":");
    this.appendValueInput("uid")
      .setCheck("Number")
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(i18n.t("PLACEHOLDER_UID") + ":");
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(blockColor("parameters"));
    // this.setTooltip("Instantiate a color sensor");
    this.setTooltip(i18n.t("COLORSENSOR_INSTANTIATE_TOOLTIP"));
    this.setHelpUrl("");
  },
};

Blockly.Blocks["colorsensor_getcolor_block"] = {
  init: function () {
    this.appendDummyInput()
      .setAlign(Blockly.ALIGN_CENTRE)
      //.appendField("get color");
      .appendField(i18n.t("COLORSENSOR_GET_COLOR"))
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(blockColor("sensing"));
    this.setTooltip(i18n.t("COLORSENSOR_GET_COLOR_TOOLTIP"));
    this.setHelpUrl("");
  },
};

Blockly.Blocks["colorsensor_gethexcolor_block"] = {
  init: function () {
    this.appendDummyInput()
      .setAlign(Blockly.ALIGN_CENTRE)
      .appendField(i18n.t("COLORSENSOR_GET_HEX_COLOR"))
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(blockColor("sensing"));
    this.setTooltip(i18n.t("COLORSENSOR_GET_HEX_COLOR_TOOLTIP"));
    this.setHelpUrl("");
  },
};

Blockly.Blocks["colorsensor_getgrayscalecolor_block"] = {
  init: function () {
    this.appendDummyInput()
      .setAlign(Blockly.ALIGN_CENTRE)
      //.appendField("get color");
      .appendField(i18n.t("COLORSENSOR_GET_GRAYSCALE_COLOR"))
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(blockColor("sensing"));
    this.setTooltip(i18n.t("COLORSENSOR_GET_GRAYSCALE_COLOR_TOOLTIP"));
    this.setHelpUrl("");
  },
};

