enum ModelType {
  all = "all",
  Robot = "Robot",
  Object = "Object",
  Primitive = "Primitive",
  Actuator = "Actuator",
  Sensor = "Sensor"
}

export default ModelType;
