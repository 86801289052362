import { TRANSLATIONS } from "../translations_template";

const BLOCKLY_TRANSLATIONS_PT = {
  PLACEHOLDER_LOGIC: "Lógica",
  PLACEHOLDER_LOOPS: "Laços",
  PLACEHOLDER_MATH: "Matemática",
  PLACEHOLDER_TEXT: "Texto",
  PLACEHOLDER_LISTS: "Listas",
  PLACEHOLDER_COLOUR: "Cor",
  PLACEHOLDER_RESOURCES: "Recursos",
  PLACEHOLDER_BASIC: "Básico",
  PLACEHOLDER_ADVANCED: "Avançado",
  PLACEHOLDER_TIME: "Tempo",
  PLACEHOLDER_SCORE: "Pontuação",
  PLACEHOLDER_CONTROLS: "Controles",
  PLACEHOLDER_SIMULATION: "Simulação",
  PLACEHOLDER_ROBOTS: "Robôs",
  PLACEHOLDER_NON_MOBILE: "Não-móveis",
  PLACEHOLDER_MOBILE: "Móveis",
  PLACEHOLDER_SENSORS: "Sensores",
  PLACEHOLDER_SUPERVISOR: "Supervisório",
  PLACEHOLDER_COLORSENSOR: "Sensor de Cor",
  PLACEHOLDER_RANGESENSOR: "Sensor de Proximidade",
  PLACEHOLDER_PHOTOELECTRICSENSOR: "Sensor Fotoelétrico",
  PLACEHOLDER_VARIABLES: "Variáveis",
  PLACEHOLDER_FUNCTIONS: "Funções",
  PLACEHOLDER_ACTUATORS: "Atuadores",
  PLACEHOLDER_CONVEYOR: "Esteira Transportadora",
  PLACEHOLDER_SPAWNER: "Dispensador de Discos",
  PLACEHOLDER_STOPWATCH: "Cronômetro",
  PLACEHOLDER_ACTIONS: "Ações",
  PLACEHOLDER_SENSING: "Sensoriamento",
  PLACEHOLDER_PARAMETERS: "Parâmetros",

  PLACEHOLDER_NAME: "nome",
  PLACEHOLDER_TYPE: "tipo",
  PLACEHOLDER_UID: "UID",
  PLACEHOLDER_INDEX: "índice",
  PLACEHOLDER_IN: "em",
  PLACEHOLDER_TO: "para",
  PLACEHOLDER_JOINT: "junta",
  PLACEHOLDER_ANGLE: "ângulo",
  PLACEHOLDER_ANGLES: "ângulos",
  PLACEHOLDER_UNIT: "unidade",
  PLACEHOLDER_STATE: "estado",
  PLACEHOLDER_LEFTWHEEL: "roda esquerda",
  PLACEHOLDER_RIGHTWHEEL: "roda direita",
  PLACEHOLDER_CENTER: "centro",
  PLACEHOLDER_AXIS: "eixo",
  PLACEHOLDER_VELOCITY: "velocidade",
  PLACEHOLDER_DISTANCE: "distância",
  PLACEHOLDER_ON: "LIGAR",
  PLACEHOLDER_OFF: "DESLIGAR",
  PLACEHOLDER_REVERSE: "REVERSO",
  PLACEHOLDER_FUNCTION: "função",
  PLACEHOLDER_INPUT: "entrada",
  PLACEHOLDER_OUTPUT: "saída",
  PLACEHOLDER_VALUE: "valor",
  PLACEHOLDER_START: "INICIAR",
  PLACEHOLDER_PAUSE: "PAUSAR",
  PLACEHOLDER_STOP: "PARAR",
  PLACEHOLDER_RESET: "REINICIAR",
  PLACEHOLDER_DEGREES: "graus",
  PLACEHOLDER_RADIANS: "radianos",
  PLACEHOLDER_POSITION: "posição",
  PLACEHOLDER_RIGHT: "direita",
  PLACEHOLDER_LEFT: "esquerda",
  PLACEHOLDER_ACTOR: "ator",

  TEXT_INPUT_OUTPUT: "Entrada/Saída",

  MATH_FUNCS: "Funções matemáticas",
  MATH_CONVERT: "{{original}} para {{target}}",
  MATH_DEG_TO_RAD_TOOLTIP: "Converte um ângulo de graus para radianos.",
  MATH_RAD_TO_DEG_TOOLTIP: "Converte um ângulo de radianos para graus.",
  MATH_QUATERNION_TO_EULER: "quatérnio para Euler",
  MATH_QUATERNION_TO_EULER_TOOLTIP: "Converta um quatérnio para um vetor de ângulos de Euler.",
  MATH_EULER_TO_QUATERNION: "Euler para quatérnio",
  MATH_EULER_TO_QUATERNION_TOOLTIP: "Converte um vetor de ângulos de Euler para quatérnio.",
  MATH_CONVERT_LENGTH_METERS_TOOLTIP: "Converte um comprimento em metros para outra unidade.",
  MATH_CONVERT_LENGTH_UNIT_TOOLTIP: "Converte um comprimento em uma dada unidade para outra.",
  MATH_CONVERT_TIME_SECONDS_TOOLTIP: "Converte tempo em segundos para outra unidade.",
  MATH_CONVERT_TIME_UNIT_TOOLTIP: "Converte tempo em uma dada unidade para outra.",
  MATH_CONVERT_VELOCITY_METERSPERSEC_TOOLTIP: "Converte velocidade em m/s para outra unidade.",
  MATH_CONVERT_VELOCITY_UNIT_TOOLTIP: "Converte velocidade em uma dada unidade para outra.",

  RESOURCES_LINEBREAK: "quebra de linha",
  RESOURCES_LINEBREAK_TOOLTIP: "Fazer uma quebra de linha",

  BASIC_DEFINE_ACTOR: "definir ator",
  BASIC_DEFINE_ACTOR_TOOLTIP: "Recebe como parâmetro uma instância de ator e com ela define uma variável de ator.",
  BASIC_GET_ACTOR: "obter este ator",
  BASIC_GET_ACTOR_TOOLTIP: "Retorna este ator.",

  ADVANCED_SET_INPUT: "definir entrada",
  ADVANCED_SET_INPUT_TOOLTIP: "Define um sinal de entrada",
  ADVANCED_GET_INPUT: "obter entrada",
  ADVANCED_GET_INPUT_TOOLTIP: "Retorna um sinal de entrada",
  ADVANCED_SET_OUTPUT: "definir saída",
  ADVANCED_SET_OUTPUT_TOOLTIP: "Define um sinal de saída",
  ADVANCED_GET_INTERFACE: "obter interface",
  ADVANCED_GET_INTERFACE_TOOLTIP: "Retorna uma interface a partir do seu nome.",
  ADVANCED_CREATE_GLOBAL_VAR: "criar variável global",
  ADVANCED_CREATE_GLOBAL_VAR_TOOLTIP: "Cria uma variável global no contexto da simulação.",
  ADVANCED_CREATE_GLOBAL_OUT_INTERFACE: "criar interface global",
  ADVANCED_CREATE_GLOBAL_OUT_INTERFACE_TOOLTIP: "Cria uma interface global de saída no contexto da simulação.",
  ADVANCED_GET_GLOBAL_VALUE: "obter valor global",
  ADVANCED_GET_GLOBAL_VALUE_TOOLTIP: "Retorna o valor da variável global selecionada.",
  ADVANCED_SET_GLOBAL_VALUE: "definir valor global",
  ADVANCED_SET_GLOBAL_VALUE_TOOLTIP: "Define o valor da variável global selecionada.",
  ADVANCED_GET_GLOBAL_INTERFACE_VALUE: "obter interface global",
  ADVANCED_GET_GLOBAL_INTERFACE_VALUE_TOOLTIP: "Retorna o valor da interface global selecionada.",
  ADVANCED_SET_GLOBAL_INTERFACE: "definir interface global",
  ADVANCED_SET_GLOBAL_INTERFACE_TOOLTIP: "Define o valor da interface global selecionada.",

  TIME_WAIT: "esperar",
  TIME_WAIT_TOOLTIP: "Pausa a simulação pelo tempo especificado.",
  TIME_SLEEP: "esperar",
  TIME_SLEEP_TOOLTIP: "Pausa a execução de código pelo tempo especificado.",

  SIMULATION_START: "Começar simulação",
  SIMULATION_PAUSE: "Pausar simulação",
  SIMULATION_STOP: "Parar simulação",
  SIMULATION_GET_STATE: "obter estado da simulação",
  SIMULATION_GET_STATE_TOOLTIP: "Retorna o estado da simulação (0 - Parado, 1 - Rodando, 2 - Pausado).",
  SIMULATION_GET_TIME: "obter tempo de simulação",
  SIMULATION_GET_TIME_TOOLTIP: "Retorna o tempo de simulação em segundos medidos a partir de um sistema interno.",
  SIMULATION_GET_REAL_TIME: "obter tempo real de simulação",
  SIMULATION_GET_REAL_TIME_TOOLTIP: "Retorna o tempo real de simulação em segundos medidos a partir do sistema operacional. Funciona somente com a a simulação em real time.",

  SIMULATION_TIME_LOOP: "Laço de tempo de",
  SIMULATION_TIME_LOOP_TOOLTIP: "Executa um laço durante um determinado tempo de simulação.",

  SIMULATION_CONFIG_SCORE_SYSTEM: "Inicializar sistema de notas",
  SIMULATION_CONFIG_SCORE_SYSTEM_TOOLTIP: "Inicializa e configura o sistema de notas do código.",
  SIMULATION_SET_SCORE: "definir nota para",
  SIMULATION_SET_SCORE_TOOLTIP: "Atribuí valor a nota da simulação.",
  SIMULATION_GET_SCORE: "obter nota",
  SIMULATION_GET_SCORE_TOOLTIP: "Retorna nota atual da simulação.",

  ROBOT2WHEELS_SETWHEELSVELOCITIES: "definir velocidades das rodas",
  ROBOT2WHEELS_SETWHEELSVELOCITIES_TOOLTIP: "Define as velocidades das duas rodas a partir de um vetor [esquerda, direita].",
  ROBOT2WHEELS_SETLEFTWHEELVELOCITY: "definir velocidade da roda esquerda",
  ROBOT2WHEELS_SETLEFTWHEELVELOCITY_TOOLTIP: "Define a velocidade da roda esquerda.",
  ROBOT2WHEELS_SETRIGHTWHEELVELOCITY: "definir velocidade da roda direita",
  ROBOT2WHEELS_SETRIGHTWHEELVELOCITY_TOOLTIP: "Define a velocidade da roda direita.",
  ROBOT2WHEELS_GETWHEELSVELOCITIES: "obter velocidades das rodas",
  ROBOT2WHEELS_GETWHEELSVELOCITIES_TOOLTIP: "Retorna as velocidades das rodas a partir de um vetor [esquerda, direita].",
  ROBOT2WHEELS_GETLEFTWHEELVELOCITY: "obter velocidade da roda esquerda",
  ROBOT2WHEELS_GETLEFTWHEELVELOCITY_TOOLTIP: "Retorna a velocidade da roda esquerda.",
  ROBOT2WHEELS_GETRIGHTWHEELVELOCITY: "obter velocidade da roda direita",
  ROBOT2WHEELS_GETRIGHTWHEELVELOCITY_TOOLTIP: "Retorna a velocidade da roda direita.",
  ROBOT2WHEELS_ROTATE: "rotacionar",
  ROBOT2WHEELS_ROTATE_TOOLTIP: "Rotaciona o robô até um dado ângulo em torno de um dado eixo (roda esquerda, roda direita ou centro do robô) a uma dada velocidade.",
  ROBOT2WHEELS_MOVE_FORWARD: "move-se para frente",
  ROBOT2WHEELS_MOVE_FORWARD_TOOLTIP: "Move o robô pra frente por uma dada distância a uma dada velocidade.",

  LINEFOLLOWERBOT_INSTANTIATE_TOOLTIP: "Instancia um robô LineFollowerBot.",
  LINEFOLLOWERBOT_GET_COLOR_TOOLTIP: "Retorna a leitura de cor (a partir de um vetor [R,G,B]) de um sensor de cor selecionado.",
  LINEFOLLOWERBOT_GET_COLORS: "obter cores",
  LINEFOLLOWERBOT_GET_COLORS_TOOLTIP: "Retorna a leitura de cores (a partir de um vetor [R,G,B]) dos sensores de cor do robô em ordem [esquerdo, centro, direita]",
  LINEFOLLOWERBOT_GET_RANGE_TOOLTIP: 'Retorna a leitura do sensor de proximidade selecionado.',
  LINEFOLLOWERBOT_GET_RANGES: 'obter distâncias',
  LINEFOLLOWERBOT_GET_RANGES_TOOLTIP: "Retorna a leitura dos sensores de proximidade em ordem [esquerdo, centro, direita].",

  NTUBOT_INSTANTIATE_TOOLTIP: "Instancia um robô NTUbot.",

  PIONEER_INSTANTIATE_TOOLTIP: "Instancia um robô Pioneer.",
  PIONEER_GET_RANGES: "obter as distâncias detectadas",
  PIONEER_GET_RANGES_TOOLTIP: "Retorna a leitura dos sensores de proximidade do Pioneer.",

  DOBOT_INSTANTIATE_TOOLTIP: "Instancia um robô Dobot Magician",
  DOBOT_SET_JOINT_ANGLE: "definir ângulo de junta",
  DOBOT_SET_JOINT_ANGLE_TOOLTIP: "Define o ângulo de uma dada junta do robô.",
  DOBOT_SET_JOINT_ANGLES: "definir ângulos de junta",
  DOBOT_SET_JOINT_ANGLES_TOOLTIP: "Define os ângulos de todas as juntas do robô.",
  DOBOT_GET_JOINT_ANGLE: "obter ângulo de junta",
  DOBOT_GET_JOINT_ANGLE_TOOLTIP: "Retorna o ângulo de uma dada junta do robô.",
  DOBOT_GET_JOINT_ANGLES: "obter ângulos de junta",
  DOBOT_GET_JOINT_ANGLES_TOOLTIP: "Retorna os ângulos de todas as juntas do robô na forma de um vetor.",
  DOBOT_SET_SUCTION_CUP_STATE: "definir estado do copo de sucção",
  DOBOT_SET_SUCTION_CUP_STATE_TOOLTIP: "Define se o copo de succção do robô está ativado ou não.",

  RANGESENSOR_INSTANTIATE_TOOLTIP: "Instancia um sensor de proximidade.",
  RANGESENSOR_GET_RANGE: "obter a distância detectada",
  RANGESENSOR_GET_RANGE_TOOLTIP: "Retorna a leitura do sensor de proximidade.",

  PHOTOELECTRICSENSOR_INSTANTIATE_TOOLTIP: "Instancia um sensor fotoelétrico.",
  PHOTOELECTRICSENSOR_GET_RANGE: "obter a distância detectada",
  PHOTOELECTRICSENSOR_GET_RANGE_TOOLTIP: "Retorna a leitura do sensor fotoelétrico.",
  PHOTOELECTRICSENSOR_HAS_DETECTED: "detectou?",
  PHOTOELECTRICSENSOR_HAS_DETECTED_TOOLTIP: "Checa se o sensor detectou algo dentro do seu volume de observação.",

  COLORSENSOR_INSTANTIATE_TOOLTIP: "Instancia um sensor de cor.",
  COLORSENSOR_GET_COLOR: "obter cor (RGB)",
  COLORSENSOR_GET_HEX_COLOR: "obter cor (HEX)",
  COLORSENSOR_GET_COLOR_TOOLTIP: "Retorna a leitura de cor (um vetor [R,G,B]) de um sensor de cor.",
  COLORSENSOR_GET_HEX_COLOR_TOOLTIP: "Retorna a leitura de cor (um número hexadecimal) de um sensor de cor.",
  COLORSENSOR_GET_GRAYSCALE_COLOR: "obter valor médio de cor",
  COLORSENSOR_GET_GRAYSCALE_COLOR_TOOLTIP: "Retorna a leitura de cor em escala de cinza, a partir de um valor do sensor de cor variando de 0 - 255.",

  CONVEYOR_INSTANTIATE_TOOLTIP: "Instancia uma esteira transportadora.",
  CONVEYOR_SET_STATE: "definir estado da esteira",
  CONVEYOR_SET_STATE_TOOLTIP: "Define se a esteira está ligada ou desligada.",

  SPAWNER_INSTANTIATE_TOOLTIP: "Instancia um dispensador de discos.",
  SPAWNER_SET_STATE: "definir estado do dispensador",
  SPAWNER_SET_STATE_TOOLTIP: "Define se o dispensador de discos está ligado ou desligado.",

  STOPWATCH_INSTANTIATE_TOOLTIP: "Instancia um cronômetro.",
  STOPWATCH_SET_STATE: "define estado do cronômetro",
  STOPWATCH_SET_STATE_TOOLTIP: "Define estado do cronômetro.",
  STOPWATCH_GET_TIME: "obter tempo",
  STOPWATCH_GET_TIME_TOOLTIP: "Retorna tempo de simulação em segundos.",
  STOPWATCH_GET_TIME_FORMAT: "obter tempo em texto",
  STOPWATCH_GET_TIME_FORMAT_TOOLTIP: "Retorna tempo de simulação formatado em HH:MM:SS.sss",

  SUPERVISOR_INSTANTIATE_TOOLTIP: "Instancia um supervisório",
  SUPERVISOR_GET_ACTOR: "obter ator",
  SUPERVISOR_GET_ACTOR_TOOLTIP: "Retorna um ator pelo nome",
  SUPERVISOR_CONNECT_INTERFACES: "conectar interfaces",
  SUPERVISOR_CONNECT_INTERFACES_TOOLTIP: "Conecta uma interface de entrada a uma interface de saída.",

  TEXT_ACTOR_TOOLTIP: "Retorna o nome de um ator na cena",
};

export const TRANSLATIONS_PT: TRANSLATIONS = {
  commons: {
    Language: "Linguagem",
    submission: "submissão",
    copy: "cópia",
    event: "evento",
    member: "membro",
    members: "membro(s)",
    group: "grupo",
    Leader: "Líder",
    Optional: "Opcional",
    defaultName_male: "Meu {{projectType}}",
    defaultName_female: "Minha {{projectType}}",
    Loading: "Carregando ...",
    Unnamed: "Sem nome",
    Untitled: "Sem título",
    Status: "Status",
    Information: "Informação",
    image: "imagem",
    userData: {
      username: "Usuário",
      email: "email",
      Email: "Email",
      fullname: "Nome completo",
      phone: "Telefone",
      zipcode: "CPF",
    },
    userAddress: {
      zipcode: "CEP",
      address1: "Endereço",
      address2: "Complemento",
      city: "Cidade",
      state: "Estado",
      country: "País"
    },
    institution: {
      institution: "Instituição",
      unit: "Unidade",
      taxIdNumber: "CNJP",
      segment: "Segmento"
    },
    msgs: {
      modal: {
        deleteEventWarning: "Ao completar esta ação, o evento será deletado e não poderá mais ser acessado por ninguém. Tem certeza que quer continuar?",
        deleteProjectWarning: "Ao completar esta ação, o projeto será deletado e não poderá ser recuperado. Tem certeza que quer continuar?",
        deleteSubmissionWarning: "Ao completar esta ação, a submissão será deletada e não será mais contabilizada na sua avaliação. Tem certeza que quer continuar?",
        deleteTemplateWarning: "Ao completar esta ação, o template será deletado e não aparecerá mais para os participantes deste evento. Tem certeza que quer continuar?",
        archiveEventWarning: "Enquanto o evento estiver arquivado, ele não aparecerá na sua lista de eventos. Tem certeza de que deseja continuar?",
        unarchiveEventWarning: "Ao desarquivar o evento, ele voltará à sua lista de ventos. Tem certeza de que deseja continuar?",
        sendTemplateWarning: "Ao completar esta ação, o template estará disponível para os participantes deste evento para que eles possam criar experimentos a partir dele. Tem certeza de que deseja continuar?",
        submitExperimentWarning: "Ao completar esta ação, o experimento será submetido para avaliação. Tem certeza de que quer continuar?"
      },
      openedUnity: "Simulação carregada!",
      openingUnity: "Carregando simulação ...",
      emptyList: "Nenhum item para exibir",
      create: "Criar {{target}}",
      created_male: "{{target}} criado com sucesso!",
      created_female: "{{target}} criada com sucesso!",
      creating: "Criando {{target}} ...",
      edit: "Editar {{target}}",
      editing: "Editando {{target}} ...",
      edited_male: "{{target}} editado com sucesso!",
      edited_female: "{{target}} editada com sucesso!",
      delete: "Deletar {{target}}",
      deleted_male: "{{target}} deletado com sucesso!",
      deleted_female: "{{target}} deletada com sucesso!",
      deleting: "Deletando {{target}} ...",
      update: "Atualizar {{project}}",
      updating: "Atualizando {{project}} ...",
      updated_male: "{{project}} atualizado com sucesso!",
      updated_female: "{{project}} atualizada com sucesso!",
      copy: "Copiar {{project}}",
      copying: "Copiando {{project}} ...",
      copied: "{{project}} copiado com sucesso!",
      archive: "Arquivar {{}}",
      archiving: "Arquivando {{}} ...",
      archived: "{{}} arquivado!",
      unarchive: "Desarquivar {{}}",
      unarchiving: "Desarquivando {{}} ...",
      unarchived: "{{}} desarquivado!",
      share: "Compartilhar {{item}}",
      sharing: "Compartilhando {{item}} ...",
      shared: "{{item}} compartilhado!",
      searching: "Buscando {{}} ...",
      found: "{{}} encontrado!",
      noTemplatesToShow: "Não há templates para mostrar"
    },
    actions: {
      backToHome: "Voltar ao início",
      confirm: "Confirmar",
      cancel: "Cancelar",
      open: "Abrir",
      close: "Fechar",
      export: "Exportar",
      load: "Carregar",
      loading: "Carregando",
      loaded: "Carregado",
      create: "Criar",
      creating: "Criando",
      created: "Criado",
      editing: "Editando",
      edit: "Editar",
      edited: "Editado",
      updating: "Atualizando",
      update: "Atualizar",
      updated: "Atualizado",
      delete: "Deletar",
      subscribe: "Inscrever-se",
      publish: "Publicar",
      finish: "Encerrar",
      next: "Próximo",
      back: "Voltar",
      send: "Enviar",
      resend: "Reenviar",
      sendingEmail: "Enviando email",
      emailSent: "Email enviado",
      copied: "Copiado",
      search: "Pesquisar",
    },
    password: {
      placeholder: "senha",
      placeholder_uppercase: "Senha",
      confirm: "Confirmar senha",
      new: "Nova senha",
    },
    request: {
      placeholder: "requisição",
      successful: "{{request}} bem-sucedido(a)!",
      failed: "{{request}} não pôde ser realizado(a)!",
      pending: "Realizando {{request}} ...",
    },
    projectInfo: {
      projectName: "Nome do projeto",
      description: "Descrição",
      creationDate: "Data de criação",
      lastModified: "Última modificação",
      models: "Modelos"
    },
    projectTypes: {
      project: "projeto",
      classroom: "sala de aula",
      tournament: "torneio",
      challenge: "desafio",
      tutorial: "tutorial",
      template: "template",
    },
    projectRoles: {
      project: "projeto",
      experiment: "experimento",
      draft: "rascunho"
    },
    LicenseType: {
      Root: "Root",
      Admin: "Administrador",
      Manager: "Gerente",
      Professor: "Professor",
      Organizer: "Organizador",
      Instructor: "Instrutor",
      Student: "Estudante",
      Member: "Membro",
      Individual: "Individual",
      Guest: "Visitante"
    },
    eventStatus: {
      UnderConstruction: "Em construção",
      WaitingApproval: "Aguardando aprovação",
      Approved: "Aprovado",
      Published: "Publicado",
      InProgress: "Em andamento",
      Closed: "Encerrado",
      WaitingScores: "Aguardando Avaliação",
      Canceled: "Cancelado"
    },
    eventTypes: {
      classroom: "sala de aula",
      tournament: "torneio",
      challenge: "desafio",
    },
    resourceTypes: {
      DobotV3: "Dobot",
      NTUbot: "NTUbot",
      PioneerV2: "Pioneer",
      ConveyorM: "Esteira Transportadora",
      Spawner2: "Dispensador",
      Stopwatch: "Cronômetro",
      colorSensor: "Sensor de Cor",
      rangeSensor: "Sensor de Proximidade",
      GreenBox: "Caixa Verde",
      RedBox: "Caixa Vermelha",
      Plant: "Planta",
      Chair: "Cadeira",
      Table: "Mesa",
      Path: "Trajeto",
      Path2: "Trajeto 2",
      Sphere: "Esfera",
      Cuboid: "Cubo",
      Cylinder: "Cilindro",
      Supervisor: "Supervisório",
      photoelectricSensor: "Sensor Fotoelétrico",
      LineFollowerBot: "Seguidor de Linha",
      Border: "Borda",
      Maze: "Labirinto",
      cameraSensor: "Câmera",
      Plane: "Plano",
      Disc: "Disco",
      Cone: "Cone",
      Capsule: "Capsula",
      MediumConveyorBelt: "Cinta Transportadora",
    },
    modelTypes: {
      Actuator: "Atuadores",
      Sensor: "Sensores",
      Object: "Objetos",
      Primitive: "Primitivas",
      Robot: "Robôs"
    },
    modelMotionProps: {
      static: "Estáticos",
      dynamic: "Dinâmicos"
    },
    time: {
      secondsAgo: "{{seconds}} segundos atrás",
      minutesAgo: "{{minutes}} minutos atrás",
      hoursAgo: "{{hours}} horas atrás",
      daysAgo: "{{days}} dias atrás",
      monthsAgo: "{{months}} meses atrás",
      yearsAgo: "{{years}} anos atrás",
    },
    timeUnit: {
      days: "dia(s)",
      months: "mes(es)",
      years: "ano(s)"
    },
    paymentPeriod: {
      daily: "dia",
      monthly: "mês",
      yearly: "ano",
    },
    form: {
      name: "nome",
      description: "descrição",
      initials: "iniciais",
      code: "código",
      type: "tipo"
    },
    unit: {
      professors: "professores",
      organizers: "organizadores",
      instructors: "instrutores",
      type: {
        head: "principal",
        branch: "filial",
        franchise: "franquia",
        department: "departamento",
        campus: "campus"
      }
    },
    units: "unidades"
  },
  components: {
    acceptProjectCopy: {
      header: "Aceitar cópia de projeto?",
      instructions: "{{user}} quer enviar uma cópia do projeto {{projectName}} para você. Deseja aceitar?"
    },
    blockly: {
      blocklyScreen: {
        loadingCode: "Carregando código do modelo",
        failedToLoadCode: "Falha ao carregar o código do modelo.",
        saveCode: "Salvar código",
        resetCode: "Resetar código",
        seeCode: "Código Python",
        downloadScreenshot: "Baixar captura de tela",
        resetModal: {
          message: "Ao relizar esta ação, todo o código será perdido e não poderá ser recuperado. Tem certeza de que quer continuar?"
        }
      },
    },
    // changePassword: {
    //   header: "Alterar minha senha",
    //   pending: "Alterando senha ...",
    //   successful: "Senha alterada com sucesso!",
    //   failed: "Alteração de senha falhou!"
    // },
    commons: {
      uploadFile: {
        removeFile: "Remover arquivo",
        sendFile: "Enviar arquivo do projeto"
      },
      uploadImage: {
        image: "imagem",
        tooltip: "Enviar imagem",
        successful: "Imagem carregada com sucesso!",
        failed: "Image não pode ser carregada!",
        pending: "A imagem está sendo carregada ...",
        sendNew: "Enviar nova foto de perfil",
        remove: "Remover foto de perfil"
      },
      userSelection: {
        searchUsersTitle: "Selecione usuários",
        selectedUsersTitle: "Usuários selecionados",
        usernamePlaceholder: "Nome de usuário ou email"
      }
    },
    credits: {
      header: "Créditos"
    },
    events: {
      actions: {
        updateEvent: "Atualizar evento",
        updatingEvent: "Atualizando evento ...",
        updatedEvent: "Evento atualizado com sucesso!",
        createEvent: "Criar evento",
        copyEvent: "Copiar evento",
        creatingEvent: "Criando evento ...",
        createdEvent: "Evento criado com sucesso!",
        classroom: {
          updateEvent: "Atualizar sala de aula",
          updatingEvent: "Atualizando sala de aula...",
          updatedEvent: "Sala de aula atualizada!",
          createEvent: "Criar sala de aula",
          copyEvent: "Copiar sala de aula",
          creatingEvent: "Criando sala de aula...",
          createdEvent: "Sala de aula criada com sucesso!"
        },
        tournament: {
          updateEvent: "Atualizar torneio",
          updatingEvent: "Atualizando torneio...",
          updatedEvent: "Torneio atualizado!",
          createEvent: "Criar torneio",
          copyEvent: "Copiar torneio",
          creatingEvent: "Criando torneio...",
          createdEvent: "Torneio criado com sucesso!"
        }
      },
      Event: {
        commons: {
          registrationDates: {
            header: "Datas de inscrição",
            start: "Data inicial de inscrição",
            end: "Data final de inscrição",
          },
          submissionDates: {
            header: "Datas de submissão",
            start: "Data inicial de submissão",
            end: "Data final de submissão",
          },
          eventDates: {
            start: "Início do evento",
            end: "Prazo final do evento"
          },
          classroomDates: {
            header: "Datas",
            release: "Data de liberação",
            end: "Data de encerramento"
          }
        },
        eventForm: {
          tournamentName: "Nome do torneio",
          classroomName: "Nome da sala de aula",
          tournamentType: "Tipo de torneio",
          eventVisibility: {
            open: "Aberto",
            closed: "Fechado"
          },
          shortDescription: "Descrição curta",
          description: "Descrição",
          teamInfo: {
            header: "Informação de time",
            zeroIfUnlimitedValue: "Coloque 0 se o valor for ilimitado.",
            maxTeamsAllowed: "Máximo de times permitidos",
            maxUsersPerTeam: "Máximo de usuários por time",
            maxSubmissions: "Máximo de submissões"
          },
          classroomSettings: {
            header: "Configurações de sala de aula",
            maxStudentsAllowed: "Máximo de alunos permitidos"
          },
          payment: {
            header: "Pagamento",
            value: "Valor",
            currency: "Moeda",
            paymentType: "Tipo de pagamento",
            paymentTypes: {
              Team: "Time",
              TeamMember: "Membro de Time",
              Robot: "Robô"
            },
            currencyNames: {
              real: "Real",
              dolar: "Dólar"
            },
          },
          rules: {
            placeholder: "Regras",
            viewMarkdownTip: "Visualizar regras em Markdown",
            viewFormatedTip: "Visualizar regras formatadas"
          },
          invites: {
            header: "Convidados"
          }
        },
        eventFormSchema: {
          beforeRegistrationStart: "Deve ser anterior à data de início de inscrições",
          beforeRegistrationEnd: "Deve ser anterior à data de encerramento de inscrições",
          beforeSubmissionsStart: "Deve ser anterior à data de início de entrega",
          beforeSubmissionsEnd: "Deve ser anterior à data de encerramento de entrega",
          beforeRelease: "Deve ser anterior à data de liberação",
          beforeEnd: "Deve ser anterior à data de encerramento",
          afterRegistrationStart: "Deve ser posterior à data de início de inscrições",
          afterRegistrationEnd: "Deve ser posterior à data de encerramento de inscrições",
          afterSubmissionsStart: "Deve ser posterior à data de início de entrega",
          afterRelease: "Deve ser posterior à data de liberação",
          afterEnd: "Deve ser posterior à data de encerramento",
          afterToday: "Deve ser posterior ao início do dia de hoje",
          atLeastOneMember: "Cada grupo deve ter pelo menos um membro",
          charsMissing: "Faltam {{number}} caracteres!",
          charsInExcess: "Excedeu em {{number}} caracteres!"
        },
        sharedInstitutions: {
          title: "Compartilhar evento com instituições",
          description: "Selecione as instituições que você deseja compartilhar seu evento.",
          searchTitle: "Selecione instituições",
          selectedTitle: "Instituições selecionadas",
          searchPlaceholder: "Nome da instituição",
          updating: "Compartilhando evento com as instituições selecionadas...",
          complete: "Evento compartilhado com as instituições selecionadas!",
        }
      },

      group: {
        groupTab: {
          editGroup: "Editar grupo",
          membersHeader: "Membros",
          noUsersInGroup: "Nenhum usuario no grupo",
          unsubscribeGroup: "Desinscrever grupo",
          unsubscribingGroup: "Desinscrevendo grupo ...",
          unsubscribedGroup: "Grupo desinscrito com sucesso!",
          quitGroup: "Sair do grupo",
          quitingGroup: "Saindo do grupo ...",
          quited: "Você deixou o grupo!",
          removeMember: "Remover membro",
          removingMember: "Removendo membro ...",
          removedMember: "Membro removido com sucesso!",
          addMember: "Adicionar membro",
          addingMember: "Adicionando membro ...",
          addedMember: "Membro adicionado com sucesso!",
          unsubscribeGroupWarning: "Ao completar esta ação, seu grupo será desinscrito, todas as submissões e projetos associados a ele serão deletados e todos os seus membros perderão o acesso ao evento.",
          unsubscribeMemberWarning: "Ao completar esta ação, o usuário {{member}} será removido do grupo e perderá o acesso ao evento.",
          unsubscribeSelfWarning: "Ao completar esta ação, você sairá do grupo e perderá o acesso ao evento.",
          areYouSure: "Tem certeza de que quer continuar?"
        },
        updateGroupForm: {
          groupName: "Nome do grupo",
        }
      },
      groupInfoTab: {
        noName: "Sem nome",
        showSubmissions: "Exibir submissões do grupo",
        seeSubmissions: "Ver submissões",
        noGroupsToShow: "Não há grupos para mostrar",
        noMembersToShow: "Não há membros para mostrar",
        creationDate: "Data de criação:",
        Location: "Localização",
        membersHeader: "Membros",
        submissionsHeader: "Submissões"
      },
      submission: {
        rankingTab: {
          tableHeaders: {
            Position: "Posição",
            GroupName: "Nome do grupo",
            Evaluation: "Avaliação",
            SubmissionDate: "Data de submissão"
          },
          noRankingToShow: "Não há classificação para mostrar"
        },
        submissionTab: {
          submissionStatus: {
            Review: "Em revisão",
            NotGraded: "Aguardando Avaliação",
            Graded: "Avaliado",
            Submitted: "Submetido"
          },
          startingAutoEvaluation: "Iniciando avaliação automática",
          startedAutoEvaluation: "Avaliação automática iniciada",
          tableHeaders: {
            ID: "ID de submissão",
            projectName: "Nome do projeto",
            submittedBy: "Submetido por",
            submissionDate: "Data de envio",
            status: "Status",
            evaluation: "Avaliação",
            actions: "Ações"
          },
          seeSubmission: "Visualizar submissão",
          revaluateSubmission: "Reavaliar submissão",
          noSubmissionToShow: "Não há envios para mostrar"
        },
      },
      templates: {
        templatesTab: {
          tableHeaders: {
            ID: "ID do template",
            templateName: "Nome do template",
            submissionDate: "Data de envio",
            actions: "Ações"
          },
          noTemplatesToShow: "Não há templates para mostrar"
        },
      },
      eventInfo: {
        subscribingEvent: "Inscrevendo-se no evento ...",
        subscribedEvent: "Inscrito no evento com sucesso!",
        publishingEvent: "Publicando evento ...",
        publishedEvent: "Evento publicado!",
        finishingEvent: "Encerrando evento ...",
        finishedEvent: "Evento encerrado!",
        acceptRules_male: "Eu li e concordo com as regras do {{event}}.",
        acceptLeaderRole_male: "Eu aceito criar e ser o líder de um novo grupo do {{event}}.",
        acceptRules_female: "Eu li e concordo com as regras da {{event}}.",
        acceptLeaderRole_female: "Eu aceito criar e ser o líder de um novo grupo da {{event}}.",
        subscriptionStatus: {
          subscribed: "Inscrito",
          eventFull: "Cheio",
          outOfDeadline: "Fora do prazo de inscrições"
        },
        navBarTabs: {
          General: "Geral",
          Drafts: "Rascunhos",
          Experiments: "Experimentos",
          Templates: "Templates",
          MyGroup: "Meu grupo",
          Groups: "Grupos",
          Submissions: "Envios",
          Ranking: "Classificação",
          Invites: "Convidados"
        }
      },
      eventList: {
        tabs: {
          MySubscriptions: "Minhas inscrições",
          Open: "Abertos",
          Closed: "Fechados",
          MyTournaments: "Meus torneios",
          MyClassrooms: "Minhas salas de aula"
        },
        hideArchived: "Ocultar arquivados",
        showArchived: "Exibir arquivados",
        archivedHeader: "Arquivados"
      },
      invites: {
        editInvitedUsers: "Editar convidados",
      },
      copyEvent: {
        description: "Selecione um dos eventos da sua unidade para fazer uma cópia."
      }
    },
    home: {
      homeHeader: {
        reloadPage: "Recarregar página"
      },
      sidebar: {
        logout: "Sair",
        config: "Configurações",
        comingSoon: "Em breve",
      }
    },
    myAccount: {
      header: "Minha conta",
      userInfo: {
        header: "Informações de usuário",
        description: "Gerenciar seus dados de usuário, como nome, email, telefone e CEP",
        updateUser: {
          pending: "Atualizando usuário...",
          successful: "Usuário atualizado!",
          failed: "Erro ao atualizar usuário",
        }
      },
      addresses: {
        header: "Meus endereços",
        description: "Adicionar ou remover endereços",
        addAddress: {
          added: "Endereço adicionado!",
          pending: "Adicionando endereço ..."
        },
        newAddress: "Novo endereço"
      },
      security: {
        header: "Segurança",
        description: "Gerenciar suas configurações de segurança, como senhas.",
        changePassword: {
          header: "Alterar minha senha",
          pending: "Alterando senha ...",
          successful: "Senha alterada com sucesso!",
          failed: "Alteração de senha falhou!"
        }
      },
      myPlans: {
        header: "Planos e licenças",
        description: "Visualizar informações da sua licença e do seu plano.",
        license: {
          header: "Licença"
        },
        plan: {
          header: "Plano",
          customPlan: "Plano customizado",
          active: "Ativo",
          inactive: "Inativo",
          features: {
            header: "Recursos",
            seats: "assento(s)",
            licensesOf: "licença(s) de",
            youHaveALicenseOf: "Você possui uma licença de {{license}}"
          },
          payments: {
            header: "Pagamentos",
            cost: "Custo",
            expiresOn: "Expira em",
            renewedOn: "Renovado em",
            paymentMethod: "Método de pagamento",
            paymentHistory: "Histórico de pagamento",
            change: "Alterar",
            trialPeriodWarning: "Este plano está em um período de testes de {{duration}} {{unit}}"
          },
          paymentHistory: {
            empty: "Não há histórico para mostrar",
            table: {
              headers: {
                paymentDate: "Data de pagamento",
                paidBy: "Pago por:",
                paymentMethod: "Forma de pagamento",
                value: "Valor",
                discount: "Desconto",
                total: "Total"
              }
            }
          }
        }
      }
    },
    login: {
      header: "Login",
      instructions: "Faça o login para acessar o simulador.",
      pending: "Fazendo login  ...",
      successful: "Login bem-sucedido!",
      failed: "Login falhou!",
      noAccount: "Não tem conta?",
      remindMe: "Lembrar neste computador",
      forgotPassword: "Esqueci a senha",
      createAccount: "Criar uma conta",
      signIn: "Entrar",
    },
    passwordRecovery: {
      OtpPanel: {
        OtpButtons: {},
        codeExpires: "Codigo expira em {{sec}} segundos",
        resendOtp: "Reenviar código",
      },
      CodeInputForm: {
        otpCheck: {
          successful: "Código validado com sucesso!",
          failed: "Validação de código falhou!",
          pending: "Verificando código ...",
        },
      },
      CodeInputSchema: {
        codeRequired: "O código é obrigatório!"
      },
      CodeInputScreen: {
        submitInstructions: "Por favor, digite o código enviado para seu email para fazer o reset da senha.",
        recoveryHeader: "Recuperação de senha",
      },
      SendCodeForm: {
        sendCode: "Enviar código",
      },
      SendCodeSchema: {},
      SendCodeScreen: {
        sendInstructions: "Por favor, entre com o email cadastrado para que possamos te enviar as instuções para reset da senha.",
        forgotPwd: "Esqueceu a senha?",
      },
      commons: {
        otpSend: {
          successful: "Código enviado com sucesso para seu email!",
          failed: "Código não pôde ser enviado pra seu email!",
          pending: "Enviando código ...",
        },
      }
    },
    projectManager: {},
    projectsSelection: {
      project: {
        actions: {
          create: "Criar {{project}}",
          creating: "Criando {{project}} ...",
          created: "{{project}} criado com sucesso!",
          edit: "Atualizar {{project}}",
          editing: "Atualizando {{project}} ...",
          edited: "{{project}} atualizado com sucesso!",
        },
        projectForm: {
          templates_header: "Templates",
          templatesInstructions: "Selecione um template para a criação do projeto",
          templatesInstructions2: "Um template permite que os usuarios trabalhem em uma cena pré-configurada com um conjunto predefinido de recursos disponíveis.",
          resourcesInstructions: "Crie uma cena vazia com um conjunto personalizado de recursos disponíveis.",
          resourcesInstructions2: "Clique no botão {{buttonLabel}} e, no modal aberto, selecione os modelos que deseja incluir.",
          copyInstructions: "Cole aqui o ID do projeto que você deseja copiar:",
          howToCopyProjectById: "Como copiar um projeto por ID ?",
          selectResources: "Selecione os recursos desejados ...",
          creationModes: {
            Template: "Template",
            Resource: "Recurso",
            Copy: "Cópia"
          },
          description: "Descrição",
          projectName: "Nome do projeto",
          projectId: "ID do projeto",
          fileDefaultName: "1 arquivo",
          addNewResources: "Adicionar novos recursos",
          copySteps: {
            goToProjectsList: "Vá até a lista de projetos/experimentos onde está o projeto que você deseja copiar.",
            clickInfoButton: "Clique no botão de informações do projeto a ser copiado.",
            clickCopy: "No modal de informações aberto, clique no botão de copiar ao lado do ID de projeto.",
            pasteId: "Com o ID de projeto copiado, acesse a tela de criação de projetos, na aba de Cópia, e cole-o no campo indicado.",
            createProject: "Se o ID colocado for válido, as informações do projeto como nome, descrição e thumbnail serão carregadas."
          }
        },
        resourceListItem: {
          available: "Disponíveis",
          used: "Usados"
        },
        resourceSelectionModal: {
          instructions: "Dentre os modelos abaixo, escolha aqueles que deseja adicionar ao projeto para apareçam na lista {{listName}}. Nesta lista, selecione a quantidade de cada modelo que deve estar disponível  e clique em {{button}}.",
          addResources: "Adicionar recursos",
          selectedResources: "Recursos selecionados",
          exempleSearchBar: "Ex: Sensor de cor",
          allCategories: "Todas as categorias",
          seeLegends: "Ver legendas",
          modelTypesSlide: {
            header: "Ícones de tipo de modelo",
            subtitles: "Estes ícones indicam em qual categoria o modelo se insere: robôs, atuadores (dispositivos que produzem movimento), sensores (dispositivos que captam sinais/estímulos), objetos ou primitivas (sólidos geométricos não-estáticos)"
          },
          motionPropsSlide: {
            header: "Ícones de propriedades de mobilidade",
            subtitles: "Estes ícones indicam se o objeto em questão é dinâmico (isto é, interage com a física da simulação) ou estático"
          }
        },
        aditionalFilters: {
          motionPropsHeader: "Propriedades"
        }
      },
      projectInfoModal: {
        projectInfoModal: {
          emailNotFound: "Email não encontrado!",
          created: "criou",
          createdIn: "criou em {{}}",
          projectId: "ID do projeto"
        }
      },
      projectListItem: {
        createdBy: "Criado por",
      },
      projectList: {
        submit: "Submeter experimento",
        submitting: "Submetendo experimento ...",
        submitted: "Experimento submetido!",
        submitExperimentTip: "Submeter experimento para avaliação",
        outOfSubmissionDeadline: "Fora do prazo de submissões",
        sendTemplate: "Enviar template",
        sendingTemplate: "Enviando template ...",
        sentTemplate: "Template enviado com sucesso!",
        myProjectsHeader: "Meus Projetos",
        myGroupProjectsHeader: "Projetos do meu grupo"
      }
    },
    registration: {
      header: "Cadastro",
      instructions: "Complete o cadastro para poder acessar a plataforma.",
      register: "Registrar",
      passwordInstructions: "Deve conter 8 caracteres, sendo pelo menos uma letra maiúscula, uma minúscula, um número e um caracter especial",
      pending: "Criando usuário ...",
      successful: "Usuário cadastrado com sucesso!",
      failed: "Usuário não pôde ser cadastrado!",
      selectInstitution: {
        instruction: "Selecione sua instituição e preencha o campo com seu email. Um email será enviado para você para completar seu cadastro institucional.",
        instructionEmailSent: "Um email de confirmação foi enviado. Faça a confirmação para continuar o cadastro.",
        invalidEmail: "O email digitado não possui o padrao de email da instituição selecionada"
      }
    },
    systemDefinitions: {
      header: "Definições do sistema",
    },

    project: {
      header: "Projetos"
    },
    classroom: {
      header: "Salas de aula"
    },
    tournament: {
      header: "Torneios"
    },
    challenge: {
      header: "Desafios"
    },
    tutorial: {
      header: "Tutoriais"
    },
    template: {
      header: "Templates"
    },
    myInstitution: {
      header: "Minha instituição",
      unitForm: {
        createHeader: "Nova unidade",
        updateHeader: "Atualizar unidade",
      },
      organizeEventsModal: {
        tip: "Organizar lista de materiais",
        header: "Materiais da unidade",
        description: "Selecione os materiais da sua instituição que podem ser utilizados pela unidade {{unit}}",
        updating: "Atualizando lista de materiais da unidade...",
        success: "Lista de materiais da unidade atualizada com sucesso!"
      },
      organizeUsersRolesModal: {
        tip:"Organizar membros",
        header: "Organizar membros da unidade",
        description: "Lista dos membros da unidade {{unit}}.",
        updating: "Atualizando membros da unidade...",
        success: "Membros da unidade atualizados com sucesso!"
      }
    }
  },
  getUser: {
    pending: "Buscando dados do usuário...",
    successful: "Usuário encontrado!",
  },
  //blocklyMsg: BlocklyMsg("{{msg}}"),
  error: {
    invalid_format: "O formato de {{field}} é inválido",
    required: "Campo obrigatório",
    minCharsRequired: "Deve conter ao menos {{min}} caracteres",
    uppercaseRequired: "Deve conter ao menos uma letra maiúscula",
    lowercaseRequired: "Deve conter ao menos uma letra minúscula",
    numberRequired: "Deve conter ao menos um número",
    specialRequired: "Deve conter ao menos um caractere especial",
    passwordsIdentical: "As senhas devem ser idênticas",
    api: {
      recaptchaNotAvailable: "Recaptcha não está disponível",
      userNotFound: "Usuário não encontrado!",
      userNameAlreadyExists: "Nome de usuário já existe!",
      emailNotAllowed: "Email não permitido para cadastro!",
      userAlreadyRegistered: "Usuário já registrado!",
      emailAlreadyExists: "Email já existe!",
      incorrectPassword: "Usuário e senha não coincidem!",
      usedOTP: "Usuário não solicitou recuperação de senha ou já usou o código de recuperação.",
      wrongOTP: "Código incorreto.",
      expiredOTP: "O código expirou.",
      authError: "Erro de autentição.",
      expiredSession: "Sessão expirada! Por favor, faça login de novo.",
      serverError: "Erro de servidor.",
      databaseError: "Erro no banco de dados.",
      serverConnectionError: "A conexão com o servidor falhou. Tente novamente mais tarde.",
      unknownError: "Erro desconhecido.",
      unknownTag: "Erro desconhecido: \"{{tag}}\".",
      formError: "Um ou mais campos foram preenchidos incorretamente.",
      simulationLoading: "Erro ao carregar simulação. Tente novamente mais tarde.",
      groupLoading: "Erro ao carregar grupo. Tente novamente mais tarde.",
      groupEditing: "Erro ao editar grupo.",
      groupsNotFound: "Nenhum grupo encontrado!",
      projectIdNotValid: "ID de projeto inválido",
      projectNotFound: "Projeto não encontrado!",
      publicEventSameName: "Um evento com o mesmo nome já existe!",
      maxSubmissionsLimit: "Número máximo de submissões atingido!",
      eventNotFound: "Evento não encontrado!",
      outOfDeadline: "Fora do prazo!",
      licenseExpired: "Sua licença expirou. Por favor, renove sua inscrição para continuar simulando.",
      userNotAllowed: "Você não tem permissão para realizar esta ação.",
      invalidRecaptcha: "Recaptcha inválido."
    },
    front:{
      noProjectSelected: "Nenhum projeto selecionado!",
      imageUploading: "Erro ao carregar imagem!",
      fileUploading: "Erro ao carregar arquivo!",
      contentLoading: "Erro ao carregar conteúdo!"
    },
    unity: {
      timeoutError: "O servidor parou de responder. Tente novamente mais tarde.",
      lostRosConnection: "Perda de conexão com o ROS. Tente novamente mais tarde.",
      loadSimulationError: "Erro ao carregar a simulação. Tente novamente mais tarde."
    }
  },
  ...BLOCKLY_TRANSLATIONS_PT
};
