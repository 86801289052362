import { blockColor } from "../../BlockProperties";
import "./block";
import "./pythonCode";
import "./customField";

import { getSpaceUntilMaxLength } from "@testing-library/user-event/dist/utils";
import i18n from "../../../translations/i18n";

function GetSpawnerToolbox(actorId: string){

  let spawnerToolbox = {
    kind: "category",
    name: i18n.t("PLACEHOLDER_SPAWNER"),
    expanded: true,
    // colour: blockColor("spawner"),
    contents: [
      {
        kind: "category",
        name: i18n.t("PLACEHOLDER_ACTIONS"),
        colour: blockColor("actions"),
        contents: [
          {
            kind: "block",
            type:"variables_call",
            fields:{
                "VAR":{
                    type: "field_variable",
                    id: actorId
                }
            },
            inputs:{
                VALUE:{
                    block: {
                        type: "spawner_setstate_block",
                        fields:{
                          state: 1
                        },
                    }
                }
            }
          },
        ],
      },
      {
        kind: "category",
        name: i18n.t("PLACEHOLDER_PARAMETERS"),
        colour: blockColor("parameters"),
        contents: [
          {
            kind: "block",
            type: "spawner_instantiation_block",
            inputs:{
              name:{
                  block: {
                      type: "text",
                      fields: {
                          TEXT: "Spawner"
                      }
                  }
              },
              uid:{
                  block: {
                      type: "math_number",
                      fields: {
                          NUM: 1
                      }
                  }
              }
            }
          },
        ],
      },
    ],
  };

  return spawnerToolbox;

}

export default GetSpawnerToolbox;
