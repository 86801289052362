import BlocklyPY from "blockly/python";

BlocklyPY["supervisor_instantiation_block"] = function(block){
  // var name = BlocklyPY.valueToCode(block, "name", BlocklyPY.ORDER_ATOMIC);
  // var code = `Supervisor(${name},actors)`;
  var code = `Supervisor("Supervisor", actors)`;
  return [code, BlocklyPY.ORDER_NONE];
};

BlocklyPY["supervisor_getactor_block"] = function (block) {
  var actor_name = BlocklyPY.valueToCode(
    block,
    "actor_name",
    BlocklyPY.ORDER_ATOMIC
  );
  var code = 'getActor('+ actor_name + ')';

  let jsonCode = {
    "code": code,
    "type": "Any"
  };

  return [code, BlocklyPY.ORDER_NONE];
  //var code = "self.actors[" + actor_name + "]['actor']";
  // return [code, BlocklyPY.ORDER_NONE];
};

BlocklyPY["supervisor_connectinterfaces_block"] = function (block) {
  var interface1 = BlocklyPY.valueToCode(
    block,
    "interface1",
    BlocklyPY.ORDER_ATOMIC
  );
  var interface2 = BlocklyPY.valueToCode(
    block,
    "interface2",
    BlocklyPY.ORDER_ATOMIC
  );
  var code = `connectInterfaces(${interface1},${interface2})`;

  let jsonCode = {
    "code": code,
    "type": ""
  };

  return [code, BlocklyPY.ORDER_NONE];
};