import Blockly from 'blockly'

class ColorSensor extends Blockly.Field{
    constructor(value,validator){
        super(value,validator);
        this.SERIALIZABLE = true;
        
        Blockly.Extensions.register('ColorSensor',ColorSensor)
        this.fromJson = function(options) {
            const value = Blockly.utils.parsing.replaceMessageReferences(
                options['value']);
            return new ColorSensor(value);
          };

        this.initView();

        this.bindEvents_ = () => {
            super.bindEvents_();
            this.mouseDownWrapper_ =
            Blockly.browserEvents.conditionalBind(this.getClickTarget_(), 'mousedown', this,
                function(event) {
                    this.originalMouseX_ = event.clientX;
                    this.isMouseDown_ = true;
                    this.originalValue_ = this.getValue();
                    event.stopPropagation();
                }
            );
            this.mouseMoveWrapper_ =
                Blockly.browserEvents.conditionalBind(document, 'mousemove', this,
                function(event) {
                    if (!this.isMouseDown_) {
                    return;
                    }
                    var delta = event.clientX - this.originalMouseX_;
                    this.setValue(this.originalValue_ + delta);
                }
            );
            this.mouseUpWrapper_ =
                Blockly.browserEvents.conditionalBind(document, 'mouseup', this,
                function(_event) {
                    this.isMouseDown_ = false;
                }
            );
        }

        this.doClassValidation_ = (newValue) => {
            if (typeof newValue != 'string') {
              return null;
            }
            return newValue;
        };
    }
}

export default ColorSensor