import Blockly from "blockly";
import { blockColor, addSeparator } from "../../BlockProperties";
import i18n from "../../../translations/i18n";

//================================================================================================================
//================================================= ColorSensor BLOCKS =================================================
//================================================================================================================

Blockly.Blocks["photoelectricsensor_instantiation_block"] = {
  init: function () {
    this.appendDummyInput("class_type")
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(addSeparator(i18n.t("PLACEHOLDER_PHOTOELECTRICSENSOR")));
    this.appendValueInput("name")
      .setCheck("String")
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(i18n.t("PLACEHOLDER_NAME") + ":");
    this.appendValueInput("uid")
      .setCheck("Number")
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(i18n.t("PLACEHOLDER_UID") + ":");
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(blockColor("parameters"));
    // this.setTooltip("Instantiate a Range Sensor");
    this.setTooltip(i18n.t("PHOTOELECTRICSENSOR_INSTANTIATE_TOOLTIP"));
    this.setHelpUrl("");
  },
};

Blockly.Blocks["photoelectricsensor_getrange_block"] = {
  init: function () {
    this.appendDummyInput()
      .appendField(i18n.t("PHOTOELECTRICSENSOR_GET_RANGE") + " " + i18n.t("PLACEHOLDER_IN"))
      .appendField(
          new Blockly.FieldDropdown([
          ["km", "km"],
          ["m", "m"],
          ["cm", "cm"],
          ["mm", "mm"],
          ["um", "um"],
          ["nm", "nm"]
          ]),
        "unit"
      );
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(blockColor("sensing"));
    this.setTooltip(i18n.t("PHOTOELECTRICSENSOR_GET_RANGE_TOOLTIP"));
    this.setHelpUrl("");
  },
};

Blockly.Blocks['photoelectricsensor_hasdetected_block'] = {
  init: function() {
    this.appendDummyInput()
        .appendField(i18n.t("PHOTOELECTRICSENSOR_HAS_DETECTED"));
    this.setInputsInline(true);
    this.setOutput(true, null);
    this.setColour(blockColor("sensing"));
 this.setTooltip(i18n.t("PHOTOELECTRICSENSOR_HAS_DETECTED_TOOLTIP"));
 this.setHelpUrl("");
  }
};
