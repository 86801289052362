import Localization from "./Localization";

class MetaGroup {
    name: string;
    creationDate?: Date;
    lastModified?: Date;
    leader?: string;
    shortDescription: string;
    thumbnail: string;
    thumbnailBlob?: File | null;
    location: Localization;
    metaDropsVersion?: string;

    constructor(values:{
        name: string,
        creationDate?: Date,
        lastModified?: Date,
        leader?: string,
        shortDescription: string,
        thumbnail: string,
        thumbnailBlob?: File | null,
        localization: Localization,
        metaDropsVersion?: string
      }
    ){
      this.name = values.name;
      this.creationDate = values.creationDate;
      this.lastModified = values.lastModified;
      this.leader = values.leader;
      this.shortDescription = values.shortDescription;
      this.thumbnail = values.thumbnail;
      this.thumbnailBlob = values.thumbnailBlob;
      this.location = values.localization;
      this.metaDropsVersion = values.metaDropsVersion;
    }
  }
  export default MetaGroup;
  