enum ProjectType {
  Project = "Project",
  Classroom = "Classroom",
  Tournament = "Tournament",
  Challenge = "Challenge",
  Tutorial = "Tutorial",
  Template = "Template"
}

export default ProjectType;
