import { blockColor } from "../../../BlockProperties";
import "./block";
import "./pythonCode";
import "../../../Sensors/ColorSensor/block"
import "../../../Sensors/ColorSensor/pythonCode"
import "../../../Sensors/RangeSensor/block"
import "../../../Sensors/RangeSensor/pythonCode"
import i18n from "../../../../translations/i18n";

function GetNtubotToolbox(actorId: string){

    let ntubotToolbox: any = {
        kind: "category",
        name: "NTUbot",
        expanded: true,
        contents: [
            {
                kind: "category",
                name: i18n.t("PLACEHOLDER_ACTIONS"),
                colour: blockColor('actions'),
                contents: [
                    {
                        kind: "block",
                        type:"variables_call",
                        fields:{
                            "VAR":{
                                type: "field_variable",
                                id: actorId
                            }
                        },
                        inputs:{
                            "VALUE":{
                                "block": {
                                    type:"robot2wheels_setwheelsvelocities_block",
                                    fields:{
                                        "unit": "m/s"
                                    },
                                    inputs:{
                                        "left_wheel_vel":{
                                            "shadow": {
                                                "type": "math_number",
                                                "fields": {
                                                    "NUM": 0.5
                                                }
                                            }
                                        },
                                        "right_wheel_vel":{
                                            "shadow": {
                                                "type": "math_number",
                                                "fields": {
                                                    "NUM": 0.5
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    {
                        kind: "block",
                        type:"variables_call",
                        fields:{
                            "VAR":{
                                type: "field_variable",
                                id: actorId
                            }
                        },
                        inputs:{
                            "VALUE":{
                                "block": {
                                    type:"robot2wheels_setleftwheelvelocity_block",
                                    fields:{
                                        "unit": "m/s",
                                    },
                                    inputs:{
                                        "left_wheel_vel":{
                                            "shadow": {
                                                "type": "math_number",
                                                "fields": {
                                                "NUM": 0.5
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    {
                        kind: "block",
                        type:"variables_call",
                        fields:{
                            "VAR":{
                                type: "field_variable",
                                id: actorId
                            }
                        },
                        inputs:{
                            "VALUE":{
                                "block": {
                                    type:"robot2wheels_setrightwheelvelocity_block",
                                    fields:{
                                        "unit": "m/s",
                                    },
                                    inputs:{
                                        "right_wheel_vel":{
                                            "shadow": {
                                                "type": "math_number",
                                                "fields": {
                                                "NUM": 0.5
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                    {
                        kind: "block",
                        type:"variables_call",
                        fields:{
                            "VAR":{
                                type: "field_variable",
                                id: actorId
                            }
                        },
                        inputs:{
                            VALUE:{
                                block: {
                                    type: "robot2wheels_rotate_block",
                                    fields:{
                                        "angle_unit": "deg",
                                        "vel_unit": "m/s"
                                    },
                                    inputs:{
                                        angle:{
                                            shadow: {
                                                type: "math_number",
                                                fields: {
                                                    NUM: 90
                                                }
                                            }
                                        },
                                        velocity:{
                                            shadow: {
                                                type: "math_number",
                                                fields: {
                                                    NUM: 0.5
                                                }
                                            }
                                        },           
                                    }
                                }
                            }
                        }
                    },
                    {
                        kind: "block",
                        type:"variables_call",
                        fields:{
                            "VAR":{
                                type: "field_variable",
                                id: actorId
                            }
                        },
                        inputs:{
                            VALUE:{
                                block: {
                                    type: "robot2wheels_moveforward_block",
                                    fields:{
                                        "distance_unit": "m",
                                        "vel_unit": "m/s"
                                    },
                                    inputs:{
                                        distance:{
                                            shadow: {
                                                type: "math_number",
                                                fields: {
                                                    NUM: 1
                                                }
                                            }
                                        },
                                        velocity:{
                                            shadow: {
                                                type: "math_number",
                                                fields: {
                                                    NUM: 0.5
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    },
                ]
            },
            {
                kind: "category",
                name: i18n.t("PLACEHOLDER_SENSING"),
                colour: blockColor('sensing'),
                contents: [
                    // {
                    //     kind: "block",
                    //     type:"robot2wheels_getwheelsvelocities_block",
                    //     fields:{
                    //         "unit": "m/s"
                    //     }
                    // },
                    // {
                    //     kind: "block",
                    //     type:"robot2wheels_getleftwheelvelocity_block",
                    //     fields:{
                    //         "unit": "m/s"
                    //     }
                    // },
                    // {
                    //     kind: "block",
                    //     type:"robot2wheels_getrightwheelvelocity_block",
                    //     fields:{
                    //         "unit": "m/s"
                    //     }
                    // },
                    {
                        kind: "block",
                        type:"variables_call_out",
                        fields:{
                            "VAR":{
                                type: "field_variable",
                                id: actorId
                            }
                        },
                        inputs:{
                            "VALUE":{
                                "block": {
                                    type:"rangesensor_getrange_block",
                                    fields:{
                                        "unit": "m",
                                    }
                                }
                            }
                        }
                    },
                    {
                        kind: "block",
                        type:"variables_call_out",
                        fields:{
                            "VAR":{
                                type: "field_variable",
                                id: actorId
                            }
                        },
                        inputs:{
                            "VALUE":{
                                "block": {
                                    type:"colorsensor_getcolor_block"
                                }
                            }
                        }
                    },
                    {
                        kind: "block",
                        type:"variables_call_out",
                        fields:{
                            "VAR":{
                                type: "field_variable",
                                id: actorId
                            }
                        },
                        inputs:{
                            "VALUE":{
                                "block": {
                                    type:"colorsensor_gethexcolor_block"
                                }
                            }
                        }
                    },
                    {
                        kind: "block",
                        type:"variables_call_out",
                        fields:{
                            "VAR":{
                                type: "field_variable",
                                id: actorId
                            }
                        },
                        inputs:{
                            "VALUE":{
                                "block": {
                                    type:"colorsensor_getgrayscalecolor_block"
                                }
                            }
                        }
                    }
                ]
            },
            {
                kind: "category",
                name: i18n.t("PLACEHOLDER_PARAMETERS"),
                colour: blockColor('parameters'),
                contents: [
                    {
                        kind: "block",
                        type:"ntubot_instantiation_block",
                        inputs:{
                            name:{
                                shadow: {
                                    type: "text",
                                    fields: {
                                        TEXT: "NTUbot"
                                    }
                                }
                            },
                            uid:{
                                shadow: {
                                    type: "math_number",
                                    fields: {
                                        NUM: 1
                                    }
                                }
                            }
                        }
                    }
                ]
            }
        ]
    };

    return ntubotToolbox;

}

export default GetNtubotToolbox;