import ModelType from "../enums/ModelType";
import { Model } from "../models/Model";
import { apiService } from "./service";

const listModels = async (filter: ModelType = ModelType.all) => {
  try {
    let data = await apiService.getRequest("/model/list/" + filter);
    data.feedback.data = data.feedback.data.map((obj: string) => {
      const p = JSON.parse(obj);
      return p as Model;
    });
    return data;
  } catch (err: any) {
    throw err;
  }
};

const modelService = {
  listModels
};

export default modelService;
