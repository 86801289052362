import BlocklyPY from 'blockly/python'

BlocklyPY["actor_setinput_block"] = function (block) {
    var input_name = BlocklyPY.valueToCode(
      block,
      "input_name",
      BlocklyPY.ORDER_ATOMIC
    );
    var code = `setInput(${input_name})`;
    return [code, BlocklyPY.ORDER_NONE];
  };
  
  BlocklyPY["actor_getinput_block"] = function (block) {
    var input_name = BlocklyPY.valueToCode(
      block,
      "input_name",
      BlocklyPY.ORDER_ATOMIC
    );
    var code = "getInput(" + input_name + ")";
    return [code, BlocklyPY.ORDER_NONE];
  };
  
  BlocklyPY["actor_setoutput_block"] = function (block) {
    var output_name = BlocklyPY.valueToCode(
      block,
      "output_name",
      BlocklyPY.ORDER_ATOMIC
    );
    var output_function = BlocklyPY.valueToCode(
      block,
      "called_function",
      BlocklyPY.ORDER_ATOMIC
    );
    
    var function_args = "";
    if(output_function.indexOf("(") === 0){
      output_function = output_function.substring(output_function.indexOf("(") + 1, output_function.lastIndexOf(")"));
      function_args = output_function.substring(output_function.indexOf("(") + 1, output_function.lastIndexOf(")"));
    }
    
    output_function = output_function.replace("'","").substring(0, output_function.lastIndexOf("("));
    var code = "setOutput(" + output_name +", " + output_function + ", args=["+function_args+"])";
    return [code, BlocklyPY.ORDER_NONE];
  };
  
  BlocklyPY["actor_getinterface_block"] = function (block) {
    var interface_name = BlocklyPY.valueToCode(
      block,
      "interface_name",
      BlocklyPY.ORDER_ATOMIC
    );
    var dropdown_interface_type_ = block.getFieldValue("interface_type_");

    var code = `getInterface(${interface_name},${dropdown_interface_type_})`;
    return [code, BlocklyPY.ORDER_NONE];
  };

  BlocklyPY['actor_createglobalvar_block'] = function(block) {
    var interface_name = BlocklyPY.valueToCode(block, 'name', BlocklyPY.ORDER_ATOMIC);
    var interface_value = BlocklyPY.valueToCode(block, 'value', BlocklyPY.ORDER_ATOMIC);
    // TODO: Assemble Python into code variable.
    var code = `self.currentActor().createGlobalVariable(${interface_name},${interface_value})\n`;
    return code;
  };
  
  BlocklyPY['actor_createglobaloutinterface_block'] = function(block) {
    var interface_name = BlocklyPY.valueToCode(block, 'name', BlocklyPY.ORDER_ATOMIC);
    var output_function = BlocklyPY.valueToCode(block, 'function', BlocklyPY.ORDER_ATOMIC);
    // TODO: Assemble Python into code variable.
    var function_args = "";

    if(output_function.indexOf("(") === 0){
      output_function = output_function.substring(output_function.indexOf("(") + 1, output_function.lastIndexOf(")"));
      function_args = output_function.substring(output_function.indexOf("(") + 1, output_function.lastIndexOf(")"));
    }
    
    output_function = output_function.replace("'","").substring(0, output_function.lastIndexOf("("));
    var code = `self.currentActor().createGlobalOutputInterface(${interface_name},${output_function},args=[${function_args}])\n`;
    return code;
  };

  BlocklyPY['actor_getglobalvalue_block'] = function(block) {
    var interface_name = BlocklyPY.valueToCode(block, 'name', BlocklyPY.ORDER_ATOMIC);
    // TODO: Assemble Python into code variable.
    var code = `self.currentActor().getGlobalVariable(${interface_name})`;
    // TODO: Change ORDER_NONE to the correct strength.
    return [code, BlocklyPY.ORDER_NONE];
  };

  BlocklyPY['actor_setglobalvalue_block'] = function(block) {
    var interface_name = BlocklyPY.valueToCode(block, 'name', BlocklyPY.ORDER_ATOMIC);
    var interface_value = BlocklyPY.valueToCode(block, 'value', BlocklyPY.ORDER_ATOMIC);

    // TODO: Assemble Python into code variable.
    var code = `self.setGlobalVariable(${interface_name}, ${interface_value})\n`;
    // TODO: Change ORDER_NONE to the correct strength.
    return code;
  };
  
  BlocklyPY['actor_getglobalinterfacevalue_block'] = function(block) {
    var interface_name = BlocklyPY.valueToCode(block, 'name', BlocklyPY.ORDER_ATOMIC);
    // TODO: Assemble Python into code variable.
    var code = `self.currentActor().getGlobalVariable(${interface_name})`;
    // TODO: Change ORDER_NONE to the correct strength.
    return [code, BlocklyPY.ORDER_NONE];
  };

  BlocklyPY['actor_setglobalinterface_block'] = function(block) {
    var interface_name = BlocklyPY.valueToCode(block, 'name', BlocklyPY.ORDER_ATOMIC);
    var output_function = BlocklyPY.valueToCode(block, 'function', BlocklyPY.ORDER_ATOMIC);

    // TODO: Assemble Python into code variable.
    var function_args = "";

    if(output_function.indexOf("(") === 0){
      output_function = output_function.substring(output_function.indexOf("(") + 1, output_function.lastIndexOf(")"));
      function_args = output_function.substring(output_function.indexOf("(") + 1, output_function.lastIndexOf(")"));
    }
    
    output_function = output_function.replace("'","").substring(0, output_function.lastIndexOf("("));

    var code = `self.setGlobalOutputInterface(${interface_name}, ${output_function}, args=[${function_args}])\n`;

    // TODO: Change ORDER_NONE to the correct strength.
    return code;
  };