import BlocklyPY from "blockly/python";

BlocklyPY["linefollowerbot_instantiation_block"] = function (block) {
  var name = BlocklyPY.valueToCode(
    block,
    "name",
    BlocklyPY.ORDER_ATOMIC
  );
  var uid = BlocklyPY.valueToCode(
    block,
    "uid",
    BlocklyPY.ORDER_ATOMIC
  );
  var code = `LineFollowerBot(${name},${uid})`;
  return [code, BlocklyPY.ORDER_NONE]
};

BlocklyPY["linefollowerbot_getcolor_block"] = function (block) {
  var position = block.getFieldValue("position");
  if(position == "left")
    var code = `getLeftColor()`
  else if(position == "right")
    var code = `getRightColor()`
  else if(position == "center")
    var code = `getCenterColor()`
  return [code, BlocklyPY.ORDER_NONE]; 
}

BlocklyPY["linefollowerbot_gethexcolor_block"] = function (block) {
  var position = block.getFieldValue("position");
  var code = `getHexColor(\"${position}\")`
  return [code, BlocklyPY.ORDER_NONE]; 
}

BlocklyPY["linefollowerbot_getgrayscalecolor_block"] = function (block) {
  var position = block.getFieldValue("position");
  var code = `getGrayscaleColor(\"${position}\")`
  return [code, BlocklyPY.ORDER_NONE]; 
}

BlocklyPY["linefollowerbot_getcolors_block"] = function (block) {
  var code = `getColors()`;
  return [code, BlocklyPY.ORDER_NONE]; 
}

BlocklyPY["linefollowerbot_getrange_block"] = function (block) {
  var dropdown_unit = block.getFieldValue("unit");
  var position = block.getFieldValue("position");
  if(position == "left")
    var code = `getLeftRange('${dropdown_unit}')`
  else if(position == "right")
    var code = `getRightRange('${dropdown_unit}')`
  else if(position == "center")
    var code = `getCenterRange('${dropdown_unit}')`
  return [code, BlocklyPY.ORDER_NONE]; 
}

BlocklyPY["linefollowerbot_getranges_block"] = function (block) {
  var dropdown_unit = block.getFieldValue("unit");
  var code = `getRanges('${dropdown_unit}')`;
  return [code, BlocklyPY.ORDER_NONE]; 
}
