import Blockly from 'blockly'

Blockly.Blocks['text_actor'] = {
    init: function() {
        this.jsonInit({
          'type': 'text_actor',
          'message0': '%1',
          'args0': [{
            'type': 'field_dropdown',
            'name': 'TEXT',
            'options': this.generateOptions()
          }],
          'output': 'String',
          'style': 'text_blocks',
          'extensions': [
            'text_quotes'
          ],

        })
    },
    generateOptions: function() {
      const _actors = localStorage.getItem("actors");
      const defaultOption = ["Supervisor",`'Supervisor'`];
      let actors;
      try{
        actors = JSON.parse(_actors);
        if(actors && actors.length)
          return [ defaultOption, ...actors.map(actor => [actor.model_name, `'${actor.model_name}'`]) ]
        else 
          return [defaultOption]
      } catch(err) {   
        return [defaultOption]
      }
    }
    
  };