import MetaGroup from "./MetaGroup";

export class GroupProperties {
  event_id: string;
  invites: Array<Object>;
  constructor(values:{
    event_id: string;
    invites: Array<Object>;
  }){
    this.event_id = values.event_id;
    this.invites = values.invites;
  }
}
  
class GroupData {
    meta: MetaGroup;
    submissions: Array<Object>;
    users: Array<string>; 
    properties: GroupProperties;
    _id: string;

    constructor(values:{
        meta: MetaGroup;
        submissions: Array<Object>;
        users: Array<string>; 
        properties: GroupProperties;
        _id: string;    
      }
    ){
      this.meta = values.meta;
      this.submissions = values.submissions;
      this.users = values.users;
      this.properties = values.properties;
      this._id = values._id;
    }
  }
  export default GroupData;