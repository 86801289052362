import { blockColor } from "../../BlockProperties";
import "./block";
import "./pythonCode";
import "./customField";
import i18n from "../../../translations/i18n";

function GetConveyorToolbox(actorId: string){

  let conveyorToolbox = {
    kind: "category",
    name: i18n.t("PLACEHOLDER_CONVEYOR"),
    expanded: true,
    // colour: blockColor("conveyor"),
    contents: [
      {
        kind: "category",
        name: i18n.t("PLACEHOLDER_ACTIONS"),
        colour: blockColor("actions"),
        contents: [
            {
              kind: "block",
              type:"variables_call",
              fields:{
                  "VAR":{
                      type: "field_variable",
                      id: actorId
                  }
              },
              inputs:{
                  VALUE:{
                      block: {
                          type: "conveyor_setstate_block",
                          fields:{
                            state: 1
                          },
                      }
                  }
              }
          },
        ],
      },
      {
        kind: "category",
        name: i18n.t("PLACEHOLDER_PARAMETERS"),
        colour: blockColor("parameters"),
        contents: [
          {
            kind: "block",
            type: "conveyor_instantiation_block",
            inputs:{
              name:{
                  block: {
                      type: "text",
                      fields: {
                          TEXT: "Conveyor"
                      }
                  }
              },
              uid:{
                  block: {
                      type: "math_number",
                      fields: {
                          NUM: 1
                      }
                  }
              }
            }
          },
        ],
      },
    ],
  };

  return conveyorToolbox;

}

export default GetConveyorToolbox;
