import { blockColor } from "../../BlockProperties";
import "./block";
import "./pythonCode";
import i18n from "../../../translations/i18n";

function GetPhotoelectricSensorToolbox(actorId: string){
  let photoelectricSensorToolbox: any = {
    kind: "category",
    name: i18n.t("PLACEHOLDER_PHOTOELECTRICSENSOR"),
    expanded: true,
    // colour: blockColor("range_sensor"),
    contents: [
      {
        kind: "category",
        name: i18n.t("PLACEHOLDER_SENSING"),
        colour: blockColor("sensing"),
        contents: [
          // {
          //   kind: "block",
          //   type: "photoelectricsensor_getrange_block",
          //   fields:{
          //     "unit": "m"
          //   }
          // },
          {
            kind: "block",
            type:"variables_call_out",
            fields:{
                "VAR":{
                    type: "field_variable",
                    id: actorId
                }
            },
            inputs:{
                "VALUE":{
                    "block": {
                        type:"photoelectricsensor_getrange_block",
                        fields:{
                          "unit": "m"
                        }
                    }
                }
            }
          },
          {
            kind: "block",
            type:"variables_call_out",
            fields:{
                "VAR":{
                    type: "field_variable",
                    id: actorId
                }
            },
            inputs:{
                "VALUE":{
                    "block": {
                        type:"photoelectricsensor_hasdetected_block"
                    }
                }
            }
          }
        ],
      },
      {
        kind: "category",
        name: i18n.t("PLACEHOLDER_PARAMETERS"),
        colour: blockColor("parameters"),
        contents: [
          {
            kind: "block",
            type: "photoelectricsensor_instantiation_block",
            inputs:{
              name:{
                  shadow: {
                      type: "text",
                      fields: {
                          TEXT: "PhotoelectricSensor"
                      }
                  }
              },
              uid:{
                  shadow: {
                      type: "math_number",
                      fields: {
                          NUM: 1
                      }
                  }
              }
            }
          },
        ],
      },
    ],
  };

  return photoelectricSensorToolbox;
}

export default GetPhotoelectricSensorToolbox;
