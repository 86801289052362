import axios from "axios";
// import errorHandler from "./errorHandler.service";
import { Buffer } from "buffer";
import {
  ModelResource,
  Project,
  ProjectPermissions,
  ProjectProperties,
  RosStructure,
  SimInfo,
} from "../models/Project";
import {Scene, SceneModel} from "../models/Scene";
import userService from "./user.service";
import MetaProject from "../models/MetaProject";
import ProjectType from "../enums/ProjectType";
import { apiService } from "./service";

const REQUEST_TIMEOUT = 3000000;

/**
 * Serviço para pegar todas as informações do projeto
 */
const getAllProjectProperties = async (projectId: string) => {
  const user = userService.getCurrentUser();
  const body = {
    user_token: user?.token,
    project_id: projectId,
  };

  try {
    let data = await apiService.postRequest("/project/getProjectProperties/all", body);
    data.feedback.data = JSON.parse(data.feedback.data);
    data.feedback.data.metaProject.createDate = new Date(data.feedback.data.metaProject?.creationDate?.$date);
    data.feedback.data.metaProject.updateDate = new Date(data.feedback.data.metaProject?.lastModified?.$date);
    data.feedback.data.id = data.feedback.data._id.$oid;
    data.feedback.data.properties.event_id = data.feedback.data.properties?.event_id?.$oid;
    return data;
  } catch (err: any) {
    throw err;
  }
};

/**
 * Serviço para listar todos os templates
 */
const getAllTemplates = async () => {
  try {
    const data = await apiService.getRequest("/project/listAllTemplates");
    return data;
  } catch (err: any) {
    throw err;
  }
};

/**
 * Serviço para listar todos os templates
 */
const listTemplatesByEvent = async (eventId: string = "default") => {
  try {
    let data = await apiService.getRequest("/project/listTemplates/" + eventId);
    
    const listDefault: Project[] = data.feedback.data.default.map(
      (obj: any) => {
        const p = JSON.parse(obj);
        return new Project({
          id: p._id.$oid,
          meta: new MetaProject(
            p.metaProject.title,
            ProjectType[p.metaProject.type as keyof typeof ProjectType],
            p.metaProject.description,
            p.metaProject.thumbnail,
            new Date(p.metaProject.creationDate.$date),
            new Date(p.metaProject.lastModified.$date),
            p.metaProject.metaDropsVersion,
            p.metaProject.owner.$oid
          ),
          permissions: new ProjectPermissions(true, true, true),
          simInfo: new SimInfo("", "127.0.0.1", 10000, "/coppeliaSim", 19999),
          resources: p.resources as ModelResource[],
          projectProperties: new ProjectProperties(),
          // rosStructure: new RosStructure({
          //   scene: new Scene({
          //     models: p.rosStructure?.scene.models as SceneModel[]
          //   })
          // })
            rosStructure: new RosStructure({
              scene: new Scene({
                models: p.rosStructure?.scene.models as SceneModel[] ?? []
            })
          })
        }
        );
      }
    );
    data.feedback.data.default = listDefault;

    const listEvent: Project[] = data.feedback.data.event?.map((obj: any) => {
      const p = JSON.parse(obj);
      return new Project({
        id: p._id.$oid,
        meta: new MetaProject(
          p.metaProject.title,
          ProjectType[p.metaProject.type as keyof typeof ProjectType],
          p.metaProject.description,
          p.metaProject.thumbnail,
          new Date(p.metaProject.creationDate.$date),
          new Date(p.metaProject.lastModified.$date),
          p.metaProject.metaDropsVersion,
          p.metaProject.owner.$oid
        ),
        permissions: new ProjectPermissions(true, true, true),
        simInfo: new SimInfo("", "127.0.0.1", 10000, "/coppeliaSim", 19999),
        resources: p.resources as ModelResource[],
        projectProperties: new ProjectProperties(eventId),
        rosStructure: new RosStructure({
          scene: new Scene({
            models: p.rosStructure?.scene.models as SceneModel[]
        })
      })
      }
      );
    });
    data.feedback.data.event = listEvent;

    return data;
  } catch (err: any) {
    throw err;
  }
};

/**
 * Serviço para pegar todas as informações do projeto
 */
const createProject = async (project: Project, templateId?: string) => {
  const access_token = userService.getAccessToken();
  const user = userService.getCurrentUser();
  const config = {
    headers: {
      Authorization: `Bearer ${access_token}`,
      // "refresh-token": userService.getRefreshToken(),
      "content-type": "multipart/form-data",
    },
    timeout: REQUEST_TIMEOUT,
  };
  console.log(project.properties?.attachment);
  const body = {
    user_token: user?.token,
    template:
      templateId !== undefined
        ? {
            metaProject: {
              title: project.metaProject.title,
              type: project.metaProject.type,
              description: project.metaProject.description,
              thumbnail: project.metaProject.thumbnailBlob,
              metaDropsVersion: project.metaProject.metaDropsVersion
            },
            permissions: {
              share: project.permissions.share,
              download: project.permissions.download,
              edit: project.permissions.edit
            },
            properties: {
              event_id: project.properties?.event_id,
              attachment: project.properties?.attachment ?? null
            },
            project_id: templateId,
          }
        : {
          metaProject: {
            title: project.metaProject.title,
            type: project.metaProject.type,
            description: project.metaProject.description,
            thumbnail: project.metaProject.thumbnailBlob,
            metaDropsVersion: project.metaProject.metaDropsVersion
          },
          permissions: {
            share: project.permissions.share,
            download: project.permissions.download,
            edit: project.permissions.edit
          },
          properties: {
            event_id: project.properties?.event_id,
            attachment: project.properties?.attachment
          },
          resources: project.resources.map((r: ModelResource) => ({
            type: r.type,
            maxQtd: r.maxQtd,
            availableQtd: r.availableQtd,
          }))
        },
  };
  try {
    const data = await apiService.postRequest("/project/createProject", body, config);
    return data;
  } catch (err: any) {
    throw err;
  }
};

/**
 * Serviço para pegar todas as informações do projeto
 */
const createCopy = async (
  project_id: string,
  to_type: ProjectType,
  read_only: boolean = false,
  metaProject?: MetaProject,
  properties?: ProjectProperties,
) => {
  const access_token = userService.getAccessToken();
  const user = userService.getCurrentUser();
  const config = {
    headers: {
      Authorization: `Bearer ${access_token}`,
      // "refresh-token": userService.getRefreshToken(),
      "content-type": "multipart/form-data",
    },
    timeout: REQUEST_TIMEOUT,
  };
  const body = {
    user_token: user?.token,
    project_id,
    to_type,
    read_only,
    updates: {   
      metaProject: {    
        title: metaProject?.title,     
        description: metaProject?.description,     
        thumbnail: metaProject?.thumbnailBlob ?? undefined
      },   
      properties: {     
        event_id: properties?.event_id, 
        //attachment: properties?.attachment, 
      }
    }
  };
  try {
    const data = await apiService.postRequest("/project/copy", body, config);
    return data;
  } catch (err: any) {
    throw err;
  }
};

/**
 * Serviço para atualizar o meta project
 */
const updateMetaProject = async (projectId: string, meta: MetaProject) => {
  const user = userService.getCurrentUser();
  const body = {
    user_token: user?.token,
    project_id: projectId,
    updates: {
      title: meta.title,
      lastModified: true,
      description: meta.description,
      thumbnail: meta.thumbnailBlob,
    },
  };
  try {
    const data = await apiService.postRequest("/project/updateMetaProject/all", body);
    return data;
  } catch (err: any) {
    throw err;
  }
};

/**
 * Serviço que atualiza o resources do projeto
 */
const updateProjectResources = async (
  projectId: string,
  resources: ModelResource[]
) => {
  const user = userService.getCurrentUser();
  const body = {
    user_token: user?.token,
    project_id: projectId,
    updates: {
      resources: resources.map((r: ModelResource) => ({
        type: r.type,
        maxQtd: r.maxQtd,
        availableQtd: r.availableQtd,
      })),
    },
  };
  try {
    const data = await apiService.postRequest("/project/updateProject/resources", body);
    return data;
  } catch (err: any) {
    throw err;
  }
};

const editProject = async (
  projectId: string,
  meta: MetaProject,
  resources: ModelResource[],
  properties: ProjectProperties
) => {
  const access_token = userService.getAccessToken();
  const user = userService.getCurrentUser();
  const config = {
    headers: {
      Authorization: `Bearer ${access_token}`,
      // "refresh-token": userService.getRefreshToken(),
      "content-type": "multipart/form-data",
    },
    timeout: REQUEST_TIMEOUT,
  };
  const body = {
    user_token: user?.token,
    project_id: projectId,
    updates: {
      metaProject: {
        title: meta.title,
        //lastModified: true,
        description: meta.description,
        thumbnail: meta.thumbnailBlob,
      },
      resources: resources.map((r: ModelResource) => ({
        type: r.type,
        maxQtd: r.maxQtd,
        availableQtd: r.availableQtd,
      })),
      properties: {
        event_id:properties.event_id,
        attachment: properties.attachment
      },
    },
  };
  try {
    const data = await apiService.postRequest("/project/edit", body, config);
    return data;
  } catch (err: any) {
    throw err;
  }
};

/**
 * Serviço para pegar o xml de um objeto pelo nome
 */
const getXmlByObjectName = async (name: string, projectId: string) => {
  const user = userService.getCurrentUser();
  const body = {
    user_token: user?.token,
    project_id: projectId,
    object_name: name,
  };

  try {
    let data = await apiService.postRequest("/project/getProjectProperties/blocklyCode", body, null, true);
    if (data.feedback !== undefined && data.feedback.data !== null)
      data.feedback.data = Buffer.from(data.feedback.data, "base64").toString();
    return data;
  } catch (err: any) {
    throw err;
  }
};

/**
 * Serviço de salvar o projeto (rosStructure)
 */
const saveProject = async (rosStructure: object, projectId: string) => {
  const user = userService.getCurrentUser();
  const body = {
    user_token: user?.token,
    project_id: projectId,
    updates: {
      rosStructure: rosStructure,
    },
  };
  try {
    const data = await apiService.postRequest("/project/updateProject/rosStructure", body, null, true);
    return data;
  } catch (err: any) {
    throw err;
  }
};

/**
 * Serviço de rodar o projeto (rosStructure)
 */
const runProject = async (projectId: string) => {
  const user = userService.getCurrentUser();

  const body = {
    user_token: user?.token,
    project_id: projectId,
  };
  try {
    const data = await apiService.postRequest("/project/run", body, null, true);
    return data;
  } catch (err: any) {
    throw err;
  }
};

/**
 * Serviço de fechar o projeto
 */
const closeProject = async (projectId: string) => {
  const user = userService.getCurrentUser();

  const body = {
    user_token: user?.token,
    project_id: projectId,
  };
  try {
    const data = await apiService.postRequest("/project/close", body, null, true);
    return data;
  } catch (err: any) {
    throw err;
  }
};

/**
 * Serviço de lançar o projeto (rosStructure)
 */
const createTemporary = async (projectId: string) => {
  const user = userService.getCurrentUser();
  const body = {
    user_token: user?.token,
    project_id: projectId,
  };
  try {
    let data = await apiService.postRequest("/project/createTemporary", body, null, true);
    data.feedback.data = JSON.parse(data.feedback.data);
    data.feedback.data.id = data.feedback.data._id.$oid;
    return data;
  } catch (err: any) {
    throw err;
  }

};

/**
 * Serviço de lançar o projeto (rosStructure)
 */
const launchProject = async (projectId: string) => {
  const user = userService.getCurrentUser();
  const body = {
    user_token: user?.token,
    project_id: projectId,
  };
  try {
    const data = await apiService.postRequest("/project/launch", body, null, true);
    return data;
  } catch (err: any) {
    throw err;
  }
};

/**
 * Serviço de lançar o projeto (rosStructure)
 */
const autolaunchProject = async (projectId: string, submissionId: string) => {
  const user = userService.getCurrentUser();
  const body = {
    user_token: user?.token,
    project_id: projectId,
    submission_id: submissionId,
    sim_timeout_min: 60
  };
  try {
    const data = await apiService.postRequest("/project/autolaunch", body, null, true);
    return data;
  } catch (err: any) {
    throw err;
  }
};

/**
 * Serviço de deletar projeto
 */
const deleteProject = async (projectId: string) => {
  const user = userService.getCurrentUser();
  const body = {
    user_token: user?.token,
    project_id: projectId,
  };
  try {
    const data = await apiService.deleteRequest("/project/deleteProject", body);
    return data;
  } catch (err: any) {
    throw err;
  }
};

/**
 * Serviço de salvar codigo de blocos
 */
const addModelToReadOnlyList = async (
  modelName: string,
  projectId: string
) => {
  const user = userService.getCurrentUser();
  const body = {
    user_token: user?.token,
    project_id: projectId,
    object_name: modelName,
  };
  try {
    const data = await apiService.postRequest("/project/model/readOnly/add", body, null, true);
    return data;
  } catch (err: any) {
    throw err;
  }
};

/**
 * Serviço de salvar codigo de blocos
 */
const removeModelFromReadOnlyList = async (
  modelName: string,
  projectId: string
) => {
  const user = userService.getCurrentUser();
  const body = {
    user_token: user?.token,
    project_id: projectId,
    object_name: modelName,
  };
  try {
    const data = await apiService.postRequest("/project/model/readOnly/remove", body, null, true);
    return data;
  } catch (err: any) {
    throw err;
  }
};

/**
 * Serviço de salvar codigo de blocos
 */
const saveCode = async (
  robotName: string,
  python: string,
  xml: string,
  projectId: string
) => {
  const user = userService.getCurrentUser();
  const encodedPython = Buffer.from(python).toString("base64");
  const encodedXml = Buffer.from(xml).toString("base64");
  const body = `{
    "user_token": "${user?.token}",
    "project_id": "${projectId}",
    "updates": {
      "codes": {
        "${robotName}": {
          "python": "${encodedPython}",
          "xml": "${encodedXml}"
        }
      }
    }
  }`;

  try {
    const data = await apiService.postRequest("/project/updateProject/codes", body, null, true);
    return data;
  } catch (err: any) {
    throw err;
  }
};

/**
 * Serviço de deletar codigo do projeto
 */
const deleteCode = async (modelName: string, projectId: string) => {
  const user = userService.getCurrentUser();
  const body = {
    user_token: user?.token,
    project_id: projectId,
    object_name: modelName,
  };
  try {
    const data = await apiService.deleteRequest("/project/deleteProjectCode", body, null, true);
    return data;
  } catch (err: any) {
    throw err;
  }
};

const projectService = {
  getAllProjectProperties,
  getAllTemplates,
  listTemplatesByEvent,
  saveCode,
  addModelToReadOnlyList,
  removeModelFromReadOnlyList,
  deleteCode,
  saveProject,
  runProject,
  closeProject,
  createTemporary,
  launchProject,
  autolaunchProject,
  deleteProject,
  getXmlByObjectName,
  createProject,
  updateMetaProject,
  editProject,
  updateProjectResources,
  createCopy,
};

export default projectService;
