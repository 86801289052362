import ProjectType from "../enums/ProjectType";

class MetaProject {
  title: string;
  type: ProjectType;
  description: string;
  thumbnail: string;
  thumbnailBlob: Blob | null;
  createDate?: Date;
  updateDate?: Date;
  metaDropsVersion: string;
  owner?: string;

  constructor(
    title: string,
    type: ProjectType,
    desc: string,
    thumbnail: string,
    createDate?: Date,
    updateDate?: Date,
    metaDropsVersion?: string,
    owner?: string
  ) {
    this.title = title;
    this.type = type;
    this.description = desc;
    this.createDate = createDate;
    this.updateDate = updateDate;
    this.thumbnail = thumbnail;
    this.thumbnailBlob = null;
    this.metaDropsVersion = metaDropsVersion ?? "v0.0.1";
    this.owner = owner;
  }
  
}

export default MetaProject;
