import Blockly from 'blockly'
import i18n from '../../translations/i18n';
import {blockColor} from "../BlockProperties"

Blockly.Blocks['linebreak_block'] = {
    init: function() {
      this.appendDummyInput()
          .appendField(i18n.t("RESOURCES_LINEBREAK"));
      this.setPreviousStatement(true, null);
      this.setNextStatement(true, null);
      this.setColour(blockColor("resources"));
   this.setTooltip(i18n.t("RESOURCES_LINEBREAK_TOOLTIP"));
   this.setHelpUrl("");
    }
  };

Blockly.Blocks["sleep_block"] = {
  init: function () {
      this.appendDummyInput("class_type")
          .setAlign(Blockly.ALIGN_RIGHT)
          //.appendField("sleep");
          .appendField(i18n.t("TIME_SLEEP"))
      this.appendValueInput("time")
          .setCheck("Number")
          .setAlign(Blockly.ALIGN_RIGHT)
      this.appendDummyInput("dropdown")
          .appendField(
              new Blockly.FieldDropdown([
              ["hr", "hr"],
              ["min", "min"],
              ["s", "s"],
              ["ms", "ms"],
              ["us", "us"],
              ["ns", "ns"],
              ]),
              "unit")
    this.setInputsInline(true);
    this.setPreviousStatement(true, null);
    this.setNextStatement(true, null);
    this.setColour(blockColor("resources"));
    this.setTooltip(i18n.t("TIME_SLEEP_TOOLTIP"));
    this.setHelpUrl("");
  },
};

Blockly.Blocks["log_block"] = {
  init: function () {
      this.appendDummyInput("class_type")
          .setAlign(Blockly.ALIGN_RIGHT)
          //.appendField("sleep");
          .appendField("log")
      this.appendValueInput("msg")
          .setCheck("String")
          .setAlign(Blockly.ALIGN_RIGHT)
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(Blockly.Themes.Zelos.blockStyles.text_blocks.colourPrimary);
    this.setTooltip("Imprimir um texto no console.");
    this.setHelpUrl("");
  },
};