import BlocklyPY from 'blockly/python'

const multiplier = {
  "hr": 3600,
  "min": 60,
  "s": 1,
  "ms": 1e-3,
  "us": 1e-6,
  "ns": 1e-9,
}

BlocklyPY['linebreak_block'] = function(block) {
    // TODO: Assemble JavaScript into code variable.
    var code = '\n';
    return code;
  };

BlocklyPY["sleep_block"] = function (block) {
    var time_value = BlocklyPY.valueToCode(block, "time", BlocklyPY.ORDER_ATOMIC);
    var dropdown_unit = block.getFieldValue("unit");
    time_value *= multiplier[dropdown_unit]
    var code = `time.sleep(${time_value})`;
    return code + '\n';
};

BlocklyPY["log_block"] = function (block) {
  var msg = BlocklyPY.valueToCode(block, "msg", BlocklyPY.ORDER_ATOMIC);
  var code = `log(${msg})`;
  return [code, BlocklyPY.ORDER_NONE];
};
