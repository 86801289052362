import { blockColor } from "../../../BlockProperties";
import "./block";
import "./pythonCode";
import "../block"
import "../pythonCode"
import i18n from "../../../../translations/i18n";

function GetLineFollowerToolbox(actorId: string){

    let linefollowerToolbox: any = {
        kind: "category",
        name: "Seguidor de linha", //colocar placeholder no i18n
        expanded: true,
        contents: [
            {
                kind: "category",
                name: i18n.t("PLACEHOLDER_ACTIONS"),
                colour: blockColor('actions'),
                contents: [
                {
                    kind: "block",
                    type:"variables_call",
                    fields:{
                        "VAR":{
                            type: "field_variable",
                            id: actorId
                        }
                    },
                    inputs:{
                        "VALUE":{
                            "block": {
                                type:"robot2wheels_setwheelsvelocities_block",
                                fields:{
                                    "unit": "m/s"
                                },
                                inputs:{
                                    "left_wheel_vel":{
                                        "shadow": {
                                            "type": "math_number",
                                            "fields": {
                                                "NUM": 0.5
                                            }
                                        }
                                    },
                                    "right_wheel_vel":{
                                        "shadow": {
                                            "type": "math_number",
                                            "fields": {
                                                "NUM": 0.5
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                },
                {
                    kind: "block",
                    type:"variables_call",
                    fields:{
                        "VAR":{
                            type: "field_variable",
                            id: actorId
                        }
                    },
                    inputs:{
                        "VALUE":{
                            "block": {
                                type:"robot2wheels_setleftwheelvelocity_block",
                                fields:{
                                    "unit": "m/s",
                                },
                                inputs:{
                                    "left_wheel_vel":{
                                        "shadow": {
                                            "type": "math_number",
                                            "fields": {
                                            "NUM": 0.5
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                },
                {
                    kind: "block",
                    type:"variables_call",
                    fields:{
                        "VAR":{
                            type: "field_variable",
                            id: actorId
                        }
                    },
                    inputs:{
                        "VALUE":{
                            "block": {
                                type:"robot2wheels_setrightwheelvelocity_block",
                                fields:{
                                    "unit": "m/s",
                                },
                                inputs:{
                                    "right_wheel_vel":{
                                        "shadow": {
                                            "type": "math_number",
                                            "fields": {
                                            "NUM": 0.5
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                },
                {
                    kind: "block",
                    type:"variables_call",
                    fields:{
                        "VAR":{
                            type: "field_variable",
                            id: actorId
                        }
                    },
                    inputs:{
                        VALUE:{
                            block: {
                                type: "robot2wheels_rotate_block",
                                fields:{
                                    "angle_unit": "deg",
                                    "vel_unit": "m/s"
                                },
                                inputs:{
                                    angle:{
                                        shadow: {
                                            type: "math_number",
                                            fields: {
                                                NUM: 90
                                            }
                                        }
                                    },
                                    velocity:{
                                        shadow: {
                                            type: "math_number",
                                            fields: {
                                                NUM: 0.5
                                            }
                                        }
                                    },           
                                }
                            }
                        }
                    }
                },
                {
                    kind: "block",
                    type:"variables_call",
                    fields:{
                        "VAR":{
                            type: "field_variable",
                            id: actorId
                        }
                    },
                    inputs:{
                        VALUE:{
                            block: {
                                type: "robot2wheels_moveforward_block",
                                fields:{
                                    "distance_unit": "m",
                                    "vel_unit": "m/s"
                                },
                                inputs:{
                                    distance:{
                                        shadow: {
                                            type: "math_number",
                                            fields: {
                                                NUM: 1
                                            }
                                        }
                                    },
                                    velocity:{
                                        shadow: {
                                            type: "math_number",
                                            fields: {
                                                NUM: 0.5
                                            }
                                        }
                                    },           
                                }
                            }
                        }
                    }
                },
                ]
            },
            {
                kind: "category",
                name: i18n.t("PLACEHOLDER_SENSING"),
                colour: blockColor('sensing'),
                contents: [
                {
                    kind: "block",
                    type:"variables_call_out",
                    fields:{
                        "VAR":{
                            type: "field_variable",
                            id: actorId
                        }
                    },
                    inputs:{
                        "VALUE":{
                            "block": {
                                type:"linefollowerbot_getrange_block",
                                fields:{
                                    "unit": "m",
                                    "position": "center"
                                },
                            }
                        }
                    }
                },
                {
                    kind: "block",
                    type:"variables_call_out",
                    fields:{
                        "VAR":{
                            type: "field_variable",
                            id: actorId
                        }
                    },
                    inputs:{
                        "VALUE":{
                            "block": {
                                type:"linefollowerbot_getranges_block",
                                fields:{
                                    "unit": "m",
                                },
                            }
                        }
                    }
                },
                {
                    kind: "block",
                    type:"variables_call_out",
                    fields:{
                        "VAR":{
                            type: "field_variable",
                            id: actorId
                        }
                    },
                    inputs:{
                        "VALUE":{
                            "block": {
                                type:"linefollowerbot_getcolor_block"
                            }
                        }
                    }
                },
                {
                    kind: "block",
                    type:"variables_call_out",
                    fields:{
                        "VAR":{
                            type: "field_variable",
                            id: actorId
                        }
                    },
                    inputs:{
                        "VALUE":{
                            "block": {
                                type:"linefollowerbot_gethexcolor_block"
                            }
                        }
                    }
                },
                {
                    kind: "block",
                    type:"variables_call_out",
                    fields:{
                        "VAR":{
                            type: "field_variable",
                            id: actorId
                        }
                    },
                    inputs:{
                        "VALUE":{
                            "block": {
                                type:"linefollowerbot_getgrayscalecolor_block",
                            }
                        }
                    }
                },
                {
                    kind: "block",
                    type:"variables_call_out",
                    fields:{
                        "VAR":{
                            type: "field_variable",
                            id: actorId
                        }
                    },
                    inputs:{
                        "VALUE":{
                            "block": {
                                type:"linefollowerbot_getcolors_block",
                            }
                        }
                    }
                },
                ]
            },
            {
                kind: "category",
                name: i18n.t("PLACEHOLDER_PARAMETERS"),
                colour: blockColor('parameters'),
                contents: [
                    {
                        kind: "block",
                        type:"linefollowerbot_instantiation_block",
                        inputs:{
                            name:{
                                shadow: {
                                    type: "text",
                                    fields: {
                                        TEXT: "LineFollowerBot"
                                    }
                                }
                            },
                            uid:{
                                shadow: {
                                    type: "math_number",
                                    fields: {
                                        NUM: 1
                                    }
                                }
                            }
                        }
                    }
                ]
            }
        ]
    };

    return linefollowerToolbox;
}

export default GetLineFollowerToolbox;