import axios, { AxiosError } from "axios";
import jwt_decoded from "jwt-decode";
import crypto from "crypto-js";
import Fingerprint2 from "fingerprintjs2";
import { publicIp, publicIpv4, publicIpv6 } from "public-ip";
import UserAccessToken from "../models/UserAccessToken";
import projectService from "./project.service";
import UserData from "../models/UserData";
import Address from "../models/UserAddress";
import EventType from "../enums/EventType";
import {
  DropsEvent,
  EventInvites,
  EventProperties,
  EventRules,
  MetaEvent,
} from "../models/Event";
import { Project, ProjectPermissions, SimInfo } from "../models/Project";
import MetaProject from "../models/MetaProject";
import ProjectType from "../enums/ProjectType";
import MetaGroup from "../models/MetaGroup";
import GroupData, { GroupProperties } from "../models/GroupData";
import { apiService } from "./service";
import { t } from "i18next";
import { Institution } from "../models/Institution";

const API_URL = `${process.env.REACT_APP_API_URL_DEV}/user`;
const ACCESS_TOKEN_STORAGE = "access_token";
const REFRESH_TOKEN_STORAGE = "refresh_token";
const KEEP_SESSION_STORAGE = "keep_session";
const REQUEST_TIMEOUT = 30000;

/**
 * Serviço de cadastro
 */
const signup = async (
  recaptchaToken: string,
  username: string,
  email: string,
  password: string,
  institution_id?: string,
  unit_id?: string
) => {
  const body = {
    username: username,
    email: email,
    password: password,
    recaptcha_token: recaptchaToken,
    institution_id,
    unit_id,
  };

  const response = await axios
    .post(API_URL + "/signup", body, { timeout: REQUEST_TIMEOUT })
    .catch((err: AxiosError) => {
      throw err;
    });
  const data = response.data as ApiResponse;
  return data;
};

/**
 * Serviço de login
 */
async function generateFingerprint() {
  try {
    const options = {
      // You can customize the fingerprinting behavior with these options (optional).
      // See https://github.com/Valve/fingerprintjs2#options for all available options.
      excludeAdBlock: true,
      excludeCanvas: true,
      excludeWebGL: true,
      excludeUserAgent: true,
    };

    const components = await new Promise((resolve) =>
      Fingerprint2.get(options, (components) => resolve(components))
    );

    components.push(
      {
        key: "ipv4Address",
        value: await publicIpv4(),
      },
      {
        key: "ipv6Address",
        value: await publicIpv6(),
      }
    );

    // Now you can use the components array to create a unique fingerprint.
    var fingerprint = Fingerprint2.x64hash128(
      components.map((component) => component.value).join(""),
      31
    );
    return fingerprint;
  } catch (error) {
    console.error("Error generating fingerprint:", error);
  }
}

const login = async (
  recaptchaToken: string,
  rememberMe: boolean = false,
  email: string,
  password?: string,
  otp?: string
) => {
  var visitorId = await generateFingerprint();

  const response = await axios
    .post(
      API_URL + "/auth",
      {
        email,
        password,
        code: otp,
        recaptcha_token: recaptchaToken,
        client_ip: await publicIpv4(),
        fingerprint: visitorId,
      },
      { timeout: REQUEST_TIMEOUT }
    )
    .catch((err: AxiosError | string) => {
      throw err;
    });

  const data = response?.data as ApiResponse;
  if (data.success) {
    localStorage.setItem(ACCESS_TOKEN_STORAGE, data.feedback.data.access_token);
    localStorage.setItem(
      REFRESH_TOKEN_STORAGE,
      data.feedback.data.refresh_token
    );
    localStorage.setItem(KEEP_SESSION_STORAGE, String(rememberMe));
  }
  return data;
};

/**
 * Obtém todas as informações do usuário
 */
const getUserData = async () => {
  const user = getCurrentUser();
  try {
    let data = await apiService.postRequest("/user/data", {
      user_token: user?.token,
    });
    data.feedback.data = decryptData(data.feedback.data);
    return data;
  } catch (err: any) {
    throw err;
  }
};

const getUsersById = async (usersList: string[]) => {
  try {
    const data = await apiService.postRequest("/user/getUsersById", {
      users: usersList,
    });
    return data;
  } catch (err: any) {
    throw err;
  }
};

const getUsers = async (values: {
  user_email?: string[];
  user_username?: string[];
}) => {
  try {
    const data = await apiService.postRequest("/user/getUsers", values);
    return data;
  } catch (err: any) {
    throw err;
  }
};

const decryptData = (data: string) => {
  const wordArray = crypto.enc.Utf8.parse(process.env.REACT_APP_JWT_KEY ?? "");
  const decrypted = crypto.AES.decrypt(data, wordArray, {
    mode: crypto.mode.ECB,
  });
  const decryptedData = decrypted.toString(crypto.enc.Utf8);
  const res = JSON.parse(decryptedData);
  return res;
};

/**
 * Verifica se o refresh token é valido (passar true caso seja necessário forçar o refresh. Usado para chamadas dentro do Unity)
 */
const verify = async (forceRefresh?: boolean) => {
  const config = {
    headers: { Authorization: `Bearer ${getAccessToken()}` },
    timeout: REQUEST_TIMEOUT,
  };
  try {
    const response = await axios.post(
      API_URL + "/auth/verify",
      { refresh_token: localStorage.getItem(REFRESH_TOKEN_STORAGE) },
      config
    );

    window.vuplex?.postMessage({
      action: "updateAccessToken",
      success: true,
      access_token: localStorage.getItem(ACCESS_TOKEN_STORAGE),
      refresh_token: localStorage.getItem(REFRESH_TOKEN_STORAGE),
    });
    return response.data as ApiResponse;
  } catch (err: any) {
    const error = err as AxiosError;
    if (error.response) {
      const apiResponse = err.response.data as ApiResponse;
      if (
        apiResponse?.feedback &&
        apiResponse?.feedback.tag === "expiredAuth"
      ) {
        const refresh =
          forceRefresh ?? localStorage.getItem(KEEP_SESSION_STORAGE) === "true";
        if (refresh) {
          try {
            const res = await refreshToken();

            window.vuplex?.postMessage({
              action: "updateAccessToken",
              success: true,
              access_token: localStorage.getItem(ACCESS_TOKEN_STORAGE),
            });
            return res.data as ApiResponse;
          } catch (err) {
            throw err;
          }
        }
      }
    }
    logout();
    throw err;
  }
};

/**
 * Serviço de logout
 */
const logout = () => {
  localStorage.clear();
};

/**
 * Serviço de envio de refresh token
 */
const refreshToken = async () => {
  const access_token = localStorage.getItem(ACCESS_TOKEN_STORAGE);
  const config = {
    headers: { Authorization: `Bearer ${access_token}` },
    timeout: REQUEST_TIMEOUT,
  };

  try {
    const refresh_token = localStorage.getItem(REFRESH_TOKEN_STORAGE);
    const response = await axios.post(
      API_URL + "/auth/refresh",
      {
        refresh_token: refresh_token,
      },
      config
    );
    const data = response.data as ApiResponse;

    if (data.success) {
      localStorage.setItem(
        ACCESS_TOKEN_STORAGE,
        data.feedback.data.access_token
      );
    }
    window.vuplex?.postMessage({
      action: "updateAccessToken",
      success: true,
      access_token: localStorage.getItem(ACCESS_TOKEN_STORAGE),
      refresh_token: localStorage.getItem(REFRESH_TOKEN_STORAGE),
    });
    return response;
  } catch (err) {
    throw err;
  }
};

/**
 * Serviço de solicitação de token de acesso
 */
const forgot = async (recaptchaToken: string, email: string) => {
  const response = await axios
    .post(
      API_URL + "/forgot",
      {
        email,
        recaptcha_token: recaptchaToken,
      },
      { timeout: REQUEST_TIMEOUT }
    )
    .catch((err: AxiosError) => {
      throw err;
    });

  return response.data as ApiResponse;
};

/**
 * Alterar  usuario
 */
const updateUser = async (userData: UserData) => {
  try {
    let data = await apiService.postRequest("/user/update", {
      user_token: getCurrentUser()?.token,
      fields: ["username", "email", "fullname", "phone", "cpf"],
      values: [
        userData.user_username,
        userData.user_email,
        userData.user_fullname,
        userData.user_phone,
        userData.user_cpf,
      ],
    });
    return data;
  } catch (err: any) {
    throw err;
  }
};

/**
 * Alterar  usuario
 */
const updateProfilePicture = async (file: any) => {
  try {
    let data = await apiService.postRequest(
      "/user/updateThumbnail",
      {
        user_token: getCurrentUser()?.token,
        image_data: file,
      },
      {
        headers: {
          Authorization: `Bearer ${getAccessToken()}`,
          // "refresh-token": getRefreshToken(),
          "content-type": "multipart/form-data",
        },
        timeout: REQUEST_TIMEOUT,
      }
    );

    await userService.refreshToken();
    return data;
  } catch (err: any) {
    throw err;
  }
};

/**
 * Alterar senha do usuario
 */
const changePassword = async (newPassword: string, userToken?: string) => {
  try {
    let data = await apiService.postRequest("/user/updatePassword", {
      user_token: userToken ?? getCurrentUser()?.token,
      new_password: newPassword,
    });
    return data;
  } catch (err: any) {
    throw err;
  }
};

/**
 * Adicionar endereço
 */
const addAddress = async (userAddress: Address) => {
  try {
    let data = await apiService.postRequest("/user/addAddress", {
      user_token: getCurrentUser()?.token,
      fields: [
        "address_zip_code",
        "address_1",
        "address_2",
        "address_city",
        "address_state",
        "address_country",
      ],
      values: [
        userAddress.zip_code,
        userAddress.address_1,
        userAddress.address_2,
        userAddress.city,
        userAddress.state,
        userAddress.country,
      ],
    });

    await userService.refreshToken();
    return data;
  } catch (err: any) {
    throw err;
  }
};

/**
 * Alterar endereço
 */
const updateAddress = async (userAddress: Address) => {
  try {
    let data = await apiService.postRequest("/user/updateAddress", {
      user_token: getCurrentUser()?.token,
      address_id: userAddress.address_id,
      fields: [
        "address_zip_code",
        "address_1",
        "address_2",
        "address_city",
        "address_state",
        "address_country",
      ],
      values: [
        userAddress.zip_code,
        userAddress.address_1,
        userAddress.address_2,
        userAddress.city,
        userAddress.state,
        userAddress.country,
      ],
    });
    await userService.refreshToken();
    return data;
  } catch (err: any) {
    throw err;
  }
};

/**
 * Retorna a lista de projetos do usuário
 */
const listUserProjects = async (type: string = "all", eventId?: string) => {
  const user = userService.getCurrentUser();
  try {
    if (eventId) {
      let data = await apiService.postRequest("/user/listProjectsByEvent", {
        user_token: user?.token,
        event_id: eventId,
      });
      data.feedback.data = JSON.parse(data.feedback.data) ?? {
        projects: [],
      };

      const list: Project[] = data.feedback.data?.projects.map((p: any) => {
        return new Project({
          id: p._id.$oid,
          meta: new MetaProject(
            p.metaProject.title,
            ProjectType[p.metaProject.type as keyof typeof ProjectType],
            p.metaProject.description,
            p.metaProject.thumbnail,
            new Date(p.metaProject.creationDate.$date),
            new Date(p.metaProject.lastModified.$date)
          ),
          permissions: new ProjectPermissions(
            p.permissions.share,
            p.permissions.download,
            p.permissions.edit
          ),
          simInfo: new SimInfo("", "127.0.0.1", 10000, "/coppeliaSim", 19999),
        });
      });
      data.feedback.data.projects = list;

      return data;
    }

    let data = await apiService.postRequest("/user/listProjects/" + type, {
      user_token: user?.token,
    });

    data.feedback.data = JSON.parse(data.feedback.data) ?? {
      projects: [],
    };

    const list: Project[] = data.feedback.data?.projects.map((p: any) => {
      return new Project({
        id: p._id.$oid,
        meta: new MetaProject(
          p.metaProject.title,
          ProjectType[p.metaProject.type as keyof typeof ProjectType],
          p.metaProject.description,
          p.metaProject.thumbnail,
          new Date(p.metaProject.creationDate.$date),
          new Date(p.metaProject.lastModified.$date)
        ),
        permissions: new ProjectPermissions(
          p.permissions.share,
          p.permissions.download,
          p.permissions.edit
        ),
        simInfo: new SimInfo("", "127.0.0.1", 10000, "/coppeliaSim", 19999),
      });
    });
    data.feedback.data.projects = list;

    return data;
  } catch (err: any) {
    throw err;
  }
};

const listGroupProjects = async (event_id: string) => {
  const user = userService.getCurrentUser();
  try {
    let data = await apiService.postRequest("/user/listGroupProjectsByEvent", {
      user_token: user?.token,
      event_id,
    });

    data.feedback.data.myProjects = JSON.parse(
      data.feedback.data.myProjects
    ) ?? { projects: [] };
    data.feedback.data.groupProjects = JSON.parse(
      data.feedback.data.groupProjects
    ) ?? { projects: [] };

    const myProjects: Project[] = data.feedback.data?.myProjects.projects.map(
      (p: any) => {
        return new Project({
          id: p._id.$oid,
          meta: new MetaProject(
            p.metaProject.title,
            ProjectType[p.metaProject.type as keyof typeof ProjectType],
            p.metaProject.description,
            p.metaProject.thumbnail,
            new Date(p.metaProject.creationDate.$date),
            new Date(p.metaProject.lastModified.$date),
            p.metaProject.metaDropsVersion ?? "",
            p.metaProject.owner.$oid ?? ""
          ),
          permissions: new ProjectPermissions(
            p.permissions.share,
            p.permissions.download,
            p.permissions.edit
          ),
          simInfo: new SimInfo("", "127.0.0.1", 10000, "/coppeliaSim", 19999),
        });
      }
    );

    data.feedback.data.myProjects = myProjects;

    const groupProjects: Project[] =
      data.feedback.data?.groupProjects.projects.map((p: any) => {
        return new Project({
          id: p._id.$oid,
          meta: new MetaProject(
            p.metaProject.title,
            ProjectType[p.metaProject.type as keyof typeof ProjectType],
            p.metaProject.description,
            p.metaProject.thumbnail,
            new Date(p.metaProject.creationDate.$date),
            new Date(p.metaProject.lastModified.$date),
            p.metaProject.metaDropsVersion ?? "",
            p.metaProject.owner.$oid ?? ""
          ),
          permissions: new ProjectPermissions(
            p.permissions.share,
            p.permissions.download,
            p.permissions.edit
          ),
          simInfo: new SimInfo("", "127.0.0.1", 10000, "/coppeliaSim", 19999),
        });
      });

    data.feedback.data.groupProjects = groupProjects;
    return data;
  } catch (err: any) {
    throw err;
  }
};

/**
 * Retorna a lista de eventos do usuário
 */
const listUserEvents = async (eventType?: EventType) => {
  const user = userService.getCurrentUser();
  const body = {
    user_token: user?.token,
  };
  try {
    let data = await apiService.postRequest(
      "/user/listEvents/" + eventType ?? "all",
      body
    );
    if(data?.feedback?.data){
      data.feedback.data = JSON.parse(data.feedback.data);
      data.feedback.data.staff = data.feedback.data.staff?.map((p: any) => {
        p.metaEvent.id = p._id.$oid;
        p.rules.dueSubmissionDate = new Date(p.rules.dueSubmissionDate.$date);
        p.rules.endRegistrationDate = new Date(p.rules.endRegistrationDate.$date);
        p.rules.startRegistrationDate = new Date(
          p.rules.startRegistrationDate.$date
        );
        p.rules.startSubmissionDate = new Date(p.rules.startSubmissionDate.$date);
        return new DropsEvent(
          new MetaEvent(p.metaEvent),
          new EventRules(p.rules),
          undefined,
          new EventProperties({
            roles: p.properties.roles,
            archived: p.properties.archived,
          })
        );
      });
      data.feedback.data.registered = data.feedback.data.registered?.map(
        (p: any) => {
          p.metaEvent.id = p._id.$oid;
          p.rules.dueSubmissionDate = new Date(p.rules.dueSubmissionDate.$date);
          p.rules.endRegistrationDate = new Date(
            p.rules.endRegistrationDate.$date
          );
          p.rules.startRegistrationDate = new Date(
            p.rules.startRegistrationDate.$date
          );
          p.rules.startSubmissionDate = new Date(
            p.rules.startSubmissionDate.$date
          );
          return new DropsEvent(
            new MetaEvent(p.metaEvent),
            new EventRules(p.rules),
            undefined,
            new EventProperties({
              roles: p.properties.roles,
              archived: p.properties.archived,
              group_id: p.properties.group_id,
            })
          );
        }
      );
    }
    return data;
  } catch (err: any) {
    throw err;
  }
};

/**
 * Retorna a lista de instituicoes do usuário
 */
const listUserInstitutions = async (userToken?: string) => {
  const user = userService.getCurrentUser();
  const body = {
    user_token: userToken ?? user?.token,
  };

  try {
    let data = await apiService.postRequest("/user/listInstitutions/all", body);
    return data;
  } catch (err: any) {
    throw err;
  }
};

/**
 * Retorna os usuarios por id
 */
const listUsersById = async (users: string[]) => {
  try {
    try {
      let data = await apiService.postRequest("/user/getUsersById", {
        users: users,
      });
      data.feedback.data = data.feedback.data.map((data: any) =>
        JSON.parse(data)
      );
      return data;
    } catch (err) {
      throw err;
    }
  } catch (err: any) {
    throw err;
  }
};

/**
 * Retorna a lista de projetos do usuário
 */
const listUsersByFilter = async (values?: {
  filter?: string;
  page?: number;
  count?: number;
}) => {
  try {
    try {
      const data = await apiService.postRequest("/user/getUsersByFilter", {
        filter: values?.filter,
        page: values?.page,
        count: values?.count,
      });
      return data;
    } catch (err) {
      throw err;
    }
  } catch (err: any) {
    throw err;
  }
};

type accessTokenDecoded = {
  exp: number;
  key: string;
};

/**
 * Retorna o usuario logado a partir do access token
 */
const getCurrentUser = () => {
  let access_token = localStorage.getItem(ACCESS_TOKEN_STORAGE);
  if (access_token === null) {
    return undefined;
  }

  try {
    const decoded: accessTokenDecoded = jwt_decoded(access_token);
    const key = process.env.REACT_APP_JWT_KEY;
    if (key === undefined) {
      return;
    }
    const wordArray = crypto.enc.Utf8.parse(key);
    const decrypted = crypto.AES.decrypt(decoded.key, wordArray, {
      mode: crypto.mode.ECB,
    });
    const data = decrypted.toString(crypto.enc.Utf8);
    return JSON.parse(data).data as UserAccessToken;
  } catch (err) {
    return undefined;
  }
};

/**
 * Retorna o usuario logado a partir do access token
 */
const sendInstitutionalRegistrationEmail = async (
  institution_id: string,
  email: string
) => {
  try {
    try {
      const data = await apiService.postRequest(
        "/user/institutionalEmail",
        {
          institution_id,
          email,
        },
        {
          timeout: REQUEST_TIMEOUT,
        }
      );
      return data;
    } catch (err) {
      throw err;
    }
  } catch (err: any) {
    throw err;
  }
};

/**
 * Retorna o usuario logado a partir do access token
 */
const confirmInsitutionEmail = async (signupCode: string) => {
  try {
    try {
      const data = await apiService.getRequest(
        "/user/institutionalEmail/confirm?signupCode=" + signupCode,
        {
          timeout: REQUEST_TIMEOUT,
        }
      );
      return data;
    } catch (err) {
      throw err;
    }
  } catch (err: any) {
    throw err;
  }
};

/**
 * Retorna o usuario logado a partir do access token
 */
const getUnit = async () => {
  const user = userService.getCurrentUser();
  const body = {
    user_token: user?.token,
  };
  try {
    try {
      const data = await apiService.postRequest(
        "/user/listUnit",
        body
      );
      return data;
    } catch (err) {
      throw err;
    }
  } catch (err: any) {
    throw err;
  }
};

/**
 * Retorna se deve manter a sessão do usuário
 */
const isKeepSession = () => {
  return localStorage.getItem(KEEP_SESSION_STORAGE) === "true";
};

/**
 * Retorna o access token salvo no localStorage
 */
const getAccessToken = () => localStorage.getItem(ACCESS_TOKEN_STORAGE);
/**
 * Retorna o access token salvo no localStorage
 */
const getRefreshToken = () => localStorage.getItem(REFRESH_TOKEN_STORAGE);

/**
 * Retorna se a mensagem de resposta da api foi um erro de auth expirado
 */
const isExpiredAuth = (data: ApiResponse) => {
  if (data.feedback !== undefined) {
    return data.feedback.tag === "expiredAuth";
  } else {
    return false;
  }
};

/* Retorna os dados de um grupo a partir do evento associado a ele e do usuário (líder) */
const getGroupDataByEventId = async (eventId: string) => {
  const body = {
    user_token: getCurrentUser()?.token,
    event_id: eventId,
  };
  try {
    let data = await apiService.postRequest("/user/groupDataByEventId", body);
    const dataObj = JSON.parse(data.feedback.data);
    const _groupMeta: MetaGroup = new MetaGroup({
      name: dataObj.metaGroup.name,
      creationDate: new Date(dataObj.metaGroup.creationDate.$date),
      lastModified: new Date(dataObj.metaGroup.lastModified.$date),
      leader: dataObj.metaGroup.leader.$oid,
      shortDescription: dataObj.metaGroup.shortDescription,
      thumbnail: dataObj.metaGroup.thumbnail,
      localization: {
        city: dataObj.metaGroup.localization.city,
        state: dataObj.metaGroup.localization.state,
        country: dataObj.metaGroup.localization.country,
      },
      metaDropsVersion: dataObj.metaGroup.metaDropsVersion,
    });
    const _groupProperties = new GroupProperties({
      event_id: dataObj.properties.event_id.$oid,
      invites: dataObj.properties.invites,
    });
    const _groupData: GroupData = new GroupData({
      meta: _groupMeta,
      submissions: dataObj.submissions,
      users: dataObj.users.map((user: any) => user.$oid),
      properties: _groupProperties,
      _id: dataObj._id.$oid,
    });

    data.feedback.data = _groupData;

    return data;
  } catch (err) {
    throw err;
  }
};

const setEventArchived = async (eventId: string, archivedStatus: boolean) => {
  const user = userService.getCurrentUser();
  const body = {
    user_token: user?.token,
    event_id: eventId,
    archive: archivedStatus,
  };
  try {
    const data = await apiService.postRequest("/user/archive/Tournament", body);
    return data;
  } catch (err) {
    throw err;
  }
};

/* Retorna o token do lider do grupo */
const getLeaderToken = async (groupId: string) => {
  const body = {
    group_id: groupId,
  };
  try {
    const data = await apiService.postRequest("/user/getLeaderToken", body);
    return data;
  } catch (err) {
    throw err;
  }
};

/* Retorna se o usuario é líder do seu grupo dentro de um evento */

const isLeader = async (groupId: string) => {
  const body = {
    user_token: getCurrentUser()?.token,
    group_id: groupId,
  };
  try {
    const data = await apiService.postRequest("/user/isLeader", body);
    return data;
  } catch (err) {
    throw err;
  }
};

const userService = {
  signup,
  login,
  logout,
  getUserData,
  getUsersById,
  getUsers,
  verify,
  refreshToken,
  forgot,
  updateUser,
  updateProfilePicture,
  addAddress,
  updateAddress,
  changePassword,
  listUserProjects,
  listGroupProjects,
  listUserEvents,
  listUsersById,
  listUsersByFilter,
  listUserInstitutions,
  sendInstitutionalRegistrationEmail,
  confirmInsitutionEmail,
  getUnit,
  getCurrentUser,
  isKeepSession,
  getAccessToken,
  getRefreshToken,
  isExpiredAuth,
  getGroupDataByEventId,
  setEventArchived,
  getLeaderToken,
  isLeader,
};

export default userService;
