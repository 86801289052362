import Blockly from "blockly";
import { blockColor, addSeparator } from "../../BlockProperties";
import i18n from "../../../translations/i18n";
import BlocklyPY from "blockly/python";
import "./pythonCode";

//================================================================================================================
//================================================= Dobot BLOCKS =================================================
//================================================================================================================

Blockly.Blocks["dobot_instantiation_block"] = {
  init: function () {
    this.appendDummyInput("class_type")
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(addSeparator("Dobot"));
    this.appendValueInput("name")
      .setCheck("String")
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(i18n.t("PLACEHOLDER_NAME")  + ":");
    this.appendValueInput("uid")
      .setCheck("Number")
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(i18n.t("PLACEHOLDER_UID") + ":");
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(blockColor("parameters"));
    // this.setTooltip("Instantiates a Dobot Magician robot");
    this.setTooltip(i18n.t("DOBOT_INSTANTIATE_TOOLTIP"));
    this.setHelpUrl("");
  },
};

Blockly.Blocks["dobot_setjointangle_block"] = {
  init: function () {
    this.appendDummyInput()
      .setAlign(Blockly.ALIGN_CENTRE)
      //.appendField(addSeparator("set joint angle"));
      .appendField(addSeparator(i18n.t("DOBOT_SET_JOINT_ANGLE")));
    this.appendValueInput("joint")
      .setCheck("Number")
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(i18n.t("PLACEHOLDER_JOINT") + ":")
    this.appendValueInput("angle")
      .setCheck("Number")
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(i18n.t("PLACEHOLDER_ANGLE") + ":")
    this.appendDummyInput()
      .appendField(
        new Blockly.FieldDropdown([
          [i18n.t("PLACEHOLDER_DEGREES"), "'deg'"],
          [i18n.t("PLACEHOLDER_RADIANS"), "'rad'"],
        ]),
        "unit"
      );
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(blockColor("actions"));
    this.setTooltip(i18n.t("DOBOT_SET_JOINT_ANGLE_TOOLTIP"));
    this.setHelpUrl("");
  },
};

Blockly.Blocks["dobot_setjointangles_block"] = {
  init: function () {
    this.appendDummyInput()
      //.setAlign(Blockly.ALIGN_CENTRE)
      .appendField(addSeparator(i18n.t("DOBOT_SET_JOINT_ANGLES")));
    this.appendValueInput("angles")
      .setCheck("Array")
      //.setAlign(Blockly.ALIGN_RIGHT)
      .appendField(i18n.t("PLACEHOLDER_ANGLES") + ":")
    this.appendDummyInput()
      .appendField(
        new Blockly.FieldDropdown([
          [i18n.t("PLACEHOLDER_DEGREES"), "'deg'"],
          [i18n.t("PLACEHOLDER_RADIANS"), "'rad'"],
        ]),
        "unit"
      );
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(blockColor("actions"));
    this.setTooltip(i18n.t("DOBOT_SET_JOINT_ANGLES_TOOLTIP"));
    this.setHelpUrl("");
  },
};

Blockly.Blocks["dobot_getjointangle_block"] = {
  init: function () {
    this.appendDummyInput()
      .appendField(i18n.t("DOBOT_GET_JOINT_ANGLE") + " "+ i18n.t("PLACEHOLDER_IN") )
      .appendField(
        new Blockly.FieldDropdown([
          [i18n.t("PLACEHOLDER_DEGREES"), "'deg'"],
          [i18n.t("PLACEHOLDER_RADIANS"), "'rad'"],
        ]),
        "unit"
      )
      .appendField(addSeparator(""));
    this.appendValueInput("joint")
      .setCheck("Number")
      .appendField(i18n.t("PLACEHOLDER_JOINT") + ":")
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(blockColor("sensing"));
    this.setTooltip(i18n.t("DOBOT_GET_JOINT_ANGLE_TOOLTIP"));
    this.setHelpUrl("");
  },
};

Blockly.Blocks["dobot_getjointsangles_block"] = {
  init: function () {
    this.appendDummyInput()
      .appendField(i18n.t("DOBOT_GET_JOINT_ANGLES") + " "+ i18n.t("PLACEHOLDER_IN") )
      .appendField(new Blockly.FieldDropdown([[i18n.t("PLACEHOLDER_DEGREES"), "'deg'"],[i18n.t("PLACEHOLDER_RADIANS"), "'rad'"]]),"unit");
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(blockColor("sensing"));
  this.setTooltip(i18n.t("DOBOT_GET_JOINT_ANGLES_TOOLTIP"));
  this.setHelpUrl("");
  },
};

Blockly.Blocks["dobot_setsuctioncupstate_block"] = {
  init: function () {
    this.appendDummyInput()
      .setAlign(Blockly.ALIGN_CENTRE)
      .appendField(addSeparator(i18n.t("DOBOT_SET_SUCTION_CUP_STATE")))
      .appendField(i18n.t("PLACEHOLDER_STATE") + ":")
      .appendField(
        new Blockly.FieldDropdown([
          [i18n.t("PLACEHOLDER_ON"), "1"],
          [i18n.t("PLACEHOLDER_OFF"), "0"],
        ]),
        "state");
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(blockColor("actions"));
    this.setTooltip(i18n.t("DOBOT_SET_SUCTION_CUP_STATE_TOOLTIP"));
    this.setHelpUrl("");
  },
};
