import { TRANSLATIONS } from "../translations_template";

const BLOCKLY_TRANSLATIONS_EN = {
  PLACEHOLDER_LOGIC: "Logic",
  PLACEHOLDER_LOOPS: "Loops",
  PLACEHOLDER_MATH: "Math",
  PLACEHOLDER_TEXT: "Text",
  PLACEHOLDER_LISTS: "Lists",
  PLACEHOLDER_COLOUR: "Colour",
  PLACEHOLDER_RESOURCES: "Resources",
  PLACEHOLDER_BASIC: "Basic",
  PLACEHOLDER_ADVANCED: "Advanced",
  PLACEHOLDER_TIME: "Time",
  PLACEHOLDER_SCORE: "Score",
  PLACEHOLDER_CONTROLS: "Controls",
  PLACEHOLDER_SIMULATION: "Simulation",
  PLACEHOLDER_ROBOTS: "Robots",
  PLACEHOLDER_NON_MOBILE: "Non mobile",
  PLACEHOLDER_MOBILE: "Mobile",
  PLACEHOLDER_SENSORS: "Sensors",
  PLACEHOLDER_SUPERVISOR: "Supervisor",
  PLACEHOLDER_COLORSENSOR: "Colour Sensor",
  PLACEHOLDER_RANGESENSOR: "Range Sensor",
  PLACEHOLDER_PHOTOELECTRICSENSOR: "Photoelectric Sensor",
  PLACEHOLDER_VARIABLES: "Variables",
  PLACEHOLDER_FUNCTIONS: "Functions",
  PLACEHOLDER_ACTUATORS: "Actuators",
  PLACEHOLDER_CONVEYOR: "Conveyor Belt",
  PLACEHOLDER_SPAWNER: "Disc Dispenser",
  PLACEHOLDER_STOPWATCH: "Stopwatch",
  PLACEHOLDER_ACTIONS: "Actions",
  PLACEHOLDER_SENSING: "Sensing",
  PLACEHOLDER_PARAMETERS: "Parameters",

  PLACEHOLDER_NAME: "name",
  PLACEHOLDER_TYPE: "type",
  PLACEHOLDER_UID: "UID",
  PLACEHOLDER_INDEX: "index",
  PLACEHOLDER_IN: "in",
  PLACEHOLDER_TO: "to",
  PLACEHOLDER_JOINT: "joint",
  PLACEHOLDER_ANGLE: "angle",
  PLACEHOLDER_ANGLES: "angles",
  PLACEHOLDER_UNIT: "unit",
  PLACEHOLDER_STATE: "state",
  PLACEHOLDER_LEFTWHEEL: "left wheel",
  PLACEHOLDER_RIGHTWHEEL: "right wheel",
  PLACEHOLDER_CENTER: "center",
  PLACEHOLDER_AXIS: "axis",
  PLACEHOLDER_VELOCITY: "velocity",
  PLACEHOLDER_DISTANCE: "distance",
  PLACEHOLDER_ON: "ON",
  PLACEHOLDER_OFF: "OFF",
  PLACEHOLDER_REVERSE: "REVERSE",
  PLACEHOLDER_FUNCTION: "function",
  PLACEHOLDER_INPUT: "input",
  PLACEHOLDER_OUTPUT: "output",
  PLACEHOLDER_VALUE: "value",
  PLACEHOLDER_START: "START",
  PLACEHOLDER_PAUSE: "PAUSE",
  PLACEHOLDER_STOP: "STOP",
  PLACEHOLDER_RESET: "RESET",
  PLACEHOLDER_DEGREES: "degrees",
  PLACEHOLDER_RADIANS: "radians",
  PLACEHOLDER_POSITION: "position",
  PLACEHOLDER_RIGHT: "right",
  PLACEHOLDER_LEFT: "left",
  PLACEHOLDER_ACTOR: "actor",

  TEXT_INPUT_OUTPUT: "Input/Output",

  MATH_FUNCS: "Math functions",
  MATH_CONVERT: "{{original}} to {{target}}",
  MATH_DEG_TO_RAD_TOOLTIP: "Convert an angle from degrees to radians.",
  MATH_RAD_TO_DEG_TOOLTIP: "Convert an angle from radians to degrees.",
  MATH_QUATERNION_TO_EULER: "quaternion to Euler",
  MATH_QUATERNION_TO_EULER_TOOLTIP: "Convert a quaternion to an array of Euler angles.",
  MATH_EULER_TO_QUATERNION: "Euler to quaternion",
  MATH_EULER_TO_QUATERNION_TOOLTIP: "Convert an array of Euler angles to quaternion.",
  MATH_CONVERT_LENGTH_METERS_TOOLTIP: "Convert a length in meters to another unit.",
  MATH_CONVERT_LENGTH_UNIT_TOOLTIP: "Convert a length from one unit to another.",
  MATH_CONVERT_TIME_SECONDS_TOOLTIP: "Convert time in seconds to another unit.",
  MATH_CONVERT_TIME_UNIT_TOOLTIP: "Convert time from one unit to another.",
  MATH_CONVERT_VELOCITY_METERSPERSEC_TOOLTIP: "Convert velocity in m/s to another unit.",
  MATH_CONVERT_VELOCITY_UNIT_TOOLTIP: "Convert a velocity from one unit to another.",

  RESOURCES_LINEBREAK: "linebreak",
  RESOURCES_LINEBREAK_TOOLTIP: "Make a linebreak",

  BASIC_DEFINE_ACTOR: "define ator",
  BASIC_DEFINE_ACTOR_TOOLTIP: "Receives an actor instance as a parameter and defines a variable with it.",
  BASIC_GET_ACTOR: "get this actor",
  BASIC_GET_ACTOR_TOOLTIP: "Retorns this actor.",

  ADVANCED_SET_INPUT: "set input",
  ADVANCED_SET_INPUT_TOOLTIP: "Defines an input signal.",
  ADVANCED_GET_INPUT: "get input",
  ADVANCED_GET_INPUT_TOOLTIP: "Returns an input signal",
  ADVANCED_SET_OUTPUT: "set output",
  ADVANCED_SET_OUTPUT_TOOLTIP: "Defines an output signal",
  ADVANCED_GET_INTERFACE: "get interface",
  ADVANCED_GET_INTERFACE_TOOLTIP: "Returns an interface with the given name.",
  ADVANCED_CREATE_GLOBAL_VAR: "create global variable",
  ADVANCED_CREATE_GLOBAL_VAR_TOOLTIP: "Creates a global variable at simulation context.",
  ADVANCED_CREATE_GLOBAL_OUT_INTERFACE: "create global interface",
  ADVANCED_CREATE_GLOBAL_OUT_INTERFACE_TOOLTIP: "Creates a global output interface at simulation context.",
  ADVANCED_GET_GLOBAL_VALUE: "get global value",
  ADVANCED_GET_GLOBAL_VALUE_TOOLTIP: "Returns the selected global variable value.",
  ADVANCED_SET_GLOBAL_VALUE: "set global value",
  ADVANCED_SET_GLOBAL_VALUE_TOOLTIP: "Creates the selected global variable value.",
  ADVANCED_GET_GLOBAL_INTERFACE_VALUE: "get global interface",
  ADVANCED_GET_GLOBAL_INTERFACE_VALUE_TOOLTIP: "Returns the selected global interface value.",
  ADVANCED_SET_GLOBAL_INTERFACE: "set global interface",
  ADVANCED_SET_GLOBAL_INTERFACE_TOOLTIP: "Creates the selected global interface value.",

  TIME_WAIT: "wait",
  TIME_WAIT_TOOLTIP: "The simulation waits for the specified time.",
  TIME_SLEEP: "sleep",
  TIME_SLEEP_TOOLTIP: "Code execution waits for the specified time.",

  SIMULATION_START: "start simulation",
  SIMULATION_PAUSE: "pause simulation",
  SIMULATION_STOP: "stop simulation",
  SIMULATION_GET_STATE: "get simulation state",
  SIMULATION_GET_STATE_TOOLTIP: "Returns the simulation state (0 - Stopped, 1 - Running, 2 - Paused).",
  SIMULATION_GET_TIME: "get simulation time",
  SIMULATION_GET_TIME_TOOLTIP: "Returns the simulation time in seconds, measured from an internal system.",
  SIMULATION_GET_REAL_TIME: "get real time",
  SIMULATION_GET_REAL_TIME_TOOLTIP: "Returns the real time spent by simulation, measured from operational system timer. It only works with real time flag activated.",

  SIMULATION_TIME_LOOP: "Time loop for",
  SIMULATION_TIME_LOOP_TOOLTIP: "Executes a loop during a certain simulation time.",

  SIMULATION_CONFIG_SCORE_SYSTEM: "Initialize score system",
  SIMULATION_CONFIG_SCORE_SYSTEM_TOOLTIP: "Initializes and configures the code score system.",
  SIMULATION_SET_SCORE: "set score to",
  SIMULATION_SET_SCORE_TOOLTIP: "Defines the current simulation score.",
  SIMULATION_GET_SCORE: "get score",
  SIMULATION_GET_SCORE_TOOLTIP: "Returns the current simulation score.",

  ROBOT2WHEELS_SETWHEELSVELOCITIES: "define wheel velocities",
  ROBOT2WHEELS_SETWHEELSVELOCITIES_TOOLTIP: "Defines the velocities of each of the two wheels from an array [left, right].",
  ROBOT2WHEELS_SETLEFTWHEELVELOCITY: "define left wheel velocity",
  ROBOT2WHEELS_SETLEFTWHEELVELOCITY_TOOLTIP: "Defines the left wheel velocity.",
  ROBOT2WHEELS_SETRIGHTWHEELVELOCITY: "define right wheel velocity",
  ROBOT2WHEELS_SETRIGHTWHEELVELOCITY_TOOLTIP: "Defines the right wheel velocity.",
  ROBOT2WHEELS_GETWHEELSVELOCITIES: "get wheels' velocities",
  ROBOT2WHEELS_GETWHEELSVELOCITIES_TOOLTIP: "Returns the velocities of each wheel from an array [left, right].",
  ROBOT2WHEELS_GETLEFTWHEELVELOCITY: "get left wheel velocity",
  ROBOT2WHEELS_GETLEFTWHEELVELOCITY_TOOLTIP: "Returns the left wheel velocity.",
  ROBOT2WHEELS_GETRIGHTWHEELVELOCITY: "get right wheel velocity",
  ROBOT2WHEELS_GETRIGHTWHEELVELOCITY_TOOLTIP: "Returns the right wheel velocity.",
  ROBOT2WHEELS_ROTATE: "rotate",
  ROBOT2WHEELS_ROTATE_TOOLTIP: "Rotate a robot until a given angle around a given axis (left wheel, right wheel or robot center) at a given velocity.",
  ROBOT2WHEELS_MOVE_FORWARD: "move forward",
  ROBOT2WHEELS_MOVE_FORWARD_TOOLTIP: "Move forward for a given distance at a given velocity.",

  LINEFOLLOWERBOT_INSTANTIATE_TOOLTIP: "Instanciate a LineFollowerBot robot.",
  LINEFOLLOWERBOT_GET_COLOR_TOOLTIP: "Returns the colour sensor reading (an array [R,G,B]) from a selected colour sensor.",
  LINEFOLLOWERBOT_GET_COLORS: "get colors",
  LINEFOLLOWERBOT_GET_COLORS_TOOLTIP: "Returns the colour sensor reading (an vector [R,G,B]) from the robot's color sensors in order [left, center, right]",
  LINEFOLLOWERBOT_GET_RANGE_TOOLTIP: 'Returns the range sensor reading.',
  LINEFOLLOWERBOT_GET_RANGES: 'get ranges',
  LINEFOLLOWERBOT_GET_RANGES_TOOLTIP: "Returns the values from range sensor reading in order [left, center, right].",

  NTUBOT_INSTANTIATE_TOOLTIP: "Instanciate a NTUbot robot.",

  PIONEER_INSTANTIATE_TOOLTIP: "Instanciate a Pioneer robot.",
  PIONEER_GET_RANGES: "get the detected ranges",
  PIONEER_GET_RANGES_TOOLTIP: "Returns robot's range sensors reading.",

  DOBOT_INSTANTIATE_TOOLTIP: "Instanciates a Dobot Magician robot",
  DOBOT_SET_JOINT_ANGLE: "set joint angle",
  DOBOT_SET_JOINT_ANGLE_TOOLTIP: "Defines the angle of a given joint of the robot.",
  DOBOT_SET_JOINT_ANGLES: "set joints angles",
  DOBOT_SET_JOINT_ANGLES_TOOLTIP: "Sets the angles of all joints.",
  DOBOT_GET_JOINT_ANGLE: "get joint angle",
  DOBOT_GET_JOINT_ANGLE_TOOLTIP: "Returns the angle of a given joint of the robot.",
  DOBOT_GET_JOINT_ANGLES: "get joints angles",
  DOBOT_GET_JOINT_ANGLES_TOOLTIP: "Sets the angles of all joints of the robot.",
  DOBOT_SET_SUCTION_CUP_STATE: "set suction cup state",
  DOBOT_SET_SUCTION_CUP_STATE_TOOLTIP: "Sets whether the sucion cup is active or not.",

  RANGESENSOR_INSTANTIATE_TOOLTIP: "Instanciates a range sensor.",
  RANGESENSOR_GET_RANGE: "get detected range",
  RANGESENSOR_GET_RANGE_TOOLTIP: "Returns the range sensor reading.",

  PHOTOELECTRICSENSOR_INSTANTIATE_TOOLTIP: "Instanciates a photoelectric sensor.",
  PHOTOELECTRICSENSOR_GET_RANGE: "get detected range",
  PHOTOELECTRICSENSOR_GET_RANGE_TOOLTIP: "Returns the range of photoelectric sensor reading.",
  PHOTOELECTRICSENSOR_HAS_DETECTED: "has detected?",
  PHOTOELECTRICSENSOR_HAS_DETECTED_TOOLTIP: "Check if detected some object inside its detection volume.",

  COLORSENSOR_INSTANTIATE_TOOLTIP: "Instanciates a colour sensor.",
  COLORSENSOR_GET_COLOR: "get colour (RGB)",
  COLORSENSOR_GET_HEX_COLOR: "get colour (HEX)",
  COLORSENSOR_GET_COLOR_TOOLTIP: "Returns the colour sensor reading (an array [R,G,B]) from a colour sensor.",
  COLORSENSOR_GET_HEX_COLOR_TOOLTIP: "Returns the colour sensor reading (an hexadecimal number) from a colour sensor.",
  COLORSENSOR_GET_GRAYSCALE_COLOR: "get average colour value",
  COLORSENSOR_GET_GRAYSCALE_COLOR_TOOLTIP: "Returns the grayscale color reading from a color sensor value ranging from 0 - 255.",

  CONVEYOR_INSTANTIATE_TOOLTIP: "Instanciates a conveyor belt.",
  CONVEYOR_SET_STATE: "set conveyor belt state",
  CONVEYOR_SET_STATE_TOOLTIP: "Sets whether the conveyor belt is active or not.",

  SPAWNER_INSTANTIATE_TOOLTIP: "Instanciates a disc dispenser.",
  SPAWNER_SET_STATE: "set disc dispenser state",
  SPAWNER_SET_STATE_TOOLTIP: "Sets whether the disc dispenser is active or not.",

  STOPWATCH_INSTANTIATE_TOOLTIP: "Instanciates a stopwatch.",
  STOPWATCH_SET_STATE: "set stopwatch state",
  STOPWATCH_SET_STATE_TOOLTIP: "Sets stopwatch states.",
  STOPWATCH_GET_TIME: "get time",
  STOPWATCH_GET_TIME_TOOLTIP: "Returns simulation time in seconds.",
  STOPWATCH_GET_TIME_FORMAT: "get time string",
  STOPWATCH_GET_TIME_FORMAT_TOOLTIP: "Returns simulation time at HH:MM:SS.sss format.",

  SUPERVISOR_INSTANTIATE_TOOLTIP: "Instanciates a supervisor",
  SUPERVISOR_GET_ACTOR: "get actor",
  SUPERVISOR_GET_ACTOR_TOOLTIP: "Returns an actor by name",
  SUPERVISOR_CONNECT_INTERFACES: "connect interfaces",
  SUPERVISOR_CONNECT_INTERFACES_TOOLTIP: "Connect an input interface to an output interface.",
  
  TEXT_ACTOR_TOOLTIP: "Returns the name of an actor in the scene",
}

export const TRANSLATIONS_EN: TRANSLATIONS = {

  commons: {
    Language: "Language",
    submission: "submission",
    copy: "copy",
    event: "event",
    member: "member",
    members: "member(s)",
    group: "mroup",
    Leader: "Leader",
    Optional: "Optional",
    defaultName_male: "My {{projectType}}",
    defaultName_female: "My {{projectType}}",
    Loading: "Loading",
    Unnamed: "Unnamed",
    Untitled: "Untitled",
    Status: "Status",
    Information: "Information",
    image: "image",
    userData: {
      username: "Username",
      email: "e-mail",
      Email: "E-mail",
      fullname: "Full name",
      phone: "Phone",
      zipcode: "ITIN",
    },
    userAddress: {
      zipcode: "ZIP",
      address1: "Address",
      address2: "Address complement",
      city: "City",
      state: "State",
      country: "Country"
    },
    institution: {
      institution: "Institution",
      unit: "Unit",
      taxIdNumber: "Tax ID number",
      segment: "Segment"
    },
    msgs: {
      modal: {
        deleteEventWarning: "After performing this action, the event will be deleted and will not be accessible to anyone anymore. Are you sure you want to proceed?",
        deleteProjectWarning: "After performing this action, the project will be deleted and cannot be recovered. Are you sure you want to proceed?",
        deleteSubmissionWarning: "After performing this action, the submission will be deleted and will not be considered for assessment. Are you sure you want to proceed?",
        deleteTemplateWarning: "After performing this action, the template will be deleted and will no longer appear to participants of this event. Are you sure you want to proceed?",
        archiveEventWarning: "When the event is archived, it will not appear on your event list. Are you sure you want to proceed?",
        unarchiveEventWarning: "After unarchiving this event, it will be back in your event list. Are you sure you want to proceed?",
        sendTemplateWarning: "After performing this action, the template will be available to the participants of this event so they can create projects from it. Are you sure you want to proceed?",
        submitExperimentWarning: "After performing this action, the experiment will be submitted for evaluation. Are you sure you want to proceed?"
      },
      openedUnity: "Simulation loaded!",
      openingUnity: "Loading simulation ...",
      emptyList: "No item to display",
      create: "Create {{target}}",
      created_male: "{{target}} created successfully!",
      created_female: "{{target}} created successfully!",
      creating: "Creating {{target}} ...",
      edit: "Edit {{target}}",
      editing: "Editing {{target}} ...",
      edited_male: "{{target}} edited successfully!",
      edited_female: "{{target}} edited successfully!",
      delete: "Delete {{target}}",
      deleted_male: "{{target}} deleted successfully!",
      deleted_female: "{{target}} deleted successfully!",
      deleting: "Deleting {{target}} ...",
      update: "Update {{project}}",
      updating: "Updating {{project}} ...",
      updated_male: "{{project}} updated successfully!",
      updated_female: "{{project}} updated successfully!",
      copy: "Copy {{project}}",
      copying: "Copying {{project}} ...",
      copied: "{{project}} copied successfully!",
      archive: "Archive {{}}",
      archiving: "Archiving {{}} ...",
      archived: "{{}} archived!",
      unarchive: "Unarchive {{}}",
      unarchiving: "Unarchiving {{}} ...",
      unarchived: "{{}} unarchived!",
      share: "Share {{item}}",
      sharing: "Sharing {{item}} ...",
      shared: "{{item}} shared!",
      searching: "Searching for {{}} ...",
      found: "{{}} found!",
      noTemplatesToShow: "No templates to show"
    },
    actions: {
      backToHome: "Back to Home",
      confirm: "Confirm",
      cancel: "Cancel",
      open: "Open",
      close: "Close",
      export: "Export",
      load: "Load",
      loading: "Loading",
      loaded: "Loaded",
      create: "Create",
      creating: "Creating",
      created: "Created",
      editing: "Editing",
      edit: "Edit",
      edited: "Edited",
      updating: "Updating",
      update: "Update",
      updated: "Updated",
      delete: "Delete",
      subscribe: "Subscribe",
      publish: "Publish",
      finish: "Finish",
      next: "Next",
      back: "Back",
      send: "Send",
      resend: "Resend",
      sendingEmail: "Sending email",
      emailSent: "Email sent",
      copied: "Copied",
      search: "Search"
    },
    password: {
      placeholder: "password",
      placeholder_uppercase: "Password",
      confirm: "Confirm password",
      new: "New password",
    },
    request: {
      placeholder: "request",
      successful: "{{request}} succesful!",
      failed: "{{request}} failed!",
      pending: "Performing {{request}} ...",
    },
    projectInfo: {
      projectName: "Project name",
      description: "Description",
      creationDate: "Creation date",
      lastModified: "Last modified",
      models: "Models",
    },
    projectTypes: {
      project: "project",
      classroom: "classroom",
      tournament: "tournament",
      challenge: "challenge",
      tutorial: "tutorial",
      template: "template",
    },
    projectRoles: {
      project: "project",
      experiment: "experiment",
      draft: "draft"
    },
    LicenseType: {
      Root: "Root",
      Admin: "Admin",
      Manager: "Manager",
      Professor: "Professor",
      Organizer: "Organizer",
      Instructor: "Instructor",
      Student: "Student",
      Member: "Member",
      Individual: "Individual",
      Guest: "Guest"
    },
    eventStatus: {
      UnderConstruction: "Under construction",
      WaitingApproval: "Waiting for approval",
      Approved: "Approved",
      Published: "Published",
      InProgress: "In progress",
      Closed: "Closed",
      WaitingScores: "Waiting for scores",
      Canceled: "Canceled"
    },
    eventTypes: {
      classroom: "classroom",
      tournament: "tournament",
      challenge: "challenge",
    },
    resourceTypes: {
      DobotV3: "Dobot",
      NTUbot: "NTUbot",
      PioneerV2: "Pioneer",
      ConveyorM: "Conveyor Belt",
      Spawner2: "Spawner",
      Stopwatch: "Stopwatch",
      colorSensor: "Color Sensor",
      rangeSensor: "Range Sensor",
      GreenBox: "Green Box",
      RedBox: "Red Box",
      Plant: "Plant",
      Chair: "Chair",
      Table: "Table",
      Path: "Path",
      Path2: "Path 2",
      Sphere: "Sphere",
      Cuboid: "Cuboid",
      Cylinder: "Cylinder",
      Supervisor: "Supervisor",
      photoelectricSensor: "Photoeletric Sensor",
      LineFollowerBot: "Line Follower Bot",
      Border: "Border",
      Maze: "Maze",
      cameraSensor: "Camera",
      Plane: "Plane",
      Disc: "Disc",
      Cone: "Cone",
      Capsule: "Capsule",
      MediumConveyorBelt: "Conveyor Belt",
    },
    modelTypes: {
      Actuator: "Actuators",
      Sensor: "Sensors",
      Object: "Objects",
      Primitive: "Primitives",
      Robot: "Robots"
    },
    modelMotionProps: {
      static: "Statics",
      dynamic: "Dynamics"
    },
    time: {
      secondsAgo: "{{seconds}} seconds ago",
      minutesAgo: "{{minutes}} minutes ago",
      hoursAgo: "{{hours}} hours ago",
      daysAgo: "{{days}} days ago",
      monthsAgo: "{{months}} months ago",
      yearsAgo: "{{years}} years ago",
    },
    timeUnit: {
      days: "day(s)",
      months: "month(s)",
      years: "year(s)"
    },
    paymentPeriod: {
      daily: "day",
      monthly: "month",
      yearly: "year"
    },
    form: {
      name: "name",
      description: "description",
      initials: "initials",
      code: "code",
      type: "type"
    },
    unit: {
      professors: "profesores",
      organizers: "organizadores",
      instructors: "instructores",
      type: {
        head: "head",
        branch: "branch",
        franchise: "franchise",
        department: "department",
        campus: "campus"
      }
    },
    units: "units"
  },
  components: {
    acceptProjectCopy: {
      header: "Accept project copy?",
      instructions: "{{user}} wants to send you a copy of the project {{projectName}}. Do you wish to accept?"
    },
    blockly: {
      blocklyScreen: {
        loadingCode: "Loading model code",
        failedToLoadCode: "Error while loading model code. Try again later.",
        saveCode: "Save code",
        resetCode: "Reset code",
        seeCode: "Python code",
        downloadScreenshot: "Download screenshot",
        resetModal: {
          message: "By doing this action, all code will be lost and cannot be recovered. Are you sure you whant to proceed?"
        }
      },
    },
    // changePassword: {
    //   header: "Change my password",
    //   pending: "Changing password ...",
    //   successful: "Password changed successfully!",
    //   failed: "Password change failed!"
    // },
    commons: {
      uploadFile: {
        removeFile: "Remove file",
        sendFile: "Send projects's file"
      },
      uploadImage: {
        image: "image",
        tooltip: "Send image",
        successful: "Image uploaded successfully!",
        failed: "Image failed to upload!",
        pending: "Image is being uploaded ...",
        sendNew: "Send new profile picture",
        remove: "Remove profile picture"
      },
      userSelection: {
        searchUsersTitle: "Select users",
        selectedUsersTitle: "Selected users",
        usernamePlaceholder: "Username or email"
      }
    },
    credits: {
      header: "Credits"
    },
    events: {
      actions: {
        updateEvent: "Update event",
        updatingEvent: "Updating event ...",
        updatedEvent: "Event updated sucessfully!",
        createEvent: "Create event",
        copyEvent: "Copy event",
        creatingEvent: "Creating event ...",
        createdEvent: "Event created sucessfully!",
        classroom: {
          updateEvent: "Update classroom",
          updatingEvent: "Updating classroom ...",
          updatedEvent: "Classroom updated sucessfully!",
          createEvent: "Create classroom",
          copyEvent: "Copy classroom",
          creatingEvent: "Creating classroom ...",
          createdEvent: "Classroom created sucessfully!",
        },
        tournament: {
          updateEvent: "Update tournament",
          updatingEvent: "Updating tournament ...",
          updatedEvent: "Tournament updated sucessfully!",
          createEvent: "Create tournament",
          copyEvent: "Copy tournament",
          creatingEvent: "Creating tournament ...",
          createdEvent: "Tournament created sucessfully!",
        }
      },
      Event: {
        commons: {
          registrationDates: {
            header: "Registration dates",
            start: "Registration start date",
            end: "Registration end date",
          },
          submissionDates: {
            header: "Submission dates",
            start: "Submission start date",
            end: "Submission end date",
          },
          eventDates: {
            start: "Event start",
            end: "Event deadline"
          },
          classroomDates: {
            header: "Dates",
            release: "Release date",
            end: "End date"
          }
        },
        eventForm: {
          tournamentName: "Event name",
          classroomName: "Classroom name",
          tournamentType: "Tournament type",
          eventVisibility: {
            open: "Open",
            closed: "Closed"
          },
          shortDescription: "Short description",
          description: "Description",
          teamInfo: {
            header: "Team info",
            zeroIfUnlimitedValue: "Put zero if the value is unlimited.",
            maxTeamsAllowed: "Maximum number of teams allowed",
            maxUsersPerTeam: "Maximum number of users per team",
            maxSubmissions: "Maximum number of submissions"
          },
          classroomSettings: {
            header: "Students info",
            maxStudentsAllowed: "Maximum number of students"
          },
          payment: {
            header: "Payment",
            value: "Value",
            currency: "Currency",
            paymentType: "Payment type",
            paymentTypes: {
              Team: "Team",
              TeamMember: "TeamMember",
              Robot: "Robot"
            },
            currencyNames: {
              real: "Real",
              dolar: "Dolar"
            },
          },
          rules: {
            placeholder: "Rules",
            viewMarkdownTip: "View rules in markdown",
            viewFormatedTip: "View rules formated"
          },
          invites: {
            header: "Invites"
          }
        },
        eventFormSchema: {
          beforeRegistrationStart: "Must be before registration start date",
          beforeRegistrationEnd: "Must be before registration end date",
          beforeSubmissionsStart: "Must be before submissions start date",
          beforeSubmissionsEnd: "Must be before submissions end date",
          beforeRelease: "Must be before release date",
          beforeEnd: "Must be before the closing date",
          afterRegistrationStart: "Must be later than registration start date",
          afterRegistrationEnd: "Must be later than registration end date",
          afterSubmissionsStart: "Must be later than submissions start date",
          afterRelease: "Must be after release date",
          afterEnd: "Must be after the closing date",
          afterToday: "Must be after today",
          atLeastOneMember: "Each group should have at least one member",
          charsMissing: "{{number}} characters missing!",
          charsInExcess: "Exceeded in {{number}} characters!"
        },
        sharedInstitutions: {
          title: "Share event with institutions",
          description: "Select the institutions you wish to share your event with.",
          searchTitle: "Select institutions",
          selectedTitle: "Selected institutions",
          searchPlaceholder: "Institution name",
          updating: "Sharing event with selected institutions...",
          complete: "Event shared with selected institutions!"
        }
      },

      group: {
        groupTab: {
          editGroup: "Edit group",
          membersHeader: "Members",
          noUsersInGroup: "No users in group",
          unsubscribeGroup: "Unsubscribe group",
          unsubscribingGroup: "Unsubscribing group ...",
          unsubscribedGroup: "Unsubscribed group!",
          quitGroup: "Quit group",
          quitingGroup: "Quiting group ...",
          quited: "You left the group",
          addMember: "Add member",
          addingMember: "Adding member ...",
          addedMember: "Member added successfully!",
          removeMember: "Remove member",
          removingMember: "Removing member ...",
          removedMember: "Member removed successfully!",
          unsubscribeGroupWarning: "By completing this action, your group will be unsubscribed, all submissions and projects associated to it will be deleted and all of its members will lose access to the event.",
          unsubscribeMemberWarning: "By completing this action, the user{{member}} will be removed from the group and will lose access to the event.",
          unsubscribeSelfWarning: "By completing this action, you will leave the group and will lose access to the event.",
          areYouSure: "Are you sure you want to proceed?"
        },
        updateGroupForm: {
          groupName: "Group name",
        }
      },
      groupInfoTab: {
        noName: "No name",
        showSubmissions: "Show group submissions",
        seeSubmissions: "See submissions",
        noGroupsToShow: "No groups to show",
        noMembersToShow: "No members to show",
        creationDate: "Creation date:",
        Location: "Location",
        membersHeader: "Members",
        submissionsHeader: "Submissions"
      },
      submission: {
        rankingTab: {
          tableHeaders: {
            Position: "Position",
            GroupName: "Group name",
            Evaluation: "Evaluation",
            SubmissionDate: "Submission Date"
          },
          noRankingToShow: "No ranking to show"
        },
        submissionTab: {
          submissionStatus: {
            Review: "In review",
            NotGraded: "Waiting for evaluation",
            Graded: "Graded",
            Submitted: "Submitted"
          },
          startingAutoEvaluation: "Starting automatic evaluation",
          startedAutoEvaluation: "Automatic evaluation started!",
          tableHeaders: {
            ID: "Submission ID",
            projectName: "Project name",
            submittedBy: "Submitted by",
            submissionDate: "Submission date",
            status: "Status",
            evaluation: "Evaluation",
            actions: "Actions"
          },
          seeSubmission: "Visualize submission",
          revaluateSubmission: "Revaluate submission",
          noSubmissionToShow: "No submissions to show"
        },
      },
      templates: {
        templatesTab: {
          tableHeaders: {
            ID: "Template ID",
            templateName: "Template name",
            submissionDate: "Submission date",
            actions: "Actions"
          },
          noTemplatesToShow: "No templates to show"
        }
      },
      eventInfo: {
        subscribingEvent: "Subscribing event ...",
        subscribedEvent: "Subscribed event successfully!",
        publishingEvent: "Publishing event ...",
        publishedEvent: "Event published!",
        finishingEvent: "Finishing event ...",
        finishedEvent: "Event finished!",
        acceptRules_male: "I read and agree with the rules of the {{event}}.",
        acceptLeaderRole_male: "I accept creating and being the leader of a new group in the {{event}}.",
        acceptRules_female: "I read and agree with the rules of the {{event}}.",
        acceptLeaderRole_female: "I accept creating and being the leader of a new group in the {{event}}.",
        subscriptionStatus: {
          subscribed: "Subscribed",
          eventFull: "Full",
          outOfDeadline: "Registration date expired"
        },
        navBarTabs: {
          General: "General",
          Drafts: "Drafts",
          Experiments: "Experiments",
          Templates: "Templates",
          MyGroup: "My Group",
          Groups: "Groups",
          Submissions: "Submissions",
          Ranking: "Ranking",
          Invites: "Invites"
        }
      },
      eventList: {
        tabs: {
          MySubscriptions: "My subscriptions",
          Open: "Open",
          Closed: "Closed",
          MyTournaments: "My tournaments",
          MyClassrooms: "My classrooms"
        },
        hideArchived: "Hide archived",
        showArchived: "Show archived",
        archivedHeader: "Archived"
      },
      invites: {
        editInvitedUsers: "Edit invites",
      },
      copyEvent: {
        description: "Select one of the events from your unit to make a copy."
      }
    },
    home: {
      homeHeader: {
        reloadPage: "Reload page"
      },
      sidebar: {
        logout: "Log out",
        config: "Settings",
        comingSoon: "Soon",
      }
    },
    myAccount: {
      header: "My account",
      userInfo: {
        header: "User info",
        description: "Manage your user info, like name, e-mail, phone and ZIP code",
        updateUser: {
          pending: "Updating user...",
          successful: "User updated!",
          failed: "Error while updating user",
        }
      },
      addresses: {
        header: "My addresses",
        description: "Add or remove addresses",
        addAddress: {
          added: "Address added!",
          pending: "Adding address ..."
        },
        newAddress: "New address",
      },
      security: {
        header: "Security",
        description: "Manage your secutity settings, like passwords.",
        changePassword: {
          header: "Change my password",
          pending: "Changing password ...",
          successful: "Password changed successfully!",
          failed: "Password change failed!"
        }
      },
      myPlans: {
        header: "Plans and licenses",
        description: "View information about your license and your plan.",
        license: {
          header: "License"
        },
        plan: {
          header: "Plan",
          customPlan: "Custom plan",
          active: "Active",
          inactive: "Inactive",
          features: {
            header: "Features",
            seats: "seat(s)",
            licensesOf: "license(s) of",
            youHaveALicenseOf: "You have a license of {{license}}"
          },
          payments: {
            header: "Payments",
            cost: "Cost",
            expiresOn: "Expires on",
            renewedOn: "Renewed on",
            paymentMethod: "Payment method",
            paymentHistory: "Payment history",
            change: "Change",
            trialPeriodWarning: "This plan is in a trial period of {{duration}} {{unit}}"
            //Este plano está em um período de testes de 1 days.
          },
          paymentHistory: {
            empty: "No history to show",
            table: {
              headers: {
                paymentDate: "Payment date",
                paidBy: "Paid by",
                paymentMethod: "Payment method",
                value: "Value",
                discount: "Discount",
                total: "Total"
              }
            }
          }
        }
      }
    },
    login: {
      header: "Login",
      instructions: "Log in to gain access to the simulator.",
      pending: "Logging in ...",
      successful: "Login successful!",
      failed: "Login failed!",
      noAccount: "Don't have an account?",
      remindMe: "Remind me in this computer",
      forgotPassword: "Forgot my password",
      createAccount: "Create an account",
      signIn: "Log in",
    },
    passwordRecovery: {
      OtpPanel: {
        OtpButtons: {},
        codeExpires: "Code expires in {{sec}} seconds",
        resendOtp: "Resend code",
      },
      CodeInputForm: {
        otpCheck: {
          successful: "Code validation succeded!",
          failed: "Code validation failed!",
          pending: "Verifying code ...",
        },
      },
      CodeInputSchema: {
        codeRequired: "The code is required"
      },
      CodeInputScreen: {
        submitInstructions: "Please, enter the code sent to your email to perform your password reset.",
        recoveryHeader: "Password recovery",
      },
      SendCodeForm: {
        sendCode: "Send code",
      },
      SendCodeSchema: {},
      SendCodeScreen: {
        sendInstructions: "Please, log in with the registered email so that we can send you the instructions for reseting your password.",
        forgotPwd: "Forgot your password?",
      },
      commons: {
        otpSend: {
          successful: "Code sent successfully to your email!",
          failed: "Code could not be sent to your email!",
          pending: "Sending code ...",
        },
      }
    },
    projectManager: {},
    projectsSelection: {
      project: {
        actions: {
          create: "Create {{project}}",
          creating: "Creating {{project}} ...",
          created: "{{project}} created successfully!",
          edit: "Update {{project}}",
          editing: "Updating {{project}} ...",
          edited: "{{project}} updated successfully!",
        },
        projectForm: {
          templates_header: "Templates",
          templatesInstructions: "Select a template for the creation of the project.",
          templatesInstructions2: "A template allows users to work on a pre-setted scene with a pre-defined set of available resources.",
          resourcesInstructions: "Create an empty scene with custom set of available resources.",
          resourcesInstructions2: "Click the button '{{buttonLabel}}' and, in the opened modal, select the models you want to include.",
          copyInstructions: "Paste here the ID of the project you want to copy:",
          howToCopyProjectById: "How to copy a project by ID ?",
          selectResources: "Select the desired resources ...",

          creationModes: {
            Template: "Template",
            Resource: "Resource",
            Copy: "Copy"
          },
          description: "Description",
          projectName: "Project name",
          projectId: "Project ID",
          fileDefaultName: "1 file",
          addNewResources: "Add new resources",
          copySteps: {
            goToProjectsList: "Go to projects/experiments list where the project you wish to copy.",
            clickInfoButton: "Click the information button of the project to be copied.",
            clickCopy: "In the newly opened modal, click the copy button next to the project ID.",
            pasteId: "With the ID project copied to the clipboard, access the creation project screen, in the Copy tab, and paste it in the indicated field.",
            createProject: "If the ID is valid, project information such as name, description e thumbnail will be loaded."
          }
        },
        resourceListItem: {
          available: "Available",
          used: "Used"
        },

        resourceSelectionModal: {
          instructions: "Among the models listed below, choose the ones you wish to add to the project and they will appear in the {{listName}} list. In this list, set the quantity of each model tha should be available and click {{button}}",
          addResources: "Add resources",
          selectedResources: "Selected Resources",
          exempleSearchBar: "Ex: Color sensor",
          allCategories: "All categories",
          seeLegends: "See legends",
          modelTypesSlide: {
            header: "Model types icons",
            subtitles: "Those icons idicate which category the model belongs to: robots, actuators (devices that produce movement), sensores (devices that read signal/stimuli), objects or primitives (non-static geometric solids)"
          },
          motionPropsSlide: {
            header: "Motion properties icons",
            subtitles: "Those icons indicate if the referred object is dynamic (that is, interacts with the simulation physics) or static"
          }
        },
        aditionalFilters: {
          motionPropsHeader: "Properties"
        }
      },
      projectInfoModal: {
        projectInfoModal: {
          emailNotFound: "Email not found",
          created: "created",
          createdIn: "created in {{}}",
          projectId: "Project ID"
        }
      },
      projectListItem: {
        createdBy: "Created by",
      },
      projectList: {
        submit: "Submit experiment",
        submitting: "Submetting experiment ...",
        submitted: "Experiment submitted!",
        submitExperimentTip: "Submit experiment for evaluation",
        outOfSubmissionDeadline: "Out of submission deadline",
        sendTemplate: "Send template",
        sendingTemplate: "Sending template ...",
        sentTemplate: "Template sent successfully!",
        myProjectsHeader: "My Projects",
        myGroupProjectsHeader: "My Group Projects"
      }
    },
    registration: {
      header: "Registration",
      instructions: "Complete the registration to gain access to the platform",
      register: "Register",
      passwordInstructions: "Must contain 8 characters, being at least one uppercase, one lowercase, a number and especial character.",
      pending: "Creating user ...",
      successful: "User registered successfully!",
      failed: "User could not be registered!",
      selectInstitution: {
        instruction: "Select your institution and fill in the field with your email. An email will be sent to you to complete your institutional registration.",
        instructionEmailSent: "A confirmation email has been sent. Please confirm to proceed with the registration.",
        invalidEmail: "The entered email does not match the email format of the selected institution."
      }
    },
    systemDefinitions: {
      header: "System definitions",
    },

    project: {
      header: "Projects"
    },
    classroom: {
      header: "Classrooms"
    },
    tournament: {
      header: "Tournaments"
    },
    challenge: {
      header: "Challenges"
    },
    tutorial: {
      header: "Tutorials"
    },
    template: {
      header: "Templates"
    },
    myInstitution: {
      header: "My institution",
      unitForm: {
        createHeader: "New unit",
        updateHeader: "Update unit"
      },
      organizeEventsModal: {
        tip: "Manage materials",
        header: "Unit materials",
        description: "Select the materials of your institution that can be used by the unit {{unit}}",
        updating: "Updating list of materials...",
        success: "Updated materials list successfully!"
      },
      organizeUsersRolesModal: {
        tip: "Manage members",
        header: "Manage unit members",
        description: "List of {{unit}} unit members.",
        updating: "Updating unit members...",
        success: "Unit members updated successfully!"
      }
    }
  },
  getUser: {
    pending: "Looking for user data ...",
    successful: "User found!",
  },
  error: {
    invalid_format: "The {{field}} format is invalid",
    required: "Required field",
    minCharsRequired: "Must contain at least {{min}} characters",
    uppercaseRequired: "Must contain at least one uppercase letter",
    lowercaseRequired: "Must contain at least one lowercase letter",
    numberRequired: "Must contain at least one number",
    specialRequired: "Must contain at least one special character",
    passwordsIdentical: "Passwords must be identical",
    api: {
      recaptchaNotAvailable: "Recaptcha not available",
      userNotFound: "User not found!",
      userNameAlreadyExists: "Username already exists!",
      userAlreadyRegistered: "User already registered!",
      emailNotAllowed: "Email not allowed for registration!",
      emailAlreadyExists: "Email já existe!",
      incorrectPassword: "Password and user/email don't match!",
      usedOTP: "User has not requested to recover password or has already used the code authentication.",
      wrongOTP: "Incorrect Code.",
      expiredOTP: "Code has expired.",
      authError: "Authentication error.",
      expiredSession: "Session expired! Please, log in again.",
      serverError: "Server Error.",
      databaseError: "Database error.",
      serverConnectionError: "Conection to server failed. Try again later.",
      unknownError: "Unknown error.",
      unknownTag: "Unkown error: \"{{tag}}\".",
      //nothingFound: "Nothing found",
      formError: "One or more fields were fullfilled incorrectly.",
      simulationLoading: "Error while loading simulation. Try again later!",
      groupLoading: "Error while loading group. Try again later!",
      groupEditing: "Error when editing group. Try again later!",
      groupsNotFound: "No groups found!",
      projectIdNotValid: "Invalid project ID",
      projectNotFound: "Project not found",
      publicEventSameName: "An event with the same name already exists!",
      maxSubmissionsLimit: "Maximum number of submissions reached!",
      eventNotFound: "Event not found!",
      outOfDeadline: "Out of deadline!",
      licenseExpired: "Your license is now expired. Please renew your subscription to continue simulating",
      userNotAllowed: "You do not have permission to perform this action.",
      invalidRecaptcha: "Invalid recaptcha."
    },
    front:{
      noProjectSelected: "No project selected!",
      imageUploading: "Error when uploading image!",
      fileUploading: "Error when uploading file!",
      contentLoading: "Error when loading content!"
    },
    unity: {
      timeoutError: "Server stopped. Try again later.",
      lostRosConnection: "Lost connection to ROS. Try again later.",
      loadSimulationError: "Error when loading simulation. Try again later."
    }
  },

  ...BLOCKLY_TRANSLATIONS_EN
};
