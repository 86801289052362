import ProjectType from "../enums/ProjectType";
import MetaProject from "./MetaProject";
import {Scene} from "./Scene";

class ProjectPermissions {
  share: boolean;
  download: boolean;
  edit: boolean;

  constructor(share?: boolean, download?: boolean, edit?: boolean) {
    this.share = share ?? false;
    this.download = download ?? false;
    this.edit = edit ?? false;
  }
}

class ModelResource {
  type: string;
  maxQtd: number;
  availableQtd: number;

  constructor(type: string, maxQtd: number, availableQtd?: number) {
    this.type = type;
    this.maxQtd = maxQtd;
    this.availableQtd = availableQtd ?? maxQtd;
  }
}

class SimInfo {
  k8s_team: string;
  ip: string;
  port: number;
  namespace: string;
  remoteApiPort: number;

  constructor(
    k8s_team: string,
    ip: string,
    port: number,
    namespace: string,
    remoteApiPort: number
  ) {
    this.k8s_team = k8s_team;
    this.ip = ip;
    this.port = port;
    this.namespace = namespace;
    this.remoteApiPort = remoteApiPort;
  }
}

class ProjectProperties{
  event_id?: string;
  submission_id?: string;
  attachment: Blob | null;
  //TODO: submission_id é necessario?
  constructor(eventId?: string, submissionId?: string, attachment?: Blob | null){
    this.event_id = eventId;
    this.submission_id = submissionId;
    this.attachment = attachment ?? null;
  }
}

class RosStructure {
  scene: Scene;
  constructor( values:{
    scene: Scene
  }){
    this.scene = values.scene;
  }
}

class ProjectCodes {

}

class Project {
  id: string;
  metaProject: MetaProject;
  permissions: ProjectPermissions;
  resources: ModelResource[];
  simInfo: SimInfo | undefined;
  properties?: ProjectProperties;
  codes?: ProjectCodes;
  rosStructure?: RosStructure;

  constructor( values:
   {
    id: string, 
    meta: MetaProject,
    permissions: ProjectPermissions,
    simInfo?: SimInfo,
    resources?: ModelResource[],
    projectProperties?: ProjectProperties,
    codes?: ProjectCodes,
    rosStructure?: RosStructure
  }
  ) {
    this.id = values.id;
    this.metaProject = values.meta;
    this.permissions = values.permissions;
    this.resources = values.resources ?? [];
    this.simInfo = values.simInfo ?? undefined;
    this.properties = values.projectProperties ?? {attachment: null};
    this.codes = values.codes ?? {}
    this.rosStructure = values.rosStructure
  }
}

const emptyProject = new Project({
  id: "",
  meta: new MetaProject("",ProjectType.Project,"","",new Date(),new Date(),""),
  permissions: new ProjectPermissions(true,true,true),
})

export { ProjectPermissions, ModelResource, Project, SimInfo, ProjectProperties, ProjectCodes, RosStructure, emptyProject};
