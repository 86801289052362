import { AxiosError } from "axios";
import { ToastErrorExtraMsgs, ToastErrorMsgs } from "./ToastMsg";

/**
 * 
 * @param error Error returned by an axios proimise (AxiosError) or error message (string)
 * @returns String caontaining the error message that should be displayed in a Toast
 */
export const toastError = (
    error: AxiosError | string,
) => {
    if(typeof error == "string"){
        return ToastErrorMsgs(error);
    }
    else if(error.response){
        const apiResponse = error.response.data as ApiResponse;
        if(apiResponse?.feedback){
          const response = apiResponse.feedback.tag 
            ? ToastErrorMsgs(apiResponse.feedback.tag) + " " + 
            (ToastErrorExtraMsgs(apiResponse.feedback.msg) ?? "") // ToastErrorExtraMsgs corresponds to error messages that have no corresponding tags
            : apiResponse.feedback.msg;
          return response;
        }
        else{
          return ToastErrorMsgs("unknownError") + ": " + error.code;
        }
      }
      else if(error.request){
        return ToastErrorMsgs("unknownError") + ": " + error.code;
      }
      else{
        return ToastErrorMsgs("internalServerError");
      }
}
