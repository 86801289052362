import BlocklyPY from "blockly/python";

BlocklyPY["pioneer_instantiation_block"] = function (block) {
  var name = BlocklyPY.valueToCode(
    block,
    "name",
    BlocklyPY.ORDER_ATOMIC
  );
  var uid = BlocklyPY.valueToCode(
    block,
    "uid",
    BlocklyPY.ORDER_ATOMIC
  );
  var code = `Pioneer(${name},${uid})`;
  return [code, BlocklyPY.ORDER_NONE]
};

BlocklyPY["pioneer_getrange_block"] = function (block) {
  var value_index = BlocklyPY.valueToCode(
    block,
    "index",
    BlocklyPY.ORDER_ATOMIC
  );
  var dropdown_unit = block.getFieldValue("unit");
  var code = `getRange(${value_index},'${dropdown_unit}')`;
  return [code, BlocklyPY.ORDER_NONE]; 
}

BlocklyPY["pioneer_getranges_block"] = function (block) {
  var dropdown_unit = block.getFieldValue("unit");
  var code = `getRanges('${dropdown_unit}')`;
  return [code, BlocklyPY.ORDER_NONE]; 
}
