import "../../CodesBlockly/MathFuncs/block";
import "../../CodesBlockly/MathFuncs/pythonCode";
import "../../CodesBlockly/Controls/Simulation/block";
import "../../CodesBlockly/Controls/Simulation/pythonCode";
import "../../CodesBlockly/Controls/Score/block";
import "../../CodesBlockly/Controls/Score/pythonCode";
import "../../CodesBlockly/Controls/Time/block";
import "../../CodesBlockly/Controls/Time/pythonCode";
import "../../CodesBlockly/Basic/block";
import "../../CodesBlockly/Basic/pythonCode";
import "../../CodesBlockly/Advanced/block";
import "../../CodesBlockly/Advanced/pythonCode";
import "../../CodesBlockly/Resources/block";
import "../../CodesBlockly/Resources/pythonCode";
import "../../CodesBlockly/Text/block";
import "../../CodesBlockly/Text/pythonCode";

import { blockColor } from "../../CodesBlockly/BlockProperties";
import GetColorSensorToolbox from "../../CodesBlockly/Sensors/ColorSensor/toolbox";
import GetRangeSensorToolbox from "../../CodesBlockly/Sensors/RangeSensor/toolbox";
import GetPhotoelectricSensorToolbox from "../../CodesBlockly/Sensors/PhotoelectricSensor/toolbox";
import GetConveyorToolbox from "../../CodesBlockly/Actuators/Conveyor/toolbox";
import GetSpawnerToolbox from "../../CodesBlockly/Actuators/Spawner/toolbox";
import GetStopwatchToolbox from "../../CodesBlockly/Actuators/Stopwatch/toolbox";
import GetDobotToolbox from "../../CodesBlockly/Robots/Dobot/toolbox";
import GetNtubotToolbox from "../../CodesBlockly/Robots/Robot2Wheels/NTUbot/toolbox";
import GetLineFollowerToolbox from "../../CodesBlockly/Robots/Robot2Wheels/LineFollowerBot/toolbox";
import GetPioneerToolbox from "../../CodesBlockly/Robots/Robot2Wheels/Pioneer/toolbox";
import GetSupervisorToolbox from "../../CodesBlockly/Supervisor/toolbox";
import GetAdvancedToolbox from "../../CodesBlockly/Advanced/toolbox"
import i18n from "../../translations/i18n";
import { t } from "i18next";


//https://github.com/google/blockly/issues/4464

/*
 Abrindo o blockly advanced:

 inspector -> console

 jsonData = Blockly.mainWorkspace.options.languageTree, (key, val) => {
     if (key === 'blockxml') return val.outerHTML;

     return val;
   }

 function download(content, fileName, contentType) {
      var a = document.createElement("a");
      var file = new Blob([content], {type: contentType});
      a.href = URL.createObjectURL(file);
      a.download = fileName;
      a.click();
  }
  download(jsonData, 'toolbox.json', 'text/plain');
*/


function ToolboxCategories(modelType: string, actorId: string) {
  const colorSensorToolbox = GetColorSensorToolbox(actorId);
  const rangeSensorToolbox = GetRangeSensorToolbox(actorId);
  const photoelectricSensorToolbox = GetPhotoelectricSensorToolbox(actorId);
  const conveyorToolbox = GetConveyorToolbox(actorId);
  const spawnerToolbox = GetSpawnerToolbox(actorId);
  const stopwatchToolbox = GetStopwatchToolbox(actorId);
  const dobotToolbox = GetDobotToolbox(actorId);
  const ntubotToolbox = GetNtubotToolbox(actorId);
  const linefollowerbotToolbox = GetLineFollowerToolbox(actorId);
  const pioneerToolbox = GetPioneerToolbox(actorId);
  const supervisorToolbox = GetSupervisorToolbox(actorId);
  const advancedToolbox = GetAdvancedToolbox(actorId);

  const modelBlocks: { [modelType: string]: any } = {
    DobotV3: [
      {
        kind: "category",
        name: i18n.t("PLACEHOLDER_ROBOTS"),
        expanded: true,
        contents: [
          {
            kind: "category",
            name: i18n.t("PLACEHOLDER_NON_MOBILE"),
            expanded: true,
            contents: [dobotToolbox],
          },
        ],
      },
    ],
    NTUbot: [
      {
        kind: "category",
        name: i18n.t("PLACEHOLDER_ROBOTS"),
        expanded: true,
        contents: [
          {
            kind: "category",
            name: i18n.t("PLACEHOLDER_MOBILE"),
            expanded: true,
            contents: [ntubotToolbox],
          }
        ],
      },
    ],
    PioneerV2: [
      {
        kind: "category",
        name: i18n.t("PLACEHOLDER_ROBOTS"),
        expanded: true,
        contents: [
          {
            kind: "category",
            name: i18n.t("PLACEHOLDER_MOBILE"),
            expanded: true,
            contents: [pioneerToolbox],
          }
        ],
      },
    ],
    LineFollowerBot:[
      {
        kind: "category",
        name: i18n.t("PLACEHOLDER_ROBOTS"),
        expanded: true,
        contents: [
          {
            kind: "category",
            name: i18n.t("PLACEHOLDER_MOBILE"),
            expanded: true,
            contents: [linefollowerbotToolbox],
          }
        ],
      }
    ],
    colorSensor: [
      {
        kind: "category",
        name: i18n.t("PLACEHOLDER_SENSORS"),
        expanded: true,
        contents: [colorSensorToolbox],
      },
    ],
    rangeSensor: [
      {
        kind: "category",
        name: i18n.t("PLACEHOLDER_SENSORS"),
        expanded: true,
        contents: [rangeSensorToolbox],
      },
    ],
    photoelectricSensor: [
      {
        kind: "category",
        name: i18n.t("PLACEHOLDER_SENSORS"),
        expanded: true,
        contents: [photoelectricSensorToolbox],
      },
    ],
    ConveyorM: [
      {
        kind: "category",
        name: i18n.t("PLACEHOLDER_ACTUATORS"),
        expanded: true,
        contents: [conveyorToolbox],
      },
    ],
    Spawner2: [
      {
        kind: "category",
        name: i18n.t("PLACEHOLDER_ACTUATORS"),
        expanded: true,
        contents: [spawnerToolbox],
      },
    ],
    Stopwatch: [
      {
        kind: "category",
        name: i18n.t("PLACEHOLDER_ACTUATORS"),
        expanded: true,
        contents: [stopwatchToolbox],
      },
    ],
    Supervisor: [
      {
        kind: "category",
        name: i18n.t("PLACEHOLDER_ROBOTS"),
        contents: [
          {
            kind: "category",
            name: i18n.t("PLACEHOLDER_NON_MOBILE"),
            contents: [dobotToolbox],
          },
          {
            kind: "category",
            name: i18n.t("PLACEHOLDER_MOBILE"),
            contents: [ntubotToolbox, pioneerToolbox, linefollowerbotToolbox],
          },
        ],
      },
      {
        kind: "category",
        name: i18n.t("PLACEHOLDER_SENSORS"),
        contents: [colorSensorToolbox, rangeSensorToolbox, photoelectricSensorToolbox],
      },
      {
        kind: "category",
        name: i18n.t("PLACEHOLDER_ACTUATORS"),
        contents: [conveyorToolbox, spawnerToolbox, stopwatchToolbox],
      },
      {
        kind: "category",
        name: i18n.t("PLACEHOLDER_SUPERVISOR"),
        contents: [...supervisorToolbox],
      },
    ]
  };

  let content = [
    {
      kind: "CATEGORY",
      contents: [
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="controls_if"/>',
          type: "controls_if",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="logic_compare"/>',
          type: "logic_compare",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="logic_operation"/>',
          type: "logic_operation",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="logic_negate"/>',
          type: "logic_negate",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="logic_boolean"/>',
          type: "logic_boolean",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="logic_null"/>',
          type: "logic_null",
          disabled: "true",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="logic_ternary"/>',
          type: "logic_ternary",
        },
      ],
      name: i18n.t("PLACEHOLDER_LOGIC"),
      categorystyle: "logic_category",
    },
    {
      kind: "CATEGORY",
      contents: [
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="controls_repeat_ext">\n    <value name="TIMES">\n      <shadow type="math_number">\n        <field name="NUM">10</field>\n      </shadow>\n    </value>\n  </block>',
          type: "controls_repeat_ext",
        },
        // {
        //   "kind": "BLOCK",
        //   "blockxml": "<block xmlns=\"https://developers.google.com/blockly/xml\" type=\"controls_repeat\" disabled=\"true\"/>",
        //   "type": "controls_repeat",
        //   "disabled": "true"
        // },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="controls_whileUntil"/>',
          type: "controls_whileUntil",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="controls_for">\n    <value name="FROM">\n      <shadow type="math_number">\n        <field name="NUM">1</field>\n      </shadow>\n    </value>\n    <value name="TO">\n      <shadow type="math_number">\n        <field name="NUM">10</field>\n      </shadow>\n    </value>\n    <value name="BY">\n      <shadow type="math_number">\n        <field name="NUM">1</field>\n      </shadow>\n    </value>\n  </block>',
          type: "controls_for",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="controls_forEach"/>',
          type: "controls_forEach",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="controls_flow_statements"/>',
          type: "controls_flow_statements",
        },
      ],
      name: i18n.t("PLACEHOLDER_LOOPS"),
      categorystyle: "loop_category",
    },
    {
      kind: "CATEGORY",
      name: i18n.t("PLACEHOLDER_MATH"),
      categorystyle: "math_category",
      contents: [
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="math_number" gap="32">\n    <field name="NUM">123</field>\n  </block>',
          type: "math_number",
          gap: "32",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="math_arithmetic">\n    <value name="A">\n      <shadow type="math_number">\n        <field name="NUM">1</field>\n      </shadow>\n    </value>\n    <value name="B">\n      <shadow type="math_number">\n        <field name="NUM">1</field>\n      </shadow>\n    </value>\n  </block>',
          type: "math_arithmetic",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="math_single">\n    <value name="NUM">\n      <shadow type="math_number">\n        <field name="NUM">9</field>\n      </shadow>\n    </value>\n  </block>',
          type: "math_single",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="math_trig">\n    <value name="NUM">\n      <shadow type="math_number">\n        <field name="NUM">45</field>\n      </shadow>\n    </value>\n  </block>',
          type: "math_trig",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="math_constant"/>',
          type: "math_constant",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="math_number_property">\n    <value name="NUMBER_TO_CHECK">\n      <shadow type="math_number">\n        <field name="NUM">0</field>\n      </shadow>\n    </value>\n  </block>',
          type: "math_number_property",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="math_round">\n    <value name="NUM">\n      <shadow type="math_number">\n        <field name="NUM">3.1</field>\n      </shadow>\n    </value>\n  </block>',
          type: "math_round",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="math_on_list"/>',
          type: "math_on_list",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="math_modulo">\n    <value name="DIVIDEND">\n      <shadow type="math_number">\n        <field name="NUM">64</field>\n      </shadow>\n    </value>\n    <value name="DIVISOR">\n      <shadow type="math_number">\n        <field name="NUM">10</field>\n      </shadow>\n    </value>\n  </block>',
          type: "math_modulo",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="math_constrain">\n    <value name="VALUE">\n      <shadow type="math_number">\n        <field name="NUM">50</field>\n      </shadow>\n    </value>\n    <value name="LOW">\n      <shadow type="math_number">\n        <field name="NUM">1</field>\n      </shadow>\n    </value>\n    <value name="HIGH">\n      <shadow type="math_number">\n        <field name="NUM">100</field>\n      </shadow>\n    </value>\n  </block>',
          type: "math_constrain",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="math_random_int">\n    <value name="FROM">\n      <shadow type="math_number">\n        <field name="NUM">1</field>\n      </shadow>\n    </value>\n    <value name="TO">\n      <shadow type="math_number">\n        <field name="NUM">100</field>\n      </shadow>\n    </value>\n  </block>',
          type: "math_random_int",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="math_random_float"/>',
          type: "math_random_float",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="math_atan2">\n    <value name="X">\n      <shadow type="math_number">\n        <field name="NUM">1</field>\n      </shadow>\n    </value>\n    <value name="Y">\n      <shadow type="math_number">\n        <field name="NUM">1</field>\n      </shadow>\n    </value>\n  </block>',
          type: "math_atan2",
        },
        {
          kind: "SEP",
        },
        {
          kind: "label",
          text: i18n.t("MATH_FUNCS") + ":",
          "web-class": "ioLabel",
        },
        {
          kind: "BLOCK",
          type: "mathfuncs_degtorad_block",
        },
        {
          kind: "BLOCK",
          type: "mathfuncs_radtodeg_block",
        },
        {
          kind: "BLOCK",
          type: "mathfuncs_quaterniontoeuler_block",
        },
        {
          kind: "BLOCK",
          type: "mathfuncs_eulertoquaternion_block",
        },
        {
          kind: "BLOCK",
          type: "mathfuncs_convertlengthunit_block",
          fields: {
            current_unit: "m",
            desired_unit: "cm",
          },
        },
        {
          kind: "BLOCK",
          type: "mathfuncs_convertlengthinmeters_block",
          fields: {
            desired_unit: "cm",
          },
        },
        {
          kind: "BLOCK",
          type: "mathfuncs_converttimeunit_block",
          fields: {
            current_unit: "s",
            desired_unit: "ms",
          },
        },
        {
          kind: "BLOCK",
          type: "mathfuncs_converttimeinseconds_block",
          fields: {
            desired_unit: "ms",
          },
        },
        {
          kind: "BLOCK",
          type: "mathfuncs_convertvelocityunit_block",
          fields: {
            current_unit: "m/s",
            desired_unit: "cm/s",
          },
        },
        {
          kind: "BLOCK",
          type: "mathfuncs_convertvelocityinmeterspersec_block",
          fields: {
            desired_unit: "cm/s",
          },
        },
      ],
    },
    {
      kind: "CATEGORY",
      contents: [
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="text"/>',
          type: "text",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="text_multiline"/>',
          type: "text_multiline",
        },
        {
          kind: "BLOCK",
          type: "text_actor"
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="text_join"/>',
          type: "text_join",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="text_append">\n    <value name="TEXT">\n      <shadow type="text"/>\n    </value>\n  </block>',
          type: "text_append",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="text_length">\n    <value name="VALUE">\n      <shadow type="text">\n        <field name="TEXT">abc</field>\n      </shadow>\n    </value>\n  </block>',
          type: "text_length",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="text_isEmpty">\n    <value name="VALUE">\n      <shadow type="text">\n        <field name="TEXT"/>\n      </shadow>\n    </value>\n  </block>',
          type: "text_isEmpty",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="text_indexOf">\n    <value name="VALUE">\n      <block type="variables_get">\n        <field name="VAR">text</field>\n      </block>\n    </value>\n    <value name="FIND">\n      <shadow type="text">\n        <field name="TEXT">abc</field>\n      </shadow>\n    </value>\n  </block>',
          type: "text_indexOf",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="text_charAt">\n    <value name="VALUE">\n      <block type="variables_get">\n        <field name="VAR">text</field>\n      </block>\n    </value>\n  </block>',
          type: "text_charAt",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="text_getSubstring">\n    <value name="STRING">\n      <block type="variables_get">\n        <field name="VAR">text</field>\n      </block>\n    </value>\n  </block>',
          type: "text_getSubstring",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="text_changeCase">\n    <value name="TEXT">\n      <shadow type="text">\n        <field name="TEXT">abc</field>\n      </shadow>\n    </value>\n  </block>',
          type: "text_changeCase",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="text_trim">\n    <value name="TEXT">\n      <shadow type="text">\n        <field name="TEXT">abc</field>\n      </shadow>\n    </value>\n  </block>',
          type: "text_trim",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="text_count">\n    <value name="SUB">\n      <shadow type="text"/>\n    </value>\n    <value name="TEXT">\n      <shadow type="text"/>\n    </value>\n  </block>',
          type: "text_count",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="text_replace">\n    <value name="FROM">\n      <shadow type="text"/>\n    </value>\n    <value name="TO">\n      <shadow type="text"/>\n    </value>\n    <value name="TEXT">\n      <shadow type="text"/>\n    </value>\n  </block>',
          type: "text_replace",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="text_reverse">\n    <value name="TEXT">\n      <shadow type="text"/>\n    </value>\n  </block>',
          type: "text_reverse",
        },
        {
          kind: "LABEL",
          //text: "Input/Output:",
          text: t("TEXT_INPUT_OUTPUT"),
          "web-class": "ioLabel",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="text_print">\n    <value name="TEXT">\n      <shadow type="text">\n        <field name="TEXT">abc</field>\n      </shadow>\n    </value>\n  </block>',
          type: "text_print",
        },
        {  
          kind: "block",
          type:"variables_call",
          fields:{
            VAR:{
                type: "field_variable",
                id: actorId
            }
          },
          inputs:{
            VALUE:{
              block: {
                type: "log_block",
                inputs:{
                  msg:{
                    shadow:{
                      type: "text",                    
                      fields: {
                        TEXT: "abc"
                      }
                    }
                  }
                }
              }
            }
          }
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="text_prompt_ext">\n    <value name="TEXT">\n      <shadow type="text">\n        <field name="TEXT">abc</field>\n      </shadow>\n    </value>\n  </block>',
          type: "text_prompt_ext",
        },
      ],
      name: i18n.t("PLACEHOLDER_TEXT"),
      categorystyle: "text_category",
    },
    {
      kind: "CATEGORY",
      contents: [
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="lists_create_with">\n    <mutation items="0"/>\n  </block>',
          type: "lists_create_with",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="lists_create_with"/>',
          type: "lists_create_with",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="lists_repeat">\n    <value name="NUM">\n      <shadow type="math_number">\n        <field name="NUM">5</field>\n      </shadow>\n    </value>\n  </block>',
          type: "lists_repeat",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="lists_length"/>',
          type: "lists_length",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="lists_isEmpty"/>',
          type: "lists_isEmpty",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="lists_indexOf">\n    <value name="VALUE">\n      <block type="variables_get">\n        <field name="VAR">list</field>\n      </block>\n    </value>\n  </block>',
          type: "lists_indexOf",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="lists_getIndex">\n    <value name="VALUE">\n      <block type="variables_get">\n        <field name="VAR">list</field>\n      </block>\n    </value>\n  </block>',
          type: "lists_getIndex",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="lists_setIndex">\n    <value name="LIST">\n      <block type="variables_get">\n        <field name="VAR">list</field>\n      </block>\n    </value>\n  </block>',
          type: "lists_setIndex",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="lists_getSublist">\n    <value name="LIST">\n      <block type="variables_get">\n        <field name="VAR">list</field>\n      </block>\n    </value>\n  </block>',
          type: "lists_getSublist",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="lists_split">\n    <value name="DELIM">\n      <shadow type="text">\n        <field name="TEXT">,</field>\n      </shadow>\n    </value>\n  </block>',
          type: "lists_split",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="lists_sort"/>',
          type: "lists_sort",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="lists_reverse"/>',
          type: "lists_reverse",
        },
      ],
      name: i18n.t("PLACEHOLDER_LISTS"),
      categorystyle: "list_category",
    },
    {
      kind: "CATEGORY",
      contents: [
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="colour_picker"/>',
          type: "colour_picker",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="colour_random"/>',
          type: "colour_random",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="colour_rgb">\n    <value name="RED">\n      <shadow type="math_number">\n        <field name="NUM">100</field>\n      </shadow>\n    </value>\n    <value name="GREEN">\n      <shadow type="math_number">\n        <field name="NUM">50</field>\n      </shadow>\n    </value>\n    <value name="BLUE">\n      <shadow type="math_number">\n        <field name="NUM">0</field>\n      </shadow>\n    </value>\n  </block>',
          type: "colour_rgb",
        },
        {
          kind: "BLOCK",
          blockxml:
            '<block xmlns="https://developers.google.com/blockly/xml" type="colour_blend">\n    <value name="COLOUR1">\n      <shadow type="colour_picker">\n        <field name="COLOUR">#ff0000</field>\n      </shadow>\n    </value>\n    <value name="COLOUR2">\n      <shadow type="colour_picker">\n        <field name="COLOUR">#3333ff</field>\n      </shadow>\n    </value>\n    <value name="RATIO">\n      <shadow type="math_number">\n        <field name="NUM">0.5</field>\n      </shadow>\n    </value>\n  </block>',
          type: "colour_blend",
        },
      ],
      name: i18n.t("PLACEHOLDER_COLOUR"),
      categorystyle: "colour_category",
    },
    {
      kind: "CATEGORY",
      colour: blockColor("resources"),
      contents: [
        {
          kind: "block",
          type: "linebreak_block",
        },
        {
          kind: "block",
          type: "sleep_block",
          fields: {
            unit: "s",
          },
          inputs:{
            time:{
              block: {
                type: "math_number",
                fields: {
                    NUM: 1
                }
              }
            }
          }
        }
      ],
      name: i18n.t("PLACEHOLDER_RESOURCES"),
    },
    {
      kind: "CATEGORY",
      name: i18n.t("PLACEHOLDER_BASIC"),
      colour: blockColor("basic"),
      contents: [
        {
          kind: "block",
          type: "define_actor_block",
        },
        {
          kind: "block",
          type: "get_actor_block",
        },
      ],
    },
    {
      kind: "CATEGORY",
      name: i18n.t("PLACEHOLDER_ADVANCED"),
      colour: blockColor("advanced"),
      contents: [...advancedToolbox],
    },
    {
      kind: "SEP",
    },
    {
      kind: "CATEGORY",
      name: i18n.t("PLACEHOLDER_CONTROLS"),
      contents: [
        {
          kind: "category",
          name: i18n.t("PLACEHOLDER_SIMULATION"),
          colour: blockColor("controls"),
          contents: [
            {
              kind: "block",
              type: "controls_start_block",
            },
            {
              kind: "block",
              type: "controls_pause_block",
            },
            {
              kind: "block",
              type: "controls_stop_block",
            },
            {
              kind: "block",
              type: "controls_getsimulationstate_block",
            },
          ]
        },
        {
          kind: "category",
          name: i18n.t("PLACEHOLDER_SCORE"),
          colour: blockColor("controls"),
          contents: [
            {
              kind: "block",
              type: "controls_configscoresystem_block",
            },
            {
              kind: "block",
              type: "controls_setscore_block",
              inputs:{
                score:{
                  shadow: {
                    type: "math_number",
                    fields: {
                        NUM: 0
                    }
                  }
                }
              }
            },
            {
              kind: "block",
              type: "controls_getscore_block",
            },
          ]
        },
        {
          kind: "category",
          name: i18n.t("PLACEHOLDER_TIME"),
          colour: blockColor("controls"),
          contents: [
            {
              kind: "block",
              type: "waittime_block",
              fields: {
                unit: "s",
              },
              inputs:{
                time:{
                  block: {
                    type: "math_number",
                    fields: {
                        NUM: 1
                    }
                  }
                }
              }
            },
            {
              kind: "block",
              type: "controls_getsimulationtime_block",
            },
            {
              kind: "block",
              type: "controls_getrealtime_block",
            },
            {
              kind: "block",
              type: "time_loop",
              disabled: true
            }
          ],
        },
      ],
    },
  ];
  content.push(...modelBlocks[modelType]);

  const varAndFuncs: any[] = [
    {
      kind: "SEP",
    },
    {
      kind: "CATEGORY",
      contents: [],
      name: i18n.t("PLACEHOLDER_VARIABLES"),
      categorystyle: "variable_category",
      custom: "VARIABLE",
    },
    {
      kind: "CATEGORY",
      name: i18n.t("PLACEHOLDER_FUNCTIONS"),
      categorystyle: "procedure_category",
      custom: "PROCEDURE",
    },
  ];
  content.push(...varAndFuncs);

  const toolbox: any = {
    contents: content,
    xmlns: "https://developers.google.com/blockly/xml",
    id: "toolbox-categories",
    style: "display: none",
  };

  return toolbox;
}

export default ToolboxCategories;
