import BlocklyPY from "blockly/python";

BlocklyPY["define_actor_block"] = function (block) {
  var interface_name = BlocklyPY.valueToCode(
    block,
    "actor_name",
    BlocklyPY.ORDER_ATOMIC
  );

  if(interface_name.indexOf("(") === 0){
    interface_name = interface_name.substring(interface_name.indexOf("(") + 1, interface_name.lastIndexOf(")"));
  }
  
  var code = `self.defineActor(${interface_name})\n`;
  return code;
};

BlocklyPY["get_actor_block"] = function (block) {
  var code = `self.currentActor()`;
  return [code, BlocklyPY.ORDER_NONE];
};
