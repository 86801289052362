import { AxiosError } from "axios";
import { toast } from "react-toastify";
import { ToastErrorExtraMsgs, ToastErrorMsgs, ToastPendingMsgs, ToastStatus } from "./ToastMsg";
import { useState } from "react";
import { toastError } from "./ToastError";

/**
 * Passing an async method, shows either a pending toast 
 * or a result toast (sucess or error).
 */


export const toastPromise = (
  promise: Promise<ApiResponse>,
  pendingMessage?: string,
  statusMsg?: string,
  target?: string
) => {
  return toast.promise(promise, {
    pending: pendingMessage ?? ToastPendingMsgs("request"),
    success: {
      render({ data }) {
        return ToastStatus(target,statusMsg) 
      },
    },
    error: {
      render({ data }) {
        try{
          return toastError(typeof data === "string" ? data : (data as AxiosError))
        } catch(err) {
          throw err;
        }
      },
    },
  },
  );
};
