import Blockly from "blockly";
import i18n from "../../../translations/i18n";
import { blockColor, addSeparator } from "../../BlockProperties";

Blockly.Blocks["stopwatch_instantiation_block"] = {
  init: function () {
    this.appendDummyInput("class_type")
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(addSeparator(i18n.t("PLACEHOLDER_STOPWATCH")))
    this.appendValueInput("name")
      .setCheck("String")
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(i18n.t("PLACEHOLDER_NAME") + ":");
    this.appendValueInput("uid")
      .setCheck("Number")
      .setAlign(Blockly.ALIGN_RIGHT)
      .appendField(i18n.t("PLACEHOLDER_UID") + ":");
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(blockColor("parameters"));
    this.setTooltip(i18n.t("STOPWATCH_INSTANTIATE_TOOLTIP"));
    this.setHelpUrl("");
  },
};

Blockly.Blocks["stopwatch_setstate_block"] = {
  init: function () {
    this.appendDummyInput()
      .setAlign(Blockly.ALIGN_CENTRE)
      .appendField(addSeparator(i18n.t("STOPWATCH_SET_STATE")))
      .appendField(i18n.t("PLACEHOLDER_STATE") + ":")
      .appendField(
        new Blockly.FieldDropdown([
          [i18n.t("PLACEHOLDER_START"), "1"],
          [i18n.t("PLACEHOLDER_PAUSE"), "2"],
          [i18n.t("PLACEHOLDER_RESET"), "0"],
        ]),
        "state"
      );
    this.setOutput(true, "Any");
    this.setInputsInline(true);
    this.setColour(blockColor("actions"));
    this.setTooltip(i18n.t("STOPWATCH_SET_STATE_TOOLTIP"));
    this.setHelpUrl("");
  },
};

Blockly.Blocks['stopwatch_getseconds_block'] = {
    init: function() {
      this.appendDummyInput()
          .appendField(i18n.t("STOPWATCH_GET_TIME"));
      this.setInputsInline(true);
      this.setOutput(true, null);
      this.setColour(blockColor("sensing"));
   this.setTooltip(i18n.t("STOPWATCH_GET_TIME_TOOLTIP"));
   this.setHelpUrl("");
    }
  };

  Blockly.Blocks['stopwatch_gettimeformat_block'] = {
    init: function() {
      this.appendDummyInput()
          .appendField(i18n.t("STOPWATCH_GET_TIME_FORMAT"));
      this.setInputsInline(true);
      this.setOutput(true, null);
      this.setColour(blockColor("sensing"));
   this.setTooltip(i18n.t("STOPWATCH_GET_TIME_FORMAT_TOOLTIP"));
   this.setHelpUrl("");
    }
  };
